import axios from 'axios';
import { authActions } from '../_actions';
import { ActionType } from '../_constants';
import store from '../store';
//import { authActions } from '../_actions/auth-actions';
import createAuthRefreshInterceptor from 'axios-auth-refresh';

const API = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

const refreshAuthLogic = (/*failedRequest*/) => {
  const refreshToken = store.getState().auth.refreshToken;

  // @ts-ignore
  //return authActions.refreshToken(refreshToken)(store.dispatch);

  /*store.dispatch({
    type: ActionType.REFRESH_TOKEN_REQUEST,
    payload: refreshToken,
  });*/

  //Promise.resolve();

  return store.dispatch<any>(authActions.refreshToken(refreshToken));
};
API.interceptors.request.use(
  (config) => {
    const token = store.getState().auth.accessToken;
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);
API.interceptors.response.use(
  (response) => {
    if (response?.data?.errors) {
      return Promise.reject({
        message: response?.data?.errors.map(
          (error: { message: { message: string } }) =>
            error.message.message || error.message
        ),
      });
    }
    //console.log(response);
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      store.dispatch({
        type: ActionType.LOGOUT,
      });
    }
    return Promise.reject(error);
  }
);

createAuthRefreshInterceptor(API, refreshAuthLogic, {
  skipWhileRefreshing: false,
});
export default API;
