import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React, { FC, useState } from 'react';
import FieldList from '../FieldList';
import { NullableOption, SelectOption, SelectSubdivisionProps } from './types';

type ChangeType = (value: NullableOption) => void;

export const SelectSubdivision: FC<SelectSubdivisionProps> = ({
  name,
  optionList,
}) => {
  const [field, , { setValue }] = useField<SelectOption[]>(name);
  const [localList, setLocalList] = useState<NullableOption>(field.value);

  const handleChange: ChangeType = (valueList) => {
    // deleting last one don't work, due to next check. see more in validation
    valueList[valueList.length - 1] && setValue(valueList as any);
    setLocalList(valueList);
  };

  const getRemainingSubdivisions = () =>
    optionList.filter(
      (subdivision) =>
        !field.value.map((item) => item && item.id).includes(subdivision.id)
    );

  return (
    <FieldList
      list={localList}
      buttonLabel="Добавить подразделение"
      onChange={handleChange}
      component={
        <Autocomplete
          className="subdivision"
          getOptionLabel={({ name }) => name}
          filterOptions={createFilterOptions({
            stringify: ({ name }) => name,
          })}
          disableListWrap={true}
          disableClearable={true}
          options={getRemainingSubdivisions()}
          renderOption={({ name }) => name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              label="Подразделение"
              fullWidth
            />
          )}
        />
      }
    />
  );
};
