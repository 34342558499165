import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const CrossTrickIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="none" d="M0 0h24v24H0z" />
      <path
        fill="currentColor"
        fillRule="nonzero"
        d="M14.123 12l9.437-9.438A1.5 1.5 0 1 0 21.438.44L12 9.878 2.562.44A1.5 1.5 0 1 0 .44 2.562L9.878 12 .44 21.438a1.5 1.5 0 1 0 2.122 2.122L12 14.122l9.438 9.438a1.496 1.496 0 0 0 2.122 0 1.5 1.5 0 0 0 0-2.122L14.123 12z"
      />
    </g>
  </SvgIcon>
);
export default CrossTrickIcon;
