import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../../_shared/types';
import UserDropdownMenu from '../../_shared/UserDropdownMenu';

interface Props {
  title: string;
}

const AwardsHeader_: StyledFC<Props> = ({ className, title }) => {
  return (
    <div className={className}>
      <div className={'title'}>{title}</div>
      <ButtonGroup
        className="linkGroup"
        variant="outlined"
        color="primary"
        aria-label="contained primary button group"
      >
        <Button
          component={NavLink}
          exact
          to="achievements"
          className="linkButton"
          activeClassName="selected"
        >
          Достижения
        </Button>
        <Button
          component={NavLink}
          exact
          to="categories"
          className="linkButton"
          activeClassName="selected"
        >
          Категории
        </Button>
      </ButtonGroup>
      <div className={'userDropMenu'}>
        <UserDropdownMenu />
      </div>
    </div>
  );
};

const AwardsHeader = styled(AwardsHeader_)`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 28px;
  color: ${({ theme }) => theme.colors.slateBlue};
  display: grid;
  gap: 16px;
  grid-template-areas: 'title linkGroup userDropMenu';
  align-items: center;
  padding: 16px 24px 0 24px;
  @media (max-width: 996px) {
    grid-template-areas:
      'title title'
      'linkGroup userDropMenu';
  }
  @media (max-width: 540px) {
    grid-template-areas:
      'title userDropMenu'
      'linkGroup linkGroup';
  }

  > .linkGroup {
    grid-area: linkGroup;
    //position: absolute;
    //left: 50%;
    //transform: translate(-50%);
    //margin-left: 16px;
    .linkButton {
      height: 36px;
      width: 160px;
      border-radius: 8px;
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.hint};
      font-size: 12px;
      :first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      :last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.selected {
        background-color: ${({ theme }) => theme.colors.hint};
      }
    }
  }
  .title {
    grid-area: title;
  }
  .userDropMenu {
    grid-area: userDropMenu;
  }
`;

export default AwardsHeader;
