import * as Types from '../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetEmployeeGrantPlayerQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetEmployeeGrantPlayerQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'isActive'>
  ) }
);

export type GetEmployeeNotAssignedListGrantQueryVariables = {
  employeeId: Types.Scalars['Int'];
  projectId?: Types.Maybe<Types.Scalars['Int']>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Types.AchievementOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
  name?: Types.Maybe<Types.Scalars['String']>;
  weight?: Types.Maybe<Types.Scalars['String']>;
  category?: Types.Maybe<Types.Scalars['String']>;
  id?: Types.Maybe<Types.Scalars['String']>;
};


export type GetEmployeeNotAssignedListGrantQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'id'>
    & { notAssigned: (
      { __typename?: 'AchievementPage' }
      & Pick<Types.AchievementPage, 'total'>
      & { items: Array<(
        { __typename?: 'Achievement' }
        & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'message' | 'isActive' | 'image' | 'animation'>
        & { category: (
          { __typename?: 'Category' }
          & Pick<Types.Category, 'id' | 'name'>
        ), weight: (
          { __typename?: 'AchievementWeight' }
          & Pick<Types.AchievementWeight, 'id' | 'name' | 'value'>
        ), color: (
          { __typename?: 'AchievementColor' }
          & Pick<Types.AchievementColor, 'id' | 'name' | 'value'>
        ) }
      )> }
    ) }
  ) }
);

export type AssignToEmployeeMutationVariables = {
  employeeId: Types.Scalars['Int'];
  achievementIds?: Types.Maybe<Array<Types.Scalars['Int']>>;
  mode: Types.ElementMode;
};


export type AssignToEmployeeMutation = (
  { __typename?: 'Mutation' }
  & { assignToEmployee: (
    { __typename?: 'AssignmentsCreated' }
    & Pick<Types.AssignmentsCreated, 'successCount' | 'failed'>
  ) }
);


export const GetEmployeeGrantPlayerDocument = gql`
    query getEmployeeGrantPlayer($id: Int!) {
  employee(id: $id) {
    id
    name
    avatar
    isActive
  }
}
    `;

/**
 * __useGetEmployeeGrantPlayerQuery__
 *
 * To run a query within a React component, call `useGetEmployeeGrantPlayerQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeGrantPlayerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeGrantPlayerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeGrantPlayerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeGrantPlayerQuery, GetEmployeeGrantPlayerQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeGrantPlayerQuery, GetEmployeeGrantPlayerQueryVariables>(GetEmployeeGrantPlayerDocument, baseOptions);
      }
export function useGetEmployeeGrantPlayerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeGrantPlayerQuery, GetEmployeeGrantPlayerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeGrantPlayerQuery, GetEmployeeGrantPlayerQueryVariables>(GetEmployeeGrantPlayerDocument, baseOptions);
        }
export type GetEmployeeGrantPlayerQueryHookResult = ReturnType<typeof useGetEmployeeGrantPlayerQuery>;
export type GetEmployeeGrantPlayerLazyQueryHookResult = ReturnType<typeof useGetEmployeeGrantPlayerLazyQuery>;
export type GetEmployeeGrantPlayerQueryResult = ApolloReactCommon.QueryResult<GetEmployeeGrantPlayerQuery, GetEmployeeGrantPlayerQueryVariables>;
export const GetEmployeeNotAssignedListGrantDocument = gql`
    query getEmployeeNotAssignedListGrant($employeeId: Int!, $projectId: Int, $skip: Int, $take: Int, $orderBy: AchievementOrderBy, $orderDir: OrderDir, $name: String, $weight: String, $category: String, $id: String) {
  employee(id: $employeeId) {
    id
    notAssigned(name: $name, weight: $weight, category: $category, id: $id, project: $projectId, skip: $skip, take: $take, orderBy: $orderBy, orderDir: $orderDir) {
      items {
        id
        name
        description
        message
        isActive
        image
        animation
        category {
          id
          name
        }
        weight {
          id
          name
          value
        }
        color {
          id
          name
          value
        }
      }
      total
    }
  }
}
    `;

/**
 * __useGetEmployeeNotAssignedListGrantQuery__
 *
 * To run a query within a React component, call `useGetEmployeeNotAssignedListGrantQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeNotAssignedListGrantQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeNotAssignedListGrantQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      projectId: // value for 'projectId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      name: // value for 'name'
 *      weight: // value for 'weight'
 *      category: // value for 'category'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeNotAssignedListGrantQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeNotAssignedListGrantQuery, GetEmployeeNotAssignedListGrantQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeNotAssignedListGrantQuery, GetEmployeeNotAssignedListGrantQueryVariables>(GetEmployeeNotAssignedListGrantDocument, baseOptions);
      }
export function useGetEmployeeNotAssignedListGrantLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeNotAssignedListGrantQuery, GetEmployeeNotAssignedListGrantQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeNotAssignedListGrantQuery, GetEmployeeNotAssignedListGrantQueryVariables>(GetEmployeeNotAssignedListGrantDocument, baseOptions);
        }
export type GetEmployeeNotAssignedListGrantQueryHookResult = ReturnType<typeof useGetEmployeeNotAssignedListGrantQuery>;
export type GetEmployeeNotAssignedListGrantLazyQueryHookResult = ReturnType<typeof useGetEmployeeNotAssignedListGrantLazyQuery>;
export type GetEmployeeNotAssignedListGrantQueryResult = ApolloReactCommon.QueryResult<GetEmployeeNotAssignedListGrantQuery, GetEmployeeNotAssignedListGrantQueryVariables>;
export const AssignToEmployeeDocument = gql`
    mutation assignToEmployee($employeeId: Int!, $achievementIds: [Int!], $mode: ElementMode!) {
  assignToEmployee(employee: $employeeId, achievements: $achievementIds, mode: $mode) {
    successCount
    failed
  }
}
    `;

/**
 * __useAssignToEmployeeMutation__
 *
 * To run a mutation, you first call `useAssignToEmployeeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignToEmployeeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignToEmployeeMutation, { data, loading, error }] = useAssignToEmployeeMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      achievementIds: // value for 'achievementIds'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useAssignToEmployeeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignToEmployeeMutation, AssignToEmployeeMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignToEmployeeMutation, AssignToEmployeeMutationVariables>(AssignToEmployeeDocument, baseOptions);
      }
export type AssignToEmployeeMutationHookResult = ReturnType<typeof useAssignToEmployeeMutation>;
export type AssignToEmployeeMutationResult = ApolloReactCommon.MutationResult<AssignToEmployeeMutation>;
export type AssignToEmployeeMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignToEmployeeMutation, AssignToEmployeeMutationVariables>;