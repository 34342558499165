import { CardContent } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React from 'react';
import {
  NavLink,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from '../../../../../_constants';
import { AssignmentOrderBy, OrderDir } from '../../../../../_graphql/schema';
import { fileUrl } from '../../../../../_helpers/file';
import QueryView from '../../../../_shared/QueryView';
import SharedTable from '../../../../_shared/SharedTable';
import { HeadCell, StyledFC } from '../../../../_shared/types';
import ChevronRightIcon from '../../../../icons/ChevronRightIcon';
import AppHeader from '../../MainPage/_shared/AppHeader';
import Page from '../Page';
import EmployeeAchievementsPage from './EmployeeAchievementsPage';
import EmployeeInfo from './EmployeeInfo';
import EmployeeMemoriesPage from './EmployeeMemoriesPage';
import { useGetEmployeeAssignedListQuery } from './queries';

const headCells: HeadCell<AssignmentOrderBy>[] = [
  {
    id: 'image',
    numeric: false,
    sortable: false,
    disablePadding: false,
    label: '',
  },
  {
    id: AssignmentOrderBy.Name,
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: 'Название',
  },
  {
    id: AssignmentOrderBy.Weight,
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: 'Вес',
  },
  {
    id: AssignmentOrderBy.Id,
    numeric: true,
    sortable: false,
    disablePadding: false,
    label: 'ID',
  },
];

const PlayerPage_: StyledFC = ({ className }) => {
  const location = useLocation();
  const {
    params: { playerId, projectId },
    path,
  } = useRouteMatch<{ playerId: string; projectId: string }>();

  const result = useGetEmployeeAssignedListQuery({
    variables: {
      employeeId: parseInt(playerId),
      ...(projectId ? { projectId: parseInt(projectId) } : {}),
      take: 3,
      orderBy: AssignmentOrderBy.CreatedAt,
      orderDir: OrderDir.Desc,
    },
  });

  return (
    <QueryView
      result={result}
      renderData={(data) => {
        const Rows = () => {
          const assignments = data.employee.assigned.items;
          return assignments.map((row, index) => {
            const labelId = `achievements-table-checkbox-${index}`;
            return (
              <TableRow
                hover
                tabIndex={-1}
                key={row.achievement.id}
                style={{ height: 72 }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  className="cell cellWidth"
                >
                  <div className="image-wrapper">
                    <img alt="logo" src={fileUrl(row.achievement.image)} />
                  </div>
                </TableCell>
                <TableCell className="cell cellWidth">
                  {row.achievement.name}
                </TableCell>
                <TableCell className="cell cellWidth">
                  {row.achievement.weight?.name}
                </TableCell>
                <TableCell className="cell cellWidth">
                  {row.achievement.id}
                </TableCell>
              </TableRow>
            );
          });
        };

        const headerTitle = (
          <HeaderTitle>
            <NavLink
              className="navLink"
              to={location.pathname.split('/').slice(0, -1).join('/')}
            >
              Игроки
            </NavLink>
            <ChevronRightIcon className="headerIcon" />
            Профиль игрока
          </HeaderTitle>
        );
        return (
          <div className={className}>
            <Page
              routeEndsWith={`/players/:playerId(\\d+)`}
              header={<AppHeader title={headerTitle} />}
              content={
                <div className="root">
                  <EmployeeInfo className="employeeInfo" />

                  <div className="achievements">
                    <Card elevation={0} className="classes">
                      <div className="cardTitle">
                        {data.employee.assigned.total === 0 && (
                          <>
                            <div>
                              Последние достижения{' '}
                              <span style={{ color: colors.slateBlue }}>
                                ({data.employee.assigned.total})
                              </span>
                            </div>
                            <Button
                              component={NavLink}
                              to={`${location.pathname}/assignments/grant`}
                              className="addButton"
                              startIcon={
                                <AddCircleOutlineIcon fontSize="inherit" />
                              }
                              type={'button'}
                            >
                              Добавить достижение
                            </Button>
                          </>
                        )}
                        {data.employee.assigned.total > 0 && (
                          <>
                            <div>
                              Последние достижения{' '}
                              <span style={{ color: colors.slateBlue }}>
                                ({data.employee.assigned.items.length} из{' '}
                                {data.employee.assigned.total})
                              </span>
                            </div>
                            <Button
                              component={NavLink}
                              to={`${location.pathname}/assignments`}
                              className="addButton"
                              type={'button'}
                            >
                              Все достижения
                            </Button>
                          </>
                        )}
                      </div>
                      <CardContent className="cardContent">
                        <SharedTable
                          onRequestSort={() => {}}
                          rows={Rows()}
                          headCells={headCells}
                          leftRightRowPadding
                          hidden={data.employee.assigned.total === 0}
                        />
                      </CardContent>
                    </Card>
                  </div>
                </div>
              }
            />
            <Switch>
              <Route
                path={`${path}/assignments`}
                render={() => (
                  <div className="pageContainer">
                    <EmployeeAchievementsPage />
                  </div>
                )}
              />
            </Switch>
            <Switch>
              <Route
                path={`${path}/memories`}
                render={() => (
                  <div className="pageContainer">
                    <EmployeeMemoriesPage />
                  </div>
                )}
              />
            </Switch>
          </div>
        );
      }}
    />
  );
};

const HeaderTitle = styled.span`
  display: flex;
  align-items: center;
  > .navLink {
    color: ${({ theme }) => theme.colors.slateBlue};
    font-size: 20px;
  }
  > .headerIcon {
    font-size: 18px;
    margin-left: 12px;
    margin-right: 12px;
    color: ${({ theme }) => theme.colors.slateBlue};
  }
`;

const PlayerPage = styled(PlayerPage_)`
  width: 100%;
  height: 100%;
  > div > div > div > .root {
    margin-top: 68px;
    display: flex;
    flex-direction: row;
    padding-bottom: 30px;
    .employeeInfo {
      width: 336px;
      margin-right: 24px;
      flex-shrink: 0;
    }
    .achievements {
      margin-top: 70px;
      width: 100%;
      margin-bottom: auto;
    }
    .cardContent {
      padding: 0 12px !important;
    }
    .cardTitle {
      display: flex;
      align-items: center;
      height: 80px;
      min-width: 575px;
      padding-left: 60px;
      padding-right: 60px;
      font-size: 16px;
      font-weight: 600;
    }
    .addButton {
      height: auto;
      width: auto;
      white-space: nowrap;
      margin-left: auto;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.71;
      letter-spacing: normal;
      & .MuiButton-label {
        color: ${colors.slateBlue};
      }
    }
    .cellWidth {
      &:first-child {
        width: 15% !important;
        padding-left: 48px !important;
        position: relative;
        &:before {
          position: absolute;
          bottom: -1px;
          left: 0;
          width: 48px !important;
          border-top: 1px solid var(--card);
          content: ' ';
        }
      }
      &:nth-child(2) {
        width: 35% !important;
      }
      &:nth-child(3) {
        width: 25% !important;
      }
      &:nth-child(4) {
        width: 25% !important;
        &:before {
          position: absolute;
          bottom: -1px;
          right: 0;
          width: 48px !important;
          border-top: 1px solid var(--card);
          content: ' ';
        }
      }
    }
  }
  > .pageContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
`;

export default PlayerPage;
