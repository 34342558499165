import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const DownloadIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <path fill="transparent" d="M0 0H24V24H0z" />
      <g fill="#5C6D99" fillRule="nonzero">
        <path
          d="M9 20.167H2.759c-.508 0-.92-.411-.92-.917V2.75c0-.506.412-.917.92-.917H9V0H2.759C1.238 0 0 1.234 0 2.75v16.5C0 20.766 1.238 22 2.759 22H9v-1.833z"
          transform="rotate(-90 11.5 10.5)"
        />
        <path
          d="M6.277 10.364L11.92 5l1.304 1.273-4.034 3.833H20v1.788H9.19l4.035 3.833L11.92 17l-5.644-5.364C6.1 11.468 6 11.24 6 11s.1-.467.277-.636z"
          transform="rotate(-90 11.5 10.5)"
        />
      </g>
    </g>
  </SvgIcon>
);
export default DownloadIcon;
