import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styled from 'styled-components/macro';
import { colors } from '../../_constants';
import {
  AssignmentOrderBy,
  CategoryOrderBy,
  EmployeeOrderBy,
  OrderDir,
} from '../../_graphql/schema';
import DropdownIcon from '../icons/DropdownIcon';
import { HeadCell } from './types';

export type OrderByType = EmployeeOrderBy | AssignmentOrderBy | CategoryOrderBy;

interface Props<T> {
  className?: string;
  headCells: HeadCell<T>[];
  rows: JSX.Element[] | undefined;
  headerPaddingLeft?: boolean;
  hidden?: boolean;
  hideLastSortIcon?: boolean;
  leftRightRowPadding?: boolean;
  onRequestSort: (orderBy: T, orderDir: OrderDir) => void;
}

function SharedTable_<T>({
  className,
  headCells,
  rows,
  headerPaddingLeft = false,
  hidden = false,
  hideLastSortIcon = false,
  leftRightRowPadding = false,
  onRequestSort = () => console.log('onRequestSort'),
}: React.PropsWithChildren<Props<T>>): JSX.Element {
  const [order, setOrder] = useState(OrderDir.Desc);
  const [orderBy, setOrderBy] = useState<T>();
  const [dense] = useState(false);

  const handleRequestSort = (property?: T) => {
    if (property) {
      const isAsc = orderBy === property && order === OrderDir.Desc;
      setOrder(isAsc ? OrderDir.Asc : OrderDir.Desc);
      setOrderBy(property);
      onRequestSort(property, isAsc ? OrderDir.Desc : OrderDir.Asc);
    }
  };

  const getCellName = (index: number) => {
    let cellName = `cellWidth head cell`;
    cellName += index === 0 && headerPaddingLeft ? ` headerPaddingLeft` : '';
    cellName += leftRightRowPadding ? ` bigAfter` : '';
    return cellName;
  };

  return (
    <div
      className={className}
      style={{
        display: hidden ? 'none' : 'block',
        padding: leftRightRowPadding ? '0 0 32px 0' : '15px 40px 32px 40px',
      }}
    >
      <TableContainer className="tableContainer">
        <PerfectScrollbar>
          <Table
            className="table"
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="shared table"
            stickyHeader
          >
            <TableHead
              className={hideLastSortIcon ? ` hideLastSortIcon` : ''}
              style={{ width: '100%' }}
            >
              <TableRow style={{ width: '100%' }}>
                {headCells.map((headCell, index) => (
                  <TableCell
                    className={getCellName(index)}
                    padding={headCell.disablePadding ? 'none' : 'default'}
                    style={{
                      paddingBottom: '0',
                      cursor:
                        headCell.sortable !== false ? 'pointer' : 'default',
                    }}
                    align="left"
                    key={headCell.id}
                    sortDirection={
                      orderBy === headCell.orderBy
                        ? order === OrderDir.Desc
                          ? 'desc'
                          : 'asc'
                        : false
                    }
                  >
                    {headCell.sortable !== false ? (
                      <TableSortLabel
                        active={orderBy === headCell.orderBy}
                        direction={
                          orderBy === headCell.orderBy
                            ? order === OrderDir.Desc
                              ? 'desc'
                              : 'asc'
                            : undefined
                        }
                        onClick={() => handleRequestSort(headCell.orderBy)}
                        IconComponent={DropdownIcon as React.FC}
                        disabled={false}
                        className="sortLabel"
                      >
                        {headCell.label}
                        {orderBy === headCell.orderBy ? (
                          <span className="visuallyHidden">
                            {order === OrderDir.Desc
                              ? 'sorted descending'
                              : 'sorted ascending'}
                          </span>
                        ) : null}
                      </TableSortLabel>
                    ) : (
                      <span style={{ color: colors.slateBlue }}>
                        {headCell.label}
                      </span>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{rows}</TableBody>
          </Table>
        </PerfectScrollbar>
      </TableContainer>
    </div>
  );
}

const SharedTable: typeof SharedTable_ = styled(SharedTable_)`
  background-color: var(--card);
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  > .tableContainer {
    .highlighted {
      td,
      th {
        background-color: var(--slate-blue);
      }
    }

    .bodyRow {
      .MuiTableCell-body:first-child {
        border-radius: 10px 0 0 10px !important;
      }
    }

    .MuiTableCell-body {
      padding: 0 8px !important;

      &:last-child {
        padding-right: 34px !important;
      }
    }

    .MuiTableCell-root {
      padding: 0 8px !important;
      border-bottom: 1px solid var(--hint);
    }

    .MuiTableCell-alignLeft {
      position: relative;

      &:first-child {
        .MuiSvgIcon-root {
          display: none;
        }
      }

      &:last-child {
      }
    }

    table {
      overflow-x: hidden;

      .MuiTableSortLabel-icon {
        color: var(--slate-blue);
      }

      tbody:before {
        content: '@';
        display: block;
        line-height: 12px;
        text-indent: -99999px;
      }

      & tr {
        cursor: pointer;

        .image-wrapper {
          height: 52px;
          width: 52px;
          border-radius: 6px;
          position: relative;
          overflow: hidden;
          border: 2px solid #fff;
          display: flex;
          align-items: center;
          justify-content: center;

          img {
            object-fit: contain;
            width: 113%;
          }
        }
      }

      & thead {
        & th {
          color: var(--hint);

          td {
            padding-bottom: 2px !important;
          }
        }
      }
    }

    tbody {
      .MuiTableCell-root {
        border: none;
      }

      .MuiTableCell-body:before {
        display: none !important;
      }

      tr {
        .deleteIcon {
          color: var(--slate-blue);
        }
      }
    }

    .MuiTableRow-head {
      height: 56px !important;
    }

    td,
    th {
      border: transparent;
      font-family: 'Open Sans', 'Helvetica Neue', sans-serif;

      min-width: 50px;
    }

    td:last-child {
      padding-top: 25px;
      width: 1rem;
    }

    .action-button {
      visibility: hidden;
      color: var(--slate-blue);
    }

    .MuiTableRow-hover:hover {
      background-color: var(--hint) !important;
    }

    tbody {
      th:first-child,
      td:first-child {
        border-radius: 10px 0 0 10px;
      }

      td:last-child {
        border-radius: 0 10px 10px 0;
      }
    }

    .MuiTableRow-root {
      th,
      td {
        transition: background-color 0.5s ease;
      }
    }

    .MuiTableContainer-root {
      overflow: hidden;

      &:hover {
        color: var(--slate-blue) !important;
      }
    }

    .MuiTableRow-root.MuiTableRow-hover:hover .action-button {
      visibility: visible;
    }

    .MuiTableSortLabel-active {
      color: var(--slate-blue) !important;
    }

    .ps .ps__rail-x:hover,
    .ps .ps__rail-y:hover,
    .ps .ps__rail-x:focus,
    .ps .ps__rail-y:focus,
    .ps .ps__rail-x.ps--clicking,
    .ps .ps__rail-y.ps--clicking {
      background-color: var(--hint);
    }

    .row {
      &.Mui-selected {
        background-color: inherit;
      }
    }
    .bigAfter {
      &:first-child:after {
        position: absolute;
        content: "''";
        width: 40px;
        height: 1px;
        bottom: -1px;
        left: 0;
        background-color: ${colors.card};
      }
      &:last-child:after {
        position: absolute;
        content: "''";
        width: 40px;
        height: 1px;
        bottom: -1px;
        right: 0;
        background-color: ${colors.card};
      }
    }
    .headerPaddingLeft {
      &.MuiTableCell-head:first-child {
        padding-left: 48px !important;
      }
    }
    .table {
      width: 100%;
      min-width: 750px;
      table-layout: fixed;
    }
    .root {
      width: 100%;
    }
    .divider {
      &.MuiDivider-root {
        width: 100%;
      }
    }
    .paper {
      width: 100%;
      background-color: transparent;
      box-shadow: none;
    }
    .visuallyHidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 20px;
      width: 1px;
    }
    .sortLabel {
      & .MuiSvgIcon-root {
        display: inline-block !important;
      }
    }
    .hideLastSortIcon {
      & th:last-child {
        & span {
          display: none !important;
        }
      }
    }
    .head {
      position: sticky !important;
      font-size: 12px;
    }
  }
`;

export default SharedTable;
