import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import { useDebounce } from '@react-hook/debounce';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from '../../../../../_constants';
import { AchievementOrderBy, OrderDir } from '../../../../../_graphql/schema';
import adaptFilePath from '../../../../../_helpers/adaptFilePath';
import EditIcon from '../../../../icons/EditIcon';
import HighlightedText from '../../../../_shared/HighlightedText';
import Paginator from '../../../../_shared/Paginator';
import QueryView from '../../../../_shared/QueryView';
import SearchBar from '../../../../_shared/SearchBar';
import SharedTable from '../../../../_shared/SharedTable';
import { HeadCell, StyledFC } from '../../../../_shared/types';
import Page from '../../_shared/Page';
import AwardsHeader from '../_shared/AwardsHeader';
import Dropdown from './Dropdown';
import { useGetProjectAchievementsListQuery } from './_shared/queries';
import {
  AchievementEditClick,
  AchievementGrantClick,
  AchievementNewClick,
} from './_shared/types';

type EditButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => void;
type PaginatorChange = (
  e: React.MouseEvent<HTMLButtonElement>,
  page: number
) => void;
type SortRequest = (orderBy: AchievementOrderBy, orderDir: OrderDir) => void;
type TableRowClick = (e: React.MouseEvent<HTMLTableRowElement>) => void;

const searchBySource: { label: string; value: AchievementOrderBy }[] = [
  {
    label: `Название`,
    value: AchievementOrderBy.Name,
  },
  {
    label: `Вес`,
    value: AchievementOrderBy.Weight,
  },
  {
    label: `Категория`,
    value: AchievementOrderBy.Category,
  },
  {
    label: `ID`,
    value: AchievementOrderBy.Id,
  },
];

const headCells: HeadCell<AchievementOrderBy>[] = [
  {
    id: `image`,
    numeric: false,
    disablePadding: false,
    label: ``,
    sortable: false,
  },
  {
    id: `name`,
    numeric: true,
    disablePadding: false,
    label: `Название`,
    orderBy: AchievementOrderBy.Name,
  },
  {
    id: `weight`,
    numeric: true,
    disablePadding: false,
    label: `Вес`,
    orderBy: AchievementOrderBy.Weight,
  },
  {
    id: `category`,
    numeric: true,
    disablePadding: false,
    label: `Категория`,
    orderBy: AchievementOrderBy.Category,
  },
  {
    id: `id`,
    numeric: true,
    disablePadding: false,
    label: `ID`,
    orderBy: AchievementOrderBy.Id,
  },
  {
    id: `button`,
    numeric: true,
    disablePadding: false,
    label: ``,
    sortable: false,
  },
];

const getHeaderTitle = (total: number, searchValue: string) => {
  return searchValue === ``
    ? `Достижения (${total})`
    : `Достижения (${total} из TODO: ${total})`;
};

interface Props {
  highlightedId: number | undefined;
  onEditClick: AchievementEditClick;
  onNewClick: AchievementNewClick;
  onRowClick: AchievementGrantClick;
}

const Main_: StyledFC<Props> = ({
  className,
  highlightedId,
  onEditClick,
  onNewClick,
  onRowClick,
}) => {
  // TODO add types for all similar cases
  const { projectId } = useParams();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState({
    skip: 0,
    take: 10,
    searchField: 'name',
    orderBy: AchievementOrderBy.Name,
    orderDir: OrderDir.Asc,
  });

  const result = useGetProjectAchievementsListQuery({
    variables: {
      projectId: +projectId,
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const handlePageChange: PaginatorChange = (_e, page) => {
    setSearchProps({
      ...searchProps,
      skip: page * searchProps.take,
    });
  };

  const handleEditClick: EditButtonClick = (e) => {
    e.stopPropagation();
    const { id } = e.currentTarget.dataset;
    id ? onEditClick(id) : console.warn(`Table row must have data-id`);
  };

  const handleRequestSort: SortRequest = (orderBy, orderDir) => {
    setSearchProps({
      ...searchProps,
      orderBy: orderBy,
      orderDir: orderDir,
    });
  };

  const handleRowClick: TableRowClick = (e) => {
    const { id } = e.currentTarget.dataset;
    id ? onRowClick(id) : console.warn(`Table row must have data-id`);
  };

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const onSearchFieldChange = (field: string) => {
    setSearchProps({
      ...searchProps,
      searchField: field,
    });
  };

  return (
    <QueryView
      result={result}
      renderData={(data) => {
        const achievementList = data.project.achievements.items.map(
          (achievement) => ({
            ...achievement,
            image: adaptFilePath(achievement.image),
          })
        );

        return (
          <div className={className}>
            <Page
              header={
                <AwardsHeader
                  title={getHeaderTitle(
                    data.project.achievements.total,
                    searchValue
                  )}
                />
              }
              noScroll
              content={
                <>
                  <div className="buttonsWrapper">
                    <SearchBar
                      placeholder="Поиск достижений..."
                      searchBySource={searchBySource}
                      selectWidth={160}
                      onSearchValueChange={onSearchValueChange}
                      onSearchFieldChange={onSearchFieldChange}
                    />
                    <div className="rightWrapper">
                      <Dropdown />
                      <Button
                        className="addAchievementButton"
                        color="primary"
                        type="submit"
                        variant="contained"
                        disableElevation
                        onClick={onNewClick}
                        style={{
                          backgroundColor: 'transparent',
                          border: '2px solid #2b3757',
                        }}
                      >
                        <AddIcon className="addIcon" />
                        Достижение
                      </Button>
                    </div>
                  </div>
                  <SharedTable<AchievementOrderBy>
                    headCells={headCells}
                    onRequestSort={handleRequestSort}
                    rows={achievementList.map((achievement, index) => (
                      <TableRow
                        className={
                          highlightedId === achievement.id ? `highlighted` : ``
                        }
                        key={achievement.id}
                        data-id={achievement.id}
                        hover
                        style={{ height: 72 }}
                        onClick={handleRowClick}
                      >
                        <TableCell
                          component="th"
                          id={`achievements-table-checkbox-${index}`}
                          scope="row"
                          className="cell cellWidth"
                        >
                          <div className="image-wrapper">
                            <img alt="logo" src={achievement.image} />
                          </div>
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={achievement.name}
                            searchValue={searchValue}
                            skip={searchProps.searchField !== `name`}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={achievement.weight.name}
                            searchValue={searchValue}
                            skip={searchProps.searchField !== `weight`}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={achievement.category.name}
                            searchValue={searchValue}
                            skip={searchProps.searchField !== `category`}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={String(achievement.id)}
                            searchValue={searchValue}
                            skip={searchProps.searchField !== `id`}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <IconButton
                            className="action-button"
                            data-id={achievement.id}
                            onClick={handleEditClick}
                          >
                            <EditIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  />
                  <Paginator
                    count={data.project.achievements.total}
                    page={Math.ceil(searchProps.skip / searchProps.take)}
                    perPage={searchProps.take}
                    onChangePage={handlePageChange}
                  />
                </>
              }
            />
          </div>
        );
      }}
    />
  );
};

const Main = styled(Main_)`
  > div > .scrollbar-container {
    .addAchievementButton {
      height: 36px;
      padding: 0 24px 0 16px;
      border-radius: 18px;
      /*background-color: transparent;
      border: 2px solid #2b3757;*/
      :hover {
        background-color: rgb(64, 76, 107) !important;
      }
    }

    .addIcon {
      margin-right: 12px;
      color: ${colors.slateBlue};
    }

    .buttonsWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      flex-wrap: wrap;
      min-height: 56px;
      width: 100%;
      margin-bottom: 24px;
    }

    .rightWrapper {
      display: grid;
      grid-auto-flow: column;
      gap: 12px;
    }

    .cellWidth {
      &:before {
        position: absolute;
        bottom: -2px;
        right: 0;

        width: 100% !important;

        border-top: 1px solid var(--dark-grey-blue);
        content: ' ';
      }

      &:first-child {
        width: 10% !important;

        &:before {
          bottom: -1.5px;
        }
      }
      &:nth-child(2) {
        width: 29% !important;
      }
      &:nth-child(3) {
        width: 19% !important;
      }
      &:nth-child(4) {
        width: 24% !important;
      }
      &:nth-child(5) {
        width: 9% !important;
      }
      &:nth-child(6) {
        width: 9% !important;
        &:before {
          bottom: -1.5px;
        }
      }
    }
  }
`;

export default Main;
