import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const PlayersIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path d="M9.7 12.7C8.6 11.8 8 10.5 8 9c0-2.4 1.7-4.5 4.1-4.9C11.1 2.8 9.7 2 8 2 5.2 2 3 4.2 3 7c0 1.9 1.1 3.6 2.6 4.4C2.9 12.4 1 15 1 18h4.3c.6-2.3 2.2-4.3 4.4-5.3z" />
    <path d="M23 20.7c0-4.4-3.6-8-8-8s-8 3.6-8 8c0 .6.4 1 1 1h14c.6 0 1-.5 1-1zm-13.9-1c.5-2.8 2.9-5 5.9-5 3 0 5.4 2.2 5.9 5H9.1zm5.9-5c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zm0-2c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z" />
  </SvgIcon>
);
export default PlayersIcon;
