import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const AchievementsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g fill="none" fillRule="evenodd">
      <path
        fill="currentColor"
        d="M18.447 12.863l-.148-.393-6.43 2.787 3.078 8.458 2.62-3.036 3.958.641z"
      />
      <path
        fill="currentColor"
        d="M5.553 12.863l.225-.603 6.353 2.997-3.078 8.458-2.62-3.036-3.958.641z"
      />
      <circle cx="12" cy="8" r="7" stroke="currentColor" strokeWidth="2" />
      <circle cx="12" cy="8" r="3" fill="currentColor" />
    </g>
  </SvgIcon>
);
export default AchievementsIcon;
