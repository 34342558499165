import { Divider, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Form as FormikForm, useFormikContext } from 'formik';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components/macro';
import { StyledFC } from './types';

export enum FormDecor {
  divider = `divider`,
}

export interface FormTemplateProps {
  fieldsRender: (
    | {
        show?: boolean;
        title?: string;
        render: () => JSX.Element;
      }
    | FormDecor.divider
  )[];
}

const FormTemplate_: StyledFC<FormTemplateProps> = ({
  className,
  fieldsRender,
}) => {
  const { dirty, isValid, isSubmitting } = useFormikContext();

  return (
    <>
      <PerfectScrollbar className={className}>
        <FormikForm className="form" id="form-template">
          {fieldsRender.map((group, index) => {
            if (group === FormDecor.divider) {
              return <Divider key={index} className="divider" />;
            } else if (group.show === true || group.show === undefined) {
              return (
                <fieldset key={index} className="form__group-wrapper">
                  {group.title ? (
                    <legend className="visually-hidden">{group.title}</legend>
                  ) : null}
                  <div className="form__group">
                    {group.title ? (
                      <Typography
                        className="form__group-title"
                        aria-hidden="true"
                      >
                        {group.title}
                      </Typography>
                    ) : null}
                    <div className="form__group-controls">{group.render()}</div>
                  </div>
                </fieldset>
              );
            } else {
              return null;
            }
          })}
        </FormikForm>
      </PerfectScrollbar>
      <Footer>
        <CancelButton
          color="primary"
          form="form-template"
          type="reset"
          variant="text"
          disableElevation
          disabled={isSubmitting}
        >
          Отмена
        </CancelButton>
        <SubmitButton
          color="primary"
          form="form-template"
          type="submit"
          variant="contained"
          disableElevation
          disabled={!isValid || !dirty || isSubmitting}
        >
          Сохранить
        </SubmitButton>
      </Footer>
    </>
  );
};

const FormTemplate = styled(FormTemplate_)`
  .form {
    display: grid;
    gap: 20px;

    max-width: 1024px;
    margin: 0 auto;
    padding: 54px;

    background-color: #242e45;
    border-radius: 15px;

    overflow: hidden;
  }

  .form__group-wrapper {
    margin: 0;
    padding: 0;

    border: 0;
  }

  .form__group {
    display: grid;
    grid-template-areas: 'title fields';
    grid-template-columns: 244px 360px;
    justify-content: start;
    column-gap: 20px;
  }

  .form__group-title {
    grid-area: title;

    color: ${({ theme }) => theme.colors.slateBlue};
    font-size: 16px;
    font-weight: 600;

    /* &--access {
      display: flex;
      align-items: center;
      height: 60px;
    } */
  }

  .form__group-controls {
    display: grid;
    grid-area: fields;
    gap: 16px;

    & > div > video {
      height: auto;
      max-width: 100% !important;
    }
  }

  .hint {
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.darkBackground};
    padding: 14px 24px 20px 16px;
    font-size: 14px;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.slateBlue};
  }

  .group__uploader {
    margin-bottom: 0;
  }

  .divider {
    margin: 16px 0 16px;
  }

  .checkbox {
    width: fit-content;
  }

  .deleteButton {
    width: fit-content;

    color: ${({ theme }) => theme.colors.slateBlue};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    text-decoration: underline;
  }
  .noDeleteButton {
    width: fit-content;
    color: ${({ theme }) => theme.colors.slateBlue};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-shrink: 0;

  height: 64px;
  margin-top: 20px;
`;

const CancelButton = styled(Button)`
  && {
    height: 36px;
    width: 160px;

    border-radius: 18px;
  }
`;

const SubmitButton = styled(Button)`
  && {
    height: 36px;
    width: 208px;

    border-radius: 18px;
  }
`;

export default FormTemplate;
