export * from './colors-constants';

export enum ActionType {
  /** user-constants */
  SET_USER = 'SET_USER',

  /** toasts-constants */
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',

  /** dialogs-constants */
  SHOW_CONFIRM_DIALOG = 'SHOW_CONFIRM_DIALOG',
  HIDE_CONFIRM_DIALOG = 'HIDE_CONFIRM_DIALOG',
  SHOW_ALERT_DIALOG = 'SHOW_ALERT_DIALOG',
  HIDE_ALERT_DIALOG = 'HIDE_ALERT_DIALOG',

  /** auth-constants */
  LOGIN_REQUEST = 'LOGIN_REQUEST',
  LOGIN_SUCCESS = 'LOGIN_SUCCESS',
  LOGIN_FAILURE = 'LOGIN_FAILURE',
  REFRESH_TOKEN_REQUEST = 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE = 'REFRESH_TOKEN_FAILURE',
  LOGOUT = 'LOGOUT',
  RESTORE = 'RESTORE',
}

export enum ValidationError {
  EMAIL = `Введите действительный Email`,
  REQUIRED = `Поле обязательно для заполнения`,
}
