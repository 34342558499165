import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider } from '@material-ui/styles';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { ThemeProvider as StyledThemeProvider } from 'styled-components/macro';
import App from './components/app';
import ErrorBoundary from './components/_shared/ErrorBoundary';
import './index.scss';
import store from './store';
import client from './_graphql/apollo-client';
import { history } from './_helpers';
import GlobalFonts from './_styled/GlobalFonts';
import { darkTheme, theme } from './_styled/theme';

ReactDOM.render(
  <StyledThemeProvider theme={theme}>
    <ThemeProvider theme={darkTheme}>
      <GlobalFonts />
      <Provider store={store}>
        <ErrorBoundary>
          <ApolloProvider client={client}>
            <Router history={history}>
              <App />
            </Router>
          </ApolloProvider>
        </ErrorBoundary>
      </Provider>
    </ThemeProvider>
  </StyledThemeProvider>,
  document.getElementById('root')
);
