import { Formik } from 'formik';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { ActionType } from '../../../../_constants';
import adaptFilePath from '../../../../_helpers/adaptFilePath';
import QueryView from '../../../_shared/QueryView';
import AppHeader from '../MainPage/_shared/AppHeader';
import Page from '../_shared/Page';
import Form from './_shared/Form';
import {
  ProjectBaseFieldsFragment,
  UpdateProjectMutationVariables,
  useDeleteProjectMutation,
  useGetProjectQuery,
  useUpdateProjectMutation,
} from './_shared/queries';
import { Fields, ProjectCancel, ProjectDelete } from './_shared/types';
import validation from './_shared/validation';

const adaptForEdit = ({
  id,
  description,
  imageBase64,
  isActive,
  name,
  subdivisions,
}: Fields): UpdateProjectMutationVariables => ({
  id,
  description,
  imageBase64,
  isActive,
  name,
  subdivisionIds: subdivisions.map(({ id }) => id),
});

const getInitialValues = ({
  id,
  description,
  image,
  isActive,
  name,
  subdivisions,
}: ProjectBaseFieldsFragment): Fields => ({
  id,
  description,
  imageBase64: ``,
  imageSrc: image,
  isActive,
  name,
  subdivisions: subdivisions.items,
});

const Edit: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { projectId } = useParams<{ projectId: string }>();
  const [deleteProjectMutation] = useDeleteProjectMutation();
  const [updateProjectMutation] = useUpdateProjectMutation();

  const projectQueryResult = useGetProjectQuery({
    variables: { id: +projectId },
  });

  const deleteProject = async () => {
    const { data, errors } = await deleteProjectMutation({
      variables: {
        id: +projectId,
      },
    });

    if (data) {
      history.push(`/`);
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'success',
          delay: 5000,
          text: `Проект «${projectQueryResult.data?.project.name}» успешно удален`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибки удаления проекта ${errors.map(
            ({ message }) => message
          )}`,
        },
      });
    }
  };

  const handleCancel: ProjectCancel = () => {
    history.push(`/`);
  };

  const handleDelete: ProjectDelete = () =>
    dispatch({
      type: ActionType.SHOW_CONFIRM_DIALOG,
      payload: {
        title: 'Удалить проект?',
        message: 'Это приведет к удалению всех сущностей, созданных в проекте.',
        applyText: 'Удалить',
        cancelText: 'Отмена',
        onApply: deleteProject,
      },
    });

  const handleSubmit = async (formData: Fields) => {
    const { data, errors } = await updateProjectMutation({
      variables: adaptForEdit(formData),
    });

    if (data) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          text: `Настройки проекта успешно обновлены!`,
          className: 'success',
          delay: 5000,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибки сохранения изменений проекта ${errors.map(
            ({ message }) => message
          )}`,
        },
      });
    }
  };

  return (
    <Page
      noScroll
      header={<AppHeader title="Настройки проекта" />}
      content={
        <QueryView
          result={projectQueryResult}
          renderData={(data) => {
            const project = {
              ...data.project,
              image: adaptFilePath(data.project.image),
            };

            return (
              <Formik
                initialValues={getInitialValues(project)}
                validationSchema={validation}
                onReset={handleCancel}
                onSubmit={handleSubmit}
              >
                <Form onDelete={handleDelete} />
              </Formik>
            );
          }}
        />
      }
    />
  );
};

export default Edit;
