import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dateToString } from '../../../../../_helpers/dateToString';
import { fileUrl } from '../../../../../_helpers/file';
import { toReactHtmlParser } from '../../../../../_utils';
import { StyledFC } from '../../../../_shared/types';

interface Props {
  assignment: {
    achievement: {
      category: { name: string; project: { description: string } };
      description: string;
      image: string;
      name: string;
    };
    createdAt: Date;
  };
}

const Assignment_: StyledFC<Props> = ({ className, assignment }) => {
  const history = useHistory();
  const location = useLocation();

  const isEdit = location.pathname.endsWith('/edit');
  const toFileUrl = (url: string | undefined) => {
    return fileUrl(url ? url : '');
  };

  const onClose = () => {
    isEdit ? history.go(-2) : history.go(-1);
  };

  return (
    <div className={className}>
      <div className="badge-card">
        <div>
          <IconButton onClick={onClose} className="close-btn">
            <CloseIcon className="close-icon" />
          </IconButton>
        </div>

        <div className="badge-ava">
          <img src={toFileUrl(assignment.achievement.image)} alt="" />
        </div>
        <div className="badge-content">
          <span>{assignment.achievement.category.name}</span>
          <h1>{assignment.achievement.name}</h1>

          <p>
            {' '}
            «{toReactHtmlParser(assignment.achievement.description)}
            »(с){' '}
          </p>

          <span>{assignment?.achievement.category.project.description}</span>
          <span className="date">
            Выдан {dateToString(new Date(assignment.createdAt), 'DD.MM.YYYY')}{' '}
          </span>
        </div>
      </div>
    </div>
  );
};

const Assignment = styled(Assignment_)`
  > .badge-card {
    min-height: 600px;
    background-color: ${({ theme }) => theme.colors.lightSlatePurple};
    display: flex;
    flex-direction: column;
    align-items: center;
    .close-btn {
      font-size: 50px;
      align-self: flex-end;
      right: 1rem;
      display: block;
      margin: 1rem 1% 0 0;
      width: 5.5rem;
      height: 5.5rem;
      position: absolute;
      .close-icon {
        width: 2rem;
        height: 2rem;
      }
    }
    .badge-ava {
      width: 100%;
      margin: 4% 0 2% 0;
      img {
        height: 240px;
      }
    }
    .badge-content {
      display: flex;
      flex-direction: column;
      max-width: 70%;
      .date {
        margin-top: 0;
        margin-bottom: 1rem;
      }
      p {
        line-height: 1.5;
      }
    }
  }
`;

export default Assignment;
