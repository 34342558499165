import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const ChevronDownIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    >
      <path d="M12 18L22 8 12 18zM12 18L2 8l10 10z" />
    </g>
  </SvgIcon>
);
export default ChevronDownIcon;
