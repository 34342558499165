import React from 'react';
import Loader from '../../app/LoggedInPage/_shared/Loader';
import ErrorMessage from './errorMessage';
import { QueryViewType } from './types';

export * from './types';

export const QueryView: QueryViewType = ({
  result,
  renderData,
  renderError,
  renderInit,
}) => {
  const { data, error, loading } = result;
  return error ? (
    renderError ? (
      renderError(result)
    ) : (
      <ErrorMessage>{error.message}</ErrorMessage>
    )
  ) : data === undefined && loading ? (
    renderInit ? (
      renderInit(result)
    ) : (
      <Loader darkened />
    )
  ) : data ? (
    renderData(data, result) || null
  ) : null;
};

export default QueryView;
