import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../_shared/types';

interface Props {
  blurred?: boolean;
  darkened?: boolean;
}

const Loader_: StyledFC<Props> = ({
  className,
  blurred = false,
  darkened = false,
}) => {
  return (
    <div
      className={`${className} ${blurred ? 'blurred' : ''} ${
        darkened ? 'darkened' : ''
      }`}
    >
      <CircularProgress />
    </div>
  );
};

const Loader = styled(Loader_)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  z-index: 9;
  &.blurred {
    backdrop-filter: blur(10px);
  }
  &.darkened {
    background-color: ${({ theme }) => theme.colors.darkBackground};
  }
`;

export default Loader;
