import FormLabel from '@material-ui/core/FormLabel';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import styled from 'styled-components/macro';
import { theme } from '../../_styled/theme';
import BadgeDefaultAnimationIcon from '../icons/BadgeDefaultAnimationIcon';
import BadgeDefaultIcon from '../icons/BadgeDefaultIcon';
import { StyledFC } from './types';

interface Props {
  color: string;
  bgColor?: string;
  text: string;
  icon?: JSX.Element;
}

const PlaceholderComponent_: StyledFC<Props> = ({
  className,
  color,
  bgColor,
  text,
  icon,
}) => {
  return (
    <div className={className} style={{ color, backgroundColor: bgColor }}>
      {icon}
      <Typography className={['text', 'underline'].join(' ')}>
        {text}
      </Typography>
      <Typography className="text">или перетащите его сюда</Typography>
    </div>
  );
};

const PlaceholderComponent = styled(PlaceholderComponent_)`
  border-radius: 5px;
  padding: 10px;
  .icon {
    margin: auto auto 20px auto;
    height: 48px;
    width: 48px;
    font-size: 21px;
    font-weight: 600;
    color: ${({ theme }) => theme.colors.slateBlue};
    display: block;
  }
  .text {
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    text-align: center;
    white-space: nowrap;
    &.underline {
      text-decoration: underline;
    }
  }
`;

export interface FileChange {
  file: File | null;
  src: string | null;
}

interface UploaderProps {
  file: File | undefined;
  mode: string;
  src: string | undefined;
  bgSize?: string;
  endAdornment?: React.ReactElement;
  error?: boolean;
  isDeleteBtn?: boolean;
  isLabelFileName?: boolean;
  onBlur?: () => void;
  onChange: (value: FileChange) => void;
  onFocus?: () => void;
}

const Uploader_: StyledFC<UploaderProps> = ({
  className,
  file = undefined,
  mode = 'image',
  src = '',
  bgSize = '50%',
  endAdornment,
  error,
  isDeleteBtn = true,
  isLabelFileName = true,
  onBlur,
  onChange = (value) => console.log(value),
  onFocus,
}) => {
  const [preview, setPreview] = useState('');

  const onDeleteButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    e.stopPropagation();
    URL.revokeObjectURL(preview);
    setPreview('');
    onChange({ file: null, src: null });
  };

  const onDrop = (files: File[]) => {
    setPreview(URL.createObjectURL(files[0]));
    onChange({
      file: files[0],
      src: URL.createObjectURL(files[0]),
    });
  };

  let accept;
  if (mode === 'image') accept = 'image/*';
  if (mode === 'animation') accept = 'video/mp4';
  const bgColor = 'rgb(124, 124, 124, 0.5)';
  return (
    <Dropzone onDrop={onDrop} accept={accept}>
      {({ getRootProps, getInputProps }) => (
        <div
          style={{
            backgroundImage: `url(${src && mode === 'image' ? src : preview})`,
            backgroundSize: bgSize,
          }}
          className={[className, error ? 'error' : '', 'dropzone'].join(' ')}
          {...getRootProps({ onFocus, onBlur })}
        >
          <input {...getInputProps()} />
          {!file && !src ? (
            <div className="content">
              {mode === 'image' && (
                <PlaceholderComponent
                  text={'Выберите файл изображения'}
                  color={theme.colors.slateBlue}
                  icon={<BadgeDefaultIcon className="icon" />}
                />
              )}
              {mode === 'animation' && (
                <PlaceholderComponent
                  text={'Выберите файл анимации'}
                  color={theme.colors.slateBlue}
                  icon={<BadgeDefaultAnimationIcon className="icon" />}
                />
              )}
            </div>
          ) : (
            <>
              {mode === 'animation' && (
                <>
                  <video controls src={src ? src : preview} className="img" />
                </>
              )}
              {(isLabelFileName && (
                <FormLabel className="label">
                  {src ? src.split('/').pop() : file?.name}
                </FormLabel>
              )) ||
                (!isLabelFileName && (
                  <FormLabel className="textLabel">
                    <PlaceholderComponent
                      bgColor={bgColor}
                      text={'Выберите новое фото'}
                      color={theme.colors.white}
                    />
                  </FormLabel>
                ))}

              {isDeleteBtn && (
                <IconButton
                  aria-label="delete"
                  onClick={onDeleteButtonClick}
                  className="deleteButton"
                  size="small"
                >
                  <ClearIcon fontSize="inherit" />
                </IconButton>
              )}
            </>
          )}
          {endAdornment}
        </div>
      )}
    </Dropzone>
  );
};

const Uploader = styled(Uploader_)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: 240px;
  border-radius: 30px;
  border-style: dashed;
  border-width: 1px;
  margin-bottom: 16px;
  overflow: hidden;
  position: relative;
  border-color: ${({ theme }) => theme.colors.slateBlue};
  &.error {
    border-color: ${({ theme }) => theme.colors.darkishPink};
  }
  background: no-repeat center;

  .content {
    display: flex;
    align-items: center;

    .img {
      display: block;
      width: auto;
      height: 100%;
    }
    .label {
      transform: translate(16px, 18px) scale(0.75);
      transform-origin: top left;
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 24px;
      top: 0;
      left: 0;
      position: absolute;
    }
    .textLabel {
      color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 0;
      left: 0;
      position: absolute;
      background: linear-gradient(
        0deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(0, 0, 0, 0.1) 30%,
        rgba(0, 0, 0, 1) 100%
      );
      width: 100%;
      height: 30%;
      padding: 0.1rem;
    }
    .tDecor {
      text-decoration: underline;
      padding: 0;
    }
    .deleteButton {
      color: ${({ theme }) => theme.colors.slateBlue};
      position: absolute;
      top: 21px;
      right: 21px;
      font-size: 16px;
    }
  }
`;

export default Uploader;
