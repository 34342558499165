export const dateToString = (date: Date, format: string) => {
  // this polyfill is grabbed from https://developer.mozilla.org/ru/docs/Web/JavaScript/Reference/Global_Objects/String/padStart
  // eslint-disable-next-line
  String.prototype.padStart = function (targetLength, padString) {
    targetLength = targetLength >> 0;
    padString = String(padString || ' ');
    if (this.length > targetLength) {
      return String(this);
    } else {
      targetLength = targetLength - this.length;
      if (targetLength > padString.length) {
        padString += padString.repeat(targetLength / padString.length);
      }
      return padString.slice(0, targetLength) + String(this);
    }
  };

  let divider;
  switch (true) {
    case format.includes('.'):
      divider = '.';
      break;
    default:
      throw new TypeError("format doesn't contain divider");
  }

  format = format.split(divider).join('');

  const dd = date.getDate().toString().padStart(2, '0');
  const mm = (date.getMonth() + 1).toString().padStart(2, '0');
  let mmmm = (date.getMonth() + 1).toString();
  switch (mmmm) {
    case '1':
      mmmm = 'января';
      break;
    case '2':
      mmmm = 'февраля';
      break;
    case '3':
      mmmm = 'марта';
      break;
    case '4':
      mmmm = 'апреля';
      break;
    case '5':
      mmmm = 'мая';
      break;
    case '6':
      mmmm = 'июня';
      break;
    case '7':
      mmmm = 'июля';
      break;
    case '8':
      mmmm = 'августа';
      break;
    case '9':
      mmmm = 'сентября';
      break;
    case '10':
      mmmm = 'oктября';
      break;
    case '11':
      mmmm = 'ноября';
      break;
    case '12':
      mmmm = 'декабря';
      break;
    default:
      mmmm = 'месяц не известен';
  }

  const yyyy = date.getFullYear().toString();

  switch (format) {
    case 'DDMMYYYY':
      return [dd, mm, yyyy].join(divider);
    case 'DDMMMMYYYY':
      return [dd, mmmm, yyyy].join(' ');
    default:
      throw new TypeError('incorrect date format');
  }
};
