import Toolbar from '@material-ui/core/Toolbar';
import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../../_shared/types';
import UserDropdownMenu from '../../_shared/UserDropdownMenu';

interface Props {
  title: JSX.Element | string;
}

const AppHeader_: StyledFC<Props> = ({ className, title }) => {
  return (
    <Toolbar className={className}>
      <div>{title}</div>
      <UserDropdownMenu />
    </Toolbar>
  );
};

const AppHeader = styled(AppHeader_)`
  margin-top: 8px;
  font-size: 20px;
  color: ${({ theme }) => theme.colors.slateBlue};
`;

export default AppHeader;
