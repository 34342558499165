import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  InputFile,
  Input,
  InputReadonly,
  Select,
  SelectAutocomplete,
  Textarea,
} from '../../../../../_shared/fields';
import FormTemplate, { FormDecor } from '../../../../../_shared/FormTemplate';
import QueryView from '../../../../../_shared/QueryView';
import { useGetSelectListsQuery } from './queries';
import { AchievementDelete, Field, Fields } from './types';

interface Props {
  onDelete?: AchievementDelete;
  playersCount?: number;
}

const Form: FC<Props> = ({ onDelete, playersCount }) => {
  const { projectId } = useParams<{ projectId: string }>();
  const formikContext = useFormikContext<Fields>();

  const selectListQueryResult = useGetSelectListsQuery({
    variables: {
      id: +projectId,
    },
  });

  return (
    <QueryView
      result={selectListQueryResult}
      renderData={(data) => (
        <FormTemplate
          fieldsRender={[
            {
              title: `Основная информация`,
              render: () => (
                <>
                  <Input name={Field.name} label="Название" />
                  {formikContext.values.id ? (
                    <InputReadonly name={Field.id} label="ID" />
                  ) : null}
                  <Select
                    name={Field.weight}
                    label="Вес достижения"
                    optionList={data.achievementWeights}
                  />
                  <Textarea name={Field.description} label="Текст описания" />
                  <Textarea name={Field.message} label="За что выдается" />
                </>
              ),
            },
            {
              title: `Изображение`,
              render: () => (
                <>
                  <InputFile
                    file={Field.imageBase64}
                    src={Field.imageSrc}
                    mode="image"
                  />
                  <Select
                    name={Field.color}
                    label="Цвет фона"
                    optionList={data.achievementColors}
                    type="color"
                  />
                </>
              ),
            },
            {
              title: `Анимация`,
              render: () => (
                <InputFile
                  file={Field.animationBase64}
                  src={Field.animationSrc}
                  mode="animation"
                />
              ),
            },
            FormDecor.divider,
            {
              title: `Доступ`,
              render: () => (
                <>
                  <SelectAutocomplete
                    name={Field.category}
                    label="Категория"
                    optionList={data.project.categories.items}
                  />
                  <Checkbox name={Field.isActive} label="Достижение активно" />
                </>
              ),
            },
            {
              show: !!onDelete,
              render: () => {
                const player = playersCount === 1 ? 'игроку' : 'игрокам';
                return playersCount && playersCount > 0 ? (
                  <div className="noDeleteButton">
                    Достижение не может быть удалено,
                    <br />
                    т.к. оно присвоено {playersCount} {player}
                  </div>
                ) : (
                  <button
                    className="button-reset deleteButton"
                    type="button"
                    onClick={onDelete}
                  >
                    Удалить достижение
                  </button>
                );
              },
            },
          ]}
        />
      )}
    />
  );
};

export default Form;
