import { createMuiTheme } from '@material-ui/core';
import { DefaultTheme } from 'styled-components/macro';
import { colors } from '../_constants';

const theme: DefaultTheme = {
  borderRadius: 8,
  colors: {
    lightGreyBlue: '#aeb3be',
    slateBlue: '#5c6d99',
    hint: '#36425d',
    white: '#ffffff',
    sideBarSelected: '#3b6cb9',
    darkBackground: '#171f2f',
    darkishPink: '#d74471',
    inactiveButton: '#181c2e',
    darkBlueGrey: '#142342',
    card: '#242e45',
    blueGrey: '#7b87a3',
    lightSlatePurple: '#b2adf7',
  },
};

const darkTheme = createMuiTheme({
  palette: {
    type: 'dark',
    error: {
      main: colors.darkishPink,
    },
    primary: {
      main: colors.slateBlue,
    },
    secondary: {
      main: colors.slateBlue,
    },
    background: {
      paper: colors.card,
      default: colors.darkBackground,
    },
  },
  typography: {
    // @ts-ignore
    fontFamily: ['Open Sans', 'Helvetica Neue', 'sans-serif'],
  },
  overrides: {
    MuiTableCell: {
      stickyHeader: {
        backgroundColor: colors.card,
      },
    },
    MuiTableSortLabel: {
      root: {
        color: colors.slateBlue,
        '&$active': {
          color: 'inherit',
          '&& $icon': {
            color: 'inherit',
          },
        },
      },
    },
    MuiTableBody: {
      root: {
        '&:before': {
          height: 12,
          display: 'block',
          content: "' '",
        },
      },
    },
    MuiAppBar: {
      root: {
        height: 72,
        paddingTop: 8,
      },
      colorPrimary: {
        backgroundColor: colors.darkBackground,
        color: colors.slateBlue,
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: colors.slateBlue,
          color: colors.white,
        },
      },
    },
    MuiListItemIcon: {
      root: {
        minWidth: 40,
        color: 'inherit',
      },
    },
    MuiInputLabel: {
      root: {
        color: colors.slateBlue,
        height: 24,
        display: 'flex',
        alignItems: 'center',

        '&$disabled': {
          color: colors.slateBlue,
          opacity: 0.5,
        },
      },
      outlined: {
        transform: 'translate(15px, 17px) scale(1)',
        '&$shrink': {
          transform: `translate(16px, 8px) scale(0.75)`,
        },
      },
    },
    MuiFormLabel: {
      root: {
        color: colors.slateBlue,
      },
    },
    // @ts-ignore
    PrivateNotchedOutline: {
      root: {
        top: 0,
        '& $legendLabelled': {
          display: 'none',
        },
      },
    },

    MuiButton: {
      root: {
        height: 60,
        borderRadius: 10,
        textTransform: 'none',
      },

      outlined: {
        height: 36,
        borderRadius: 18,
        fontSize: 14,
        fontWeight: 600,
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: 'normal',
        textAlign: 'center',
      },
      label: {
        color: colors.white,
      },
    },
    MuiMenu: {
      paper: {
        borderRadius: 6,
        minWidth: 140,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: 'normal',
        '&:hover': {
          backgroundColor: colors.slateBlue,
        },
      },
    },
    MuiInputBase: {
      root: {
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: 'normal',
      },
    },
    MuiOutlinedInput: {
      input: {
        paddingTop: 25,
        paddingLeft: 16,
        paddingBottom: 11,
        paddingRight: 16,
        height: 24,
      },
      inputMultiline: {
        height: 'auto',
      },
      multiline: {
        paddingTop: 25,
        paddingLeft: 16,
        paddingBottom: 11,
        paddingRight: 16,
      },
      root: {
        borderRadius: 10,
        borderColor: colors.slateBlue,
        '& $notchedOutline': {
          borderColor: colors.slateBlue,
        },
        '&:hover $notchedOutline': {
          borderColor: colors.slateBlue,
        },
        '&$focused $notchedOutline': {
          borderColor: colors.slateBlue,
        },
        '&$disabled': {
          opacity: 0.5,
          color: colors.white,
          '& $notchedOutline': {
            borderColor: colors.slateBlue,
          },
        },
      },
    },
    MuiFormHelperText: {
      root: {
        // can't change marginLeft from here, it's override by mui
        // marginLeft: 16,
        paddingLeft: 2,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'unset',
        },
      },
      outlined: {
        borderRadius: 10,
      },
      icon: {
        color: colors.slateBlue,
      },
    },
    MuiAutocomplete: {
      paper: {
        backgroundColor: colors.hint,
      },
      option: {
        paddingLeft: 24,
        paddingRight: 24,
        fontSize: 14,
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.71,
        letterSpacing: 'normal',
        color: colors.white,
        '&[aria-selected="true"]': {
          backgroundColor: colors.slateBlue,
        },
      },
      inputRoot: {
        '&[class*="MuiOutlinedInput-root"]': {
          padding: 0,
          '&> .MuiAutocomplete-input': {
            paddingTop: 25,
            paddingLeft: 16,
            paddingBottom: 11,
            paddingRight: 0,
            '&:first-child': {
              paddingLeft: 16,
            },
          },
        },
      },
      clearIndicator: {
        color: colors.slateBlue,
      },
      popupIndicator: {
        color: colors.slateBlue,
      },
    },
    MuiCard: {
      root: {
        borderRadius: 15,
      },
    },
    MuiFormControlLabel: {
      root: {
        '&.checkbox': {
          height: 60,
          marginLeft: -16,
          '&> $label': {
            fontSize: 14,
            fontWeight: 'normal',
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 'normal',
            color: colors.slateBlue,
          },
        },
      },
    },
    MuiCheckbox: {
      root: {
        padding: 16,
        color: colors.slateBlue,
      },
    },
  },
});

export { theme, darkTheme };
