import * as Types from '../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type AssignAchievementToMutationVariables = {
  achievementId: Types.Scalars['Int'];
  employeeIds?: Types.Maybe<Array<Types.Scalars['Int']>>;
  mode: Types.ElementMode;
};


export type AssignAchievementToMutation = (
  { __typename?: 'Mutation' }
  & { assignAchievementTo: (
    { __typename?: 'AssignmentsCreated' }
    & Pick<Types.AssignmentsCreated, 'successCount' | 'failed'>
  ) }
);


export const AssignAchievementToDocument = gql`
    mutation assignAchievementTo($achievementId: Int!, $employeeIds: [Int!], $mode: ElementMode!) {
  assignAchievementTo(achievement: $achievementId, employees: $employeeIds, mode: $mode) {
    successCount
    failed
  }
}
    `;

/**
 * __useAssignAchievementToMutation__
 *
 * To run a mutation, you first call `useAssignAchievementToMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAssignAchievementToMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [assignAchievementToMutation, { data, loading, error }] = useAssignAchievementToMutation({
 *   variables: {
 *      achievementId: // value for 'achievementId'
 *      employeeIds: // value for 'employeeIds'
 *      mode: // value for 'mode'
 *   },
 * });
 */
export function useAssignAchievementToMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AssignAchievementToMutation, AssignAchievementToMutationVariables>) {
        return ApolloReactHooks.useMutation<AssignAchievementToMutation, AssignAchievementToMutationVariables>(AssignAchievementToDocument, baseOptions);
      }
export type AssignAchievementToMutationHookResult = ReturnType<typeof useAssignAchievementToMutation>;
export type AssignAchievementToMutationResult = ApolloReactCommon.MutationResult<AssignAchievementToMutation>;
export type AssignAchievementToMutationOptions = ApolloReactCommon.BaseMutationOptions<AssignAchievementToMutation, AssignAchievementToMutationVariables>;