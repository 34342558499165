import { ActionType } from '../_constants';
import { authService } from '../_services';
import { createRemoteAction } from './_helpers/createRemoteAction';

const login = createRemoteAction<
  { email: string; password: string },
  { accessToken: string; refreshToken: string }
>(
  ActionType.LOGIN_REQUEST,
  ActionType.LOGIN_SUCCESS,
  ActionType.LOGIN_FAILURE,
  authService.login
);

const refreshToken = createRemoteAction<
  string,
  { accessToken: string; refreshToken: string }
>(
  ActionType.REFRESH_TOKEN_REQUEST,
  ActionType.REFRESH_TOKEN_SUCCESS,
  ActionType.REFRESH_TOKEN_FAILURE,
  authService.refreshToken
);

/*const login = (email: string, password: string) => (dispatch: Dispatch) => {
  dispatch(request());
  return new Promise((resolve, reject) =>
    authService
      .login(email, password)
      .then(({ data: { accessToken, refreshToken } }) => {
        dispatch(success({ accessToken, refreshToken }));
        resolve({ accessToken, refreshToken });
      })
      .catch((error) => {
        dispatch(failure(error));
        reject(error);
      })
  );

  function request() {
    return { type: ActionTypes.LOGIN_REQUEST };
  }

  function success({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) {
    return {
      type: ActionTypes.LOGIN_SUCCESS,
      payload: { accessToken, refreshToken },
    };
  }

  function failure(error: Error) {
    return { type: ActionTypes.LOGIN_FAILURE, payload: error };
  }
};*/

/*const refreshToken = (refreshToken: string) => (
  dispatch: Dispatch
) => {
  dispatch(request());
  return new Promise((resolve, reject) =>
    authService
      .refreshToken(refreshToken)
      .then(({ data: { accessToken, refreshToken } }) => {
        dispatch(success({ accessToken, refreshToken }));
        resolve({ accessToken, refreshToken });
      })
      .catch((error) => {
        console.error('refresh', error);
        dispatch(failure(error));
        dispatch(logout());
        reject(error);
      })
  );

  function request() {
    return { type: ActionTypes.REFRESH_TOKEN_REQUEST };
  }

  function success({
    accessToken,
    refreshToken,
  }: {
    accessToken: string;
    refreshToken: string;
  }) {
    return {
      type: ActionTypes.REFRESH_TOKEN_SUCCESS,
      payload: { accessToken, refreshToken },
    };
  }

  function failure(error: Error) {
    return { type: ActionTypes.REFRESH_TOKEN_FAILURE, payload: error };
  }
};*/

export const logout = () => {
  return { type: ActionType.LOGOUT };
};

const restore = (email: string) => (/*dispatch*/) => {
  return authService.restore(email);
};

export const authActions = {
  login,
  logout,
  refreshToken,
  restore,
};
