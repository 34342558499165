import { ActionType } from '../_constants';

const initialState = {
  email: null,
  employee: null,
};

const user = (
  state = initialState,
  action: {
    type: ActionType.SET_USER;
    payload: { email: string; employee: number };
  }
) => {
  switch (action.type) {
    case ActionType.SET_USER:
      return {
        ...state,
        email: action.payload.email,
        employee: action.payload.employee,
      };
    default:
      return state;
  }
};
export default user;
