import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const DropdownIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 20 20" {...props}>
    <path
      fill={props.fill}
      fillRule="evenodd"
      d="M10.8 12.618a1.032 1.032 0 01-1.6 0l-4-5.09a.926.926 0 01-.2-.573C5 6.427 5.448 6 6 6h8c.216 0 .427.067.6.19a.926.926 0 01.2 1.337l-4 5.091z"
    />
  </SvgIcon>
);
export default DropdownIcon;
