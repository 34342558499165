import { Formik } from 'formik';
import plural from 'plural-ru';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { dialogsActions } from '../../../../../_actions';
import { ActionType } from '../../../../../_constants';
import adaptFilePath from '../../../../../_helpers/adaptFilePath';
import QueryView from '../../../../_shared/QueryView';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import Form from './_shared/Form';
import {
  BaseAchievementFieldsFragment,
  useDeleteAchievementMutation,
  useGetAchievementQuery,
  useUpdateAchievementMutation,
  UpdateAchievementMutationVariables,
} from './_shared/queries';
import {
  AchievementCancel,
  AchievementChange,
  AchievementDelete,
  Fields,
} from './_shared/types';
import validation from './_shared/validation';

const adaptForUpdate = ({
  id,
  animationBase64,
  categoryId,
  colorId,
  description,
  imageBase64,
  isActive,
  message,
  name,
  weightId,
}: Fields): UpdateAchievementMutationVariables => ({
  id,
  animationBase64,
  categoryId,
  colorId: +colorId,
  description,
  imageBase64,
  isActive,
  message,
  name,
  weightId: +weightId,
});

const getAdaptedFormData = ({
  id,
  animation,
  category,
  color,
  description,
  image,
  isActive,
  message,
  name,
  weight,
}: BaseAchievementFieldsFragment): Fields => ({
  id,
  animationBase64: ``,
  animationSrc: animation ? animation : ``,
  categoryId: category.id,
  colorId: String(color.id),
  description,
  imageBase64: ``,
  imageSrc: image,
  isActive,
  message,
  name,
  weightId: String(weight.id),
});

interface Props {
  onCancel: AchievementCancel;
  onChange: AchievementChange;
  onDelete: AchievementDelete;
}

const Edit: FC<Props> = ({ onCancel, onChange, onDelete }) => {
  const dispatch = useDispatch();
  const { achievementId } = useParams();
  const [deleteAchievementMutation] = useDeleteAchievementMutation();
  const [updateAchievementMutation] = useUpdateAchievementMutation();

  const achievementQueryResult = useGetAchievementQuery({
    variables: {
      id: +achievementId,
    },
  });

  const deleteAchievement = async (
    achievement: BaseAchievementFieldsFragment
  ) => {
    try {
      const { data, errors } = await deleteAchievementMutation({
        variables: {
          id: achievement.id,
        },
      });
      if (data) {
        onDelete();
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            className: `success`,
            delay: 3000,
            text: `Достижение «${achievement.name}» успешно удалено`,
          },
        });
      } else if (errors) {
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            className: `error`,
            delay: 3000,
            text: `Ошибки удаления ${errors.map(
              ({ extensions }) => extensions?.exeption.detail
            )}`,
          },
        });
      }
    } catch (error) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибка удаления ${error.message}`,
        },
      });
    }
  };

  const handleDelete = (achievement: BaseAchievementFieldsFragment) => {
    dispatch(
      dialogsActions.showConfirmDialog({
        applyText: `Удалить`,
        cancelText: `Отмена`,
        message: `Вы отзовете достижение «${achievement.name}» для ${plural(
          achievement.assignments.total,
          `%d игрока`,
          `%d игроков`,
          `%d игроков`
        )}.`,
        title: `Удалить достижение?`,
        onApply: () => {
          return deleteAchievement(achievement);
        },
      })
    );
  };

  const handleSubmit = async (formData: Fields) => {
    const { data, errors } = await updateAchievementMutation({
      variables: adaptForUpdate(formData),
    });

    if (data) {
      onChange(data.updateAchievement.id);
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: `success`,
          delay: 3000,
          text: `Достижение «${data.updateAchievement.name}» успешно изменено`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: `error`,
          delay: 3000,
          text: `Ошибки сохранения ${errors.map(({ message }) => message)}`,
        },
      });
    }
  };

  return (
    <QueryView
      result={achievementQueryResult}
      renderData={(data) => {
        const { animation, image } = data.achievement;
        const achievement = {
          ...data.achievement,
          animation: animation && adaptFilePath(animation),
          image: adaptFilePath(image),
        };
        const playersCount = achievement.assignments.total;
        return (
          <Page
            noScroll
            header={<AppHeader title={achievement.name} />}
            content={
              <Formik
                initialValues={getAdaptedFormData(achievement)}
                validationSchema={validation}
                onReset={onCancel}
                onSubmit={handleSubmit}
              >
                <Form
                  playersCount={playersCount}
                  onDelete={() => handleDelete(achievement)}
                />
              </Formik>
            }
          />
        );
      }}
    />
  );
};

export default Edit;
