import { CardContent } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components/macro';
import { EmployeeOrderBy } from '../../../../../../_graphql/schema';
import CheckboxDropdownMenu from '../../../../../_shared/CheckboxDropdownMenu';
import HighlightedText from '../../../../../_shared/HighlightedText';
import { HeadCell, StyledFC } from '../../../../../_shared/types';
import DropdownIcon from '../../../../../icons/DropdownIcon';
import Loader from '../../../_shared/Loader';

const headCells: HeadCell<EmployeeOrderBy>[] = [
  {
    id: 'avatar',
    numeric: false,
    sortable: false,
    align: 'left',
    disablePadding: true,
    label: '',
    minWidth: 100,
  },
  {
    id: 'name',
    numeric: false,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'Имя',
    minWidth: 100,
    orderBy: EmployeeOrderBy.Name,
  },
  {
    id: 'rootSubdivisionName',
    numeric: false,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'Подразделение',
    minWidth: 100,
    orderBy: EmployeeOrderBy.RootSubdivisionName,
  },
  {
    id: 'position',
    numeric: false,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'Должность',
    minWidth: 100,
    orderBy: EmployeeOrderBy.Position,
  },
  {
    id: 'id',
    numeric: false,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'Табельный номер',
    minWidth: 170,
    orderBy: EmployeeOrderBy.Id,
  },
];

interface HeadProps {
  isChecked: boolean;
  isIndeterminate: boolean;
  order: string;
  orderBy: string;
  showSelectAllMenuItem: boolean;
  showSelectAllOnPageMenuItem: boolean;
  onRequestSort: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    property: EmployeeOrderBy
  ) => void;
  onSelectAllClick: (checked: boolean) => void;
  onSelectAllOnPageClick: () => void;
  onRowClick?: (event: Event, id: number) => void;
}

const GrantEmployeesTableHead_: StyledFC<HeadProps> = ({
  isChecked,
  isIndeterminate,
  order,
  orderBy,
  showSelectAllMenuItem,
  showSelectAllOnPageMenuItem,
  onRequestSort,
  onSelectAllClick,
  onSelectAllOnPageClick,
  className,
}) => {
  const createSortHandler = (property: any) => (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={className}>
        <TableCell
          className="cell"
          style={{ paddingLeft: 0, paddingRight: 0 }}
          padding="checkbox"
        >
          <CheckboxDropdownMenu
            onSelectAllClick={onSelectAllClick}
            onSelectAllOnPageClick={onSelectAllOnPageClick}
            indeterminate={isIndeterminate}
            checked={isChecked}
            showSelectAllMenuItem={showSelectAllMenuItem}
            showSelectAllOnPageMenuItem={showSelectAllOnPageMenuItem}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="cell"
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={
              orderBy === headCell.id
                ? order === 'desc'
                  ? 'desc'
                  : 'asc'
                : undefined
            }
          >
            <TableSortLabel
              disabled={!headCell.sortable}
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id
                  ? order === 'desc'
                    ? 'desc'
                    : 'asc'
                  : undefined
              }
              onClick={createSortHandler(headCell.id)}
              IconComponent={DropdownIcon as React.FC}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className="visuallyHidden" />
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const GrantEmployeesTableHead = styled(GrantEmployeesTableHead_)`
  .cell {
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    color: ${({ theme }) => theme.colors.slateBlue};
    padding-top: 1px;
    padding-bottom: 13px;
    border-color: ${({ theme }) => theme.colors.hint};
  }
`;

interface RowProps {
  index: number;
  row: {
    id: number;
    avatar: string;
    isActive: boolean;
    name: string;
    position: string;
    rootSubdivisionName: string;
  };
  searchField: string;
  searchValue: string;
  selected: number[];
  handleClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowId: number
  ) => void;
  isSelected: (id: number) => boolean;
}

const Row_: StyledFC<RowProps> = ({
  className,
  index,
  row,
  searchField,
  searchValue,
  selected,
  handleClick,
  isSelected,
}) => {
  const isItemSelected = isSelected(row.id);
  const labelId = `grant-employees-table-checkbox-${index}`;

  return (
    <TableRow
      className={className}
      hover
      onClick={(event) => handleClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox" className={['cell', 'checkbox'].join(' ')}>
        <Checkbox
          className="checkbox"
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell align="left" className="cell">
        <Badge
          className={`badge ${row.isActive ? 'active' : ''}`}
          overlap="circle"
          badgeContent=" "
          variant="dot"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <Avatar
            className="avatar"
            src={
              row.avatar
                ? process.env.REACT_APP_BASE_PATH + row.avatar
                : '/assets/images/male-default-avatar.svg'
            }
          />
        </Badge>
      </TableCell>
      <TableCell align="left" className="cell">
        <HighlightedText
          text={row.name}
          skip={searchField !== 'name'}
          searchValue={searchValue}
        />
      </TableCell>
      <TableCell align="left" className="cell">
        <HighlightedText
          text={row.rootSubdivisionName}
          skip={searchField !== 'rootSubdivisionName'}
          searchValue={searchValue}
        />
      </TableCell>
      <TableCell align="left" className="cell">
        <HighlightedText
          text={row.position}
          skip={searchField !== 'position'}
          searchValue={searchValue}
        />
      </TableCell>
      <TableCell align="left" className="cell">
        <HighlightedText
          text={row.id.toString()}
          skip={searchField !== 'id'}
          searchValue={searchValue}
        />
      </TableCell>
    </TableRow>
  );
};

const Row = styled(Row_)`
  &.Mui-selected {
    background-color: inherit;
  }
  .cell {
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    &:first-child {
      border-radius: 10px 0 0 10px;
    }
    &:nth-child(2) {
      width: 10%;
    }
    &:nth-child(3) {
      width: 25%;
    }
    &:nth-child(4) {
      width: 18%;
    }
    &:nth-child(5) {
      width: 25%;
    }
    &:nth-child(6) {
      width: 150px;
    }
    &:last-child {
      border-radius: 0 10px 10px 0;
    }
    &.checkbox {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .checkbox {
    padding: 0;
  }
  .badge {
    & > .MuiBadge-badge {
      width: 12px;
      height: 12px;
      border: solid 2px ${({ theme }) => theme.colors.card};
      background-color: ${({ theme }) => theme.colors.lightGreyBlue};
      border-radius: 50%;
    }
    &.active {
      & > .MuiBadge-badge {
        background-color: #19c4ac;
      }
    }
  }
  .avatar {
    width: 60px;
    height: 60px;
  }
`;

interface TableProps {
  isHeadChecked: boolean;
  isHeadIndeterminate: boolean;
  loading: boolean;
  order: string;
  orderBy: string;
  searchField: string;
  searchValue: string;
  selected: number[];
  total?: number;
  rows: any[] | undefined;
  showSelectAllMenuItem: boolean;
  showSelectAllOnPageMenuItem: boolean;
  isSelected: (id: number) => boolean;
  onRequestSort: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    property: EmployeeOrderBy
  ) => void;
  onRowClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowId: number
  ) => void;
  onSelectAllClick: (checked: boolean) => void;
  onSelectAllOnPageClick: () => void;
  handleClick?: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowId: number
  ) => void;
}

const GrantEmployeesTable_: StyledFC<TableProps> = ({
  className,
  isHeadChecked = false,
  isHeadIndeterminate = false,
  loading = false,
  order,
  orderBy,
  rows = [],
  searchField,
  searchValue,
  selected,
  showSelectAllMenuItem,
  showSelectAllOnPageMenuItem,
  isSelected = (id) => selected.indexOf(id) !== -1,
  onRequestSort = (event, property) =>
    console.log('handleRequestSort', event, property),
  onRowClick = (event, id) => console.log('onRowClick', event, id),
  onSelectAllClick = (event) => console.log('onSelectAllClick', event),
  onSelectAllOnPageClick = () => console.log('onSelectAllOnPageClick'),
}) => {
  return (
    <div className={className}>
      <Card elevation={0} className="card">
        <CardContent className="cardContent">
          <TableContainer className="tableContainer">
            <PerfectScrollbar>
              <Table stickyHeader className="table">
                <GrantEmployeesTableHead
                  isChecked={isHeadChecked}
                  isIndeterminate={isHeadIndeterminate}
                  showSelectAllOnPageMenuItem={showSelectAllOnPageMenuItem}
                  showSelectAllMenuItem={showSelectAllMenuItem}
                  order={order.toString().toLowerCase()}
                  orderBy={orderBy}
                  onSelectAllClick={onSelectAllClick}
                  onSelectAllOnPageClick={onSelectAllOnPageClick}
                  onRequestSort={onRequestSort}
                />
                <TableBody>
                  {rows.map((row, idx) => (
                    <Row
                      isSelected={isSelected}
                      key={row.id}
                      row={row}
                      index={idx}
                      selected={selected}
                      handleClick={onRowClick}
                      searchField={searchField}
                      searchValue={searchValue}
                    />
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </TableContainer>
          {loading && <Loader blurred />}
        </CardContent>
      </Card>
    </div>
  );
};

const GrantEmployeesTable = styled(GrantEmployeesTable_)`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
  .card {
    overflow: hidden;
    height: 100%;
    .paper {
      width: 100%;
      overflow: hidden;
      height: 100%;
    }
    .cardContent {
      position: relative;
      padding: 32px 48px 24px;
      overflow: hidden;
      height: 100%;
    }
    .tableContainer {
      height: 100%;
    }
    .table {
      min-width: 750px;
    }
    .visuallyHidden {
      border: 0;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      top: 20px;
      width: 1px;
    }
  }
`;

export default GrantEmployeesTable;
