import { Formik } from 'formik';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../../../../_constants';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import Form from './_shared/Form';
import {
  CreateAchievementMutationVariables,
  useCreateAchievementMutation,
} from './_shared/queries';
import { AchievementCancel, AchievementCreate, Fields } from './_shared/types';
import validation from './_shared/validation';

const adaptForCreate = ({
  animationBase64,
  categoryId,
  colorId,
  description,
  imageBase64,
  isActive,
  message,
  name,
  weightId,
}: Fields): CreateAchievementMutationVariables => ({
  animationBase64,
  categoryId,
  colorId: +colorId,
  description,
  imageBase64,
  isActive,
  message,
  name,
  weightId: +weightId,
});

const getEmptyFormData = (): Fields => ({
  animationBase64: ``,
  animationSrc: ``,
  categoryId: 0,
  colorId: ``,
  description: ``,
  id: 0,
  imageBase64: ``,
  imageSrc: ``,
  isActive: true,
  message: ``,
  name: ``,
  weightId: ``,
});

interface Props {
  onCancel: AchievementCancel;
  onCreate: AchievementCreate;
}

const New: FC<Props> = ({ onCancel, onCreate }) => {
  const dispatch = useDispatch();
  const [createAchievementMutation] = useCreateAchievementMutation();

  const handleSubmit = async (formData: Fields) => {
    const { data, errors } = await createAchievementMutation({
      variables: adaptForCreate(formData),
    });

    if (data) {
      onCreate(data.createAchievement.id);
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: `success`,
          delay: 3000,
          text: `Достижение «${data.createAchievement.name}» создано`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: `error`,
          delay: 3000,
          text: `Ошибки при создании достижения ${errors.map(
            ({ message }) => message
          )}`,
        },
      });
    }
  };

  return (
    <Page
      noScroll
      header={<AppHeader title="Новое достижение" />}
      content={
        <Formik
          initialValues={getEmptyFormData()}
          validationSchema={validation}
          onReset={onCancel}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      }
    />
  );
};

export default New;
