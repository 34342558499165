import { useField } from 'formik';
import React, { FC } from 'react';
import { toBase64 } from '../../../_utils';
import Uploader, { FileChange } from '../Uploader';
import { InputFileProps } from './types';

export const InputFile: FC<InputFileProps> = ({ file: name, src, mode }) => {
  const [fileField, , fileHelper] = useField<string>(name);
  const [srcField, , srcHelper] = useField<string>(src);

  const handleChange = async ({ file, src }: FileChange) => {
    srcHelper.setTouched(true);
    srcHelper.setValue(src ? src : ``);

    try {
      const base64 = file && (await toBase64(file));
      fileHelper.setValue(base64 ? base64 : ``);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Uploader
      className="group__uploader"
      file={fileField.value as any}
      mode={mode}
      src={srcField.value}
      onChange={handleChange}
    />
  );
};
