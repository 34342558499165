import { ActionType } from '../_constants';

type ShowToastProps = {
  text: string;
  className: string;
  delay?: number;
};

const showToast = ({ text, className, delay = 3000 }: ShowToastProps) => {
  return {
    type: ActionType.ADD_TOAST,
    payload: { text, className, delay },
  };
};
const removeToast = (toastId: number) => {
  return {
    type: ActionType.REMOVE_TOAST,
    payload: toastId,
  };
};

export const toastsActions = {
  showToast,
  removeToast,
};
