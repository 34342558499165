import Typography from '@material-ui/core/Typography';
import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { ActionType } from '../../../_constants';
import API from '../../../_services/api-service';
import EmployeesPage from './EmployeesPage';
import MainPage from './MainPage';
import Projects from './Projects';
import ProjectNew from './Projects/New';
import AdminRoute from './_shared/AdminRoute';

export type User = { email: string; employee: string };

const LoggedInPage: FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const setUser = useCallback(
    (user: User) => () =>
      dispatch({ type: ActionType.SET_USER, payload: user }),
    [dispatch]
  );
  const email = useSelector<{ user: { email: string } }>((state) => ({
    email: state.user.email,
  }));

  useEffect(() => {
    API.post('', {
      query: `{ me { employee { id }, email } }`,
    }).then(({ data: { data } }) => setUser(data.me));
  }, [setUser]);

  return (
    email && (
      <div style={{ minHeight: '100vh' }}>
        <Switch>
          <Route path={`/`} exact render={() => <Redirect to="projects" />} />
          <Route path={`/projects`} exact component={Projects} />
          <Route path={`/projects/:projectId(\\d+)`} component={MainPage} />
          <AdminRoute path={`/projects/new`} component={ProjectNew} />
          <AdminRoute path={`/players`} component={EmployeesPage} />
          <Route
            render={() => (
              <Typography variant="h4">
                {' '}
                Страница {location.pathname} не найдена{' '}
              </Typography>
            )}
          />
        </Switch>
      </div>
    )
  );
};

export default LoggedInPage;
