import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AchievementsPage from './Achievements';
import CategoriesPage from './Categories';

interface Props {
  match: { path: string; url: string };
}

const AwardsPage: FC<Props> = ({ match }) => {
  return (
    <Switch>
      <Route path={`${match.path}/achievements`} component={AchievementsPage} />
      <Route path={`${match.path}/categories`} component={CategoriesPage} />
      <Redirect to={`${match.url}/achievements`} />
    </Switch>
  );
};

export default AwardsPage;
