import Button from '@material-ui/core/Button';
import React, { Fragment } from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../_constants';
import ChevronRightIcon from '../icons/ChevronRightIcon';
import { StyledFC } from './types';

interface Props {
  count: number;
  page: number;
  perPage: number;
  onChangePage: (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => void;
}

const Paginator_: StyledFC<Props> = ({
  className,
  count,
  page,
  perPage,
  onChangePage,
}) => {
  const pages = Math.ceil(count / perPage);

  const onPrevButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onChangePage(e, page > 0 ? page - 1 : 0);
  };
  const onNextButtonClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    onChangePage(e, page < pages - 1 ? page + 1 : pages - 1);
  };
  const getButtonClass = (condition: boolean) => {
    return condition ? 'button selected' : 'button';
  };

  return (
    <>
      {pages > 1 && (
        <div className={className}>
          <Button
            className="button icon"
            disabled={page === 0}
            onClick={onPrevButtonClick}
          >
            <ChevronRightIcon
              style={{
                color: colors.slateBlue,
                fontSize: 18,
                transform: 'rotate(180deg)',
              }}
            />
          </Button>
          {!(pages > 5) &&
            [...Array(pages)].map((value, idx) => (
              <Fragment key={idx}>
                <Button
                  className={getButtonClass(idx === page)}
                  onClick={(e) => onChangePage(e, idx)}
                >
                  {idx + 1}
                </Button>
              </Fragment>
            ))}
          {pages > 5 && (
            <>
              <Button
                className={getButtonClass(0 === page)}
                onClick={(e) => onChangePage(e, 0)}
              >
                1
              </Button>
              {page > 2 && (
                <Button disabled className="button">
                  …
                </Button>
              )}
              {[...Array(3)].map((value, idx) => (
                <Fragment key={idx}>
                  {!(page > 2) && (
                    <Button
                      className={getButtonClass(idx + 1 === page)}
                      onClick={(e) => onChangePage(e, idx + 1)}
                    >
                      {idx + 2}
                    </Button>
                  )}
                  {page > 2 && pages - page > 3 && (
                    <Button
                      className={getButtonClass(idx + page - 1 === page)}
                      onClick={(e) => onChangePage(e, idx + page - 1)}
                    >
                      {idx + page}
                    </Button>
                  )}
                  {page > 2 && !(pages - page > 3) && (
                    <Button
                      className={getButtonClass(idx + pages - 4 === page)}
                      onClick={(e) => onChangePage(e, pages - 4 + idx)}
                    >
                      {pages - 3 + idx}
                    </Button>
                  )}
                </Fragment>
              ))}
              {pages - page > 3 && (
                <Button disabled className="button">
                  …
                </Button>
              )}
              <Button
                className={getButtonClass(pages - 1 === page)}
                onClick={(e) => onChangePage(e, pages - 1)}
              >
                {pages}
              </Button>
            </>
          )}
          <Button
            className="button icon"
            disabled={page === pages - 1}
            onClick={onNextButtonClick}
          >
            <ChevronRightIcon
              style={{
                color: colors.slateBlue,
                fontSize: 18,
              }}
            />
          </Button>
        </div>
      )}
    </>
  );
};

const Paginator = styled(Paginator_)`
  display: flex;
  align-items: center;
  min-height: 64px;
  margin-top: auto;
  > .button {
    height: 36px;
    min-width: 36px;
    border-radius: 4px;
    margin: 0 6px;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
    .mui-disabled {
      .MuiButton-label {
        color: ${colors.slateBlue};
      }
    }
    &.selected {
      background-color: ${colors.slateBlue} !important;
    }
  }
`;

export default Paginator;
