import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import React, { FC } from 'react';
import { InputProps } from './types';

export const InputReadonly: FC<InputProps> = ({ name, label, ...rest }) => {
  const [field] = useField<string>(name);

  return (
    <TextField
      defaultValue={field.value}
      disabled
      fullWidth
      label={label}
      name={name}
      variant="outlined"
      {...rest}
    />
  );
};
