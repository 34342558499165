import { createGlobalStyle } from 'styled-components/macro';

export default createGlobalStyle`
 
  .Big-Empty-Search {
    font-family: OpenSans;
    font-size: 40px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #404d6e;
  }
  
  .Badge-Label {
    font-family: OpenSans;
    font-size: 36px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
  }
  
  .Header-Style {
    font-family: OpenSans;
    font-size: 28px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Attached-File-Text {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.2;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Search-Style-Active {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
  }
  
  .Search-Style {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Text-Style {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--light-grey-blue);
  }
  
  .Badge-Details {
    font-family: OpenSans;
    font-size: 20px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
  }
  
  .-Style {
    font-family: OpenSans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Main-List-Title {
    font-family: OpenSans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
  }
  
  .Main-List-Digits {
    font-family: OpenSans;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--light-grey-blue);
  }
  
  .SideBar-Text-Inactive {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.57;
    letter-spacing: normal;
    color: var(--light-grey-blue);
  }
  
  .SideBar-Text {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.57;
    letter-spacing: normal;
    color: var(--white);
  }
  
  .Customization-Style {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 3.57;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Button-Text-Style {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
  }
  
  .Add-File-Link {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: var(--slate-blue);
  }
  
  .-Style {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--white);
  }
  
  .Main-Table-Text {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--white);
  }
  
  .Main-Table-Inactive-text {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #a6aab3;
  }
  
  .Hint-Text {
    font-family: OpenSans;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Column-Title-Selected {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Small-Button {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #b7bfca;
  }
  
  .-Details-Style {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    color: var(--blue-grey);
  }
  
  .-Details-Style {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 2;
    letter-spacing: normal;
    color: var(--slate-blue);
  }
  
  .Profile-Info {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.67;
    letter-spacing: normal;
    text-align: center;
    color: var(--white);
  }
  
  .Error-message {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: var(--darkish-pink);
  }
  
  .Calendar-Digits-White {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: right;
    color: var(--white);
  }
  
  .Calendar-Digits-Selected {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: right;
    color: var(--hint);
  }
  
  .Calendar-Digits {
    font-family: OpenSans;
    font-size: 12px;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: right;
    color: var(--blue-grey);
  }
`;
