import * as Types from '../../../../../_graphql/schema';

export type ProjectCancel = () => void;
export type ProjectDelete = () => void;

export type SubdivisionType = Pick<Types.Subdivision, 'id' | 'name'>;

export enum Field {
  id = 'id',
  description = 'description',
  imageBase64 = 'imageBase64',
  imageSrc = 'imageSrc',
  isActive = 'isActive',
  name = 'name',
  subdivisions = 'subdivisions',
}

export interface Fields {
  [Field.id]: number;
  [Field.description]: string;
  [Field.imageBase64]: string;
  [Field.imageSrc]: string;
  [Field.isActive]: boolean;
  [Field.name]: string;
  [Field.subdivisions]: SubdivisionType[];
}
