import * as Types from '../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetProjectNameQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetProjectNameQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id' | 'name'>
  ) }
);


export const GetProjectNameDocument = gql`
    query getProjectName($id: Int!) {
  project(id: $id) {
    id
    name
  }
}
    `;

/**
 * __useGetProjectNameQuery__
 *
 * To run a query within a React component, call `useGetProjectNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectNameQuery, GetProjectNameQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(GetProjectNameDocument, baseOptions);
      }
export function useGetProjectNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectNameQuery, GetProjectNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectNameQuery, GetProjectNameQueryVariables>(GetProjectNameDocument, baseOptions);
        }
export type GetProjectNameQueryHookResult = ReturnType<typeof useGetProjectNameQuery>;
export type GetProjectNameLazyQueryHookResult = ReturnType<typeof useGetProjectNameLazyQuery>;
export type GetProjectNameQueryResult = ApolloReactCommon.QueryResult<GetProjectNameQuery, GetProjectNameQueryVariables>;