import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDebounce } from '@react-hook/debounce';
import React, { useState } from 'react';
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { AssignmentOrderBy, OrderDir } from '../../../../../_graphql/schema';
import { dateToString } from '../../../../../_helpers/dateToString';
import { fileUrl } from '../../../../../_helpers/file';
import ChevronRightIcon from '../../../../icons/ChevronRightIcon';
import EditIcon from '../../../../icons/EditIcon';
import HighlightedText from '../../../../_shared/HighlightedText';
import ImageCell from '../../../../_shared/ImageCell';
import Paginator from '../../../../_shared/Paginator';
import QueryView from '../../../../_shared/QueryView';
import SearchBar from '../../../../_shared/SearchBar';
import SharedTable from '../../../../_shared/SharedTable';
import { HeadCell, StyledFC } from '../../../../_shared/types';
import GrantPlayerAchievementsPage from '../../MainPage/GrantPlayerAchievementsPage';
import AppHeader from '../../MainPage/_shared/AppHeader';
import Page from '../Page';
import { useGetEmployeeAssignedListQuery } from './queries';

const searchBySource = [
  {
    label: 'Название достижения',
    value: 'name',
  },
  {
    label: 'Текст',
    value: 'description',
  },
  {
    label: 'Дата публикации',
    value: 'createdAt',
  },
  {
    label: 'ID достижения',
    value: 'achievementId',
  },
];

const headCells: HeadCell<AssignmentOrderBy>[] = [
  {
    id: 'assignedImage',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
  },
  {
    id: 'name',
    numeric: false,
    disablePadding: false,
    label: 'Название достижения',
    orderBy: AssignmentOrderBy.Name,
  },
  {
    id: 'description',
    numeric: true,
    disablePadding: false,
    label: 'Текст',
    orderBy: AssignmentOrderBy.Description,
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Дата публикации',
    orderBy: AssignmentOrderBy.CreatedAt,
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID достижения',
    orderBy: AssignmentOrderBy.Id,
  },
  {
    id: 'button',
    numeric: true,
    disablePadding: false,
    label: '',
    sortable: false,
  },
];

const EmployeeAchievementsPage_: StyledFC = ({ className }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    params: { playerId, projectId },
    path,
  } = useRouteMatch<{ playerId: string; projectId: string }>();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState({
    skip: 0,
    take: 10,
    searchField: 'name',
    orderBy: AssignmentOrderBy.Name,
    orderDir: OrderDir.Asc,
  });

  const [highlightAchievement, setHighlightAchievement] = useState<{
    id: number;
  }>();

  const result = useGetEmployeeAssignedListQuery({
    variables: {
      employeeId: parseInt(playerId),
      withImage: true,
      ...(projectId ? { projectId: parseInt(projectId) } : {}),
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });
  const isMemories = location.pathname.endsWith('/memories');
  if (isMemories) result.refetch();

  const onAddAchievementClick = () => {
    console.log('onAddAchievementClick');
    history.push(`assignments/grant`);
  };
  const onEditAchievementClick = (id: number) => {
    history.push(`assignments/${id}/edit`);
  };
  const onAchievementRowClick = (id: number) => {
    history.push(`assignments/${id}`);
  };
  const onGrantPlayerAchievementsPageClosed = () => {
    console.log('onGrantPlayerAchievementsPageClosed');
    history.push(`${location.pathname.split('/').slice(0, -1).join('/')}`);
  };
  const onGrantPlayerAchievementsPageGranted = (highlightAchievementListIds: {
    id: number;
  }) => {
    console.log('onGrantPlayerAchievementsPageGranted');
    setHighlightAchievement(highlightAchievementListIds);
    history.push(`${location.pathname.split('/').slice(0, -1).join('/')}`);
  };

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const onSearchFieldChange = (field: string) => {
    setSearchProps({
      ...searchProps,
      searchField: field,
    });
  };

  const onPageChange = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchProps({
      ...searchProps,
      skip: page * searchProps.take,
    });
  };

  const onRequestSort = (orderBy?: AssignmentOrderBy, orderDir?: OrderDir) => {
    setSearchProps({
      ...searchProps,
      orderBy: orderBy as AssignmentOrderBy,
      orderDir: orderDir as OrderDir,
    });
  };

  return (
    <QueryView
      result={result}
      renderData={(data) => {
        const Rows = () => {
          const achWithMemories = data.employee.assigned.items;
          const searchString = searchValue;
          const searchField = searchProps.searchField;

          return achWithMemories.map((row, index) => {
            const labelId = `achievements-table-checkbox-${index}`;
            return (
              <TableRow
                className={
                  highlightAchievement?.id === row.achievement.id
                    ? 'highlighted'
                    : ''
                }
                hover
                tabIndex={-1}
                key={row.achievement.id}
                onClick={() => onAchievementRowClick(row.achievement.id)}
                style={{ height: 72 }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  className="cell cellWidth"
                >
                  <ImageCell
                    imageSrc={fileUrl(row.achievement.image)}
                    assignmentImage={row.image ? fileUrl(row.image) : undefined}
                    isMemoriesPage
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={row.achievement.name}
                    searchValue={searchString}
                    skip={searchField !== 'name'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={row.achievement.description}
                    searchValue={searchString}
                    skip={searchField !== 'description'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={dateToString(new Date(row.createdAt), 'DD.MM.YYYY')}
                    searchValue={searchString}
                    skip={searchField !== 'createdAt'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={String(row.achievement.id)}
                    searchValue={searchString}
                    skip={searchField !== 'achievementId'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <IconButton
                    className="action-button"
                    onClick={(e) => {
                      e.stopPropagation();
                      onEditAchievementClick(row.achievement.id);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          });
        };

        const urlArray = location.pathname.split('/');
        const linkProfile = urlArray.slice(0, -1).join('/');
        const linkPlayers = urlArray.slice(0, -2).join('/');

        const headerTitle = (
          <HeaderTitle>
            <NavLink className="navLink" to={linkPlayers}>
              Игроки
            </NavLink>
            <ChevronRightIcon className="headerIcon" />
            <NavLink className="navLink" to={linkProfile}>
              Профиль игрока
            </NavLink>
            <ChevronRightIcon className="headerIcon" />
            Достижения
          </HeaderTitle>
        );

        return (
          <>
            <Page
              header={<AppHeader title={headerTitle} />}
              content={
                <div className={className}>
                  <ButtonGroup
                    className="linkGroup"
                    variant="outlined"
                    color="primary"
                    aria-label="contained primary button group"
                  >
                    <Button
                      component={NavLink}
                      exact
                      to="assignments"
                      className="linkButton"
                      activeClassName="selected"
                    >
                      Достижения
                    </Button>
                    <Button
                      component={NavLink}
                      exact
                      to="memories"
                      className="linkButton"
                      activeClassName="selected"
                    >
                      Воспоминания
                    </Button>
                  </ButtonGroup>
                  <div className="buttonsWrapper">
                    <SearchBar
                      searchBySource={searchBySource}
                      placeholder={`Поиск достижений...`}
                      onSearchValueChange={onSearchValueChange}
                      onSearchFieldChange={onSearchFieldChange}
                    />
                    <div style={{ display: 'flex' }}>
                      <Button
                        className="addAchievementButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        type="submit"
                        onClick={onAddAchievementClick}
                      >
                        Выдать достижение
                      </Button>
                    </div>
                  </div>
                  <SharedTable<AssignmentOrderBy>
                    onRequestSort={onRequestSort}
                    rows={Rows()}
                    headCells={headCells}
                  />
                  <Paginator
                    count={data.employee.assigned?.total}
                    perPage={searchProps.take}
                    page={Math.ceil(searchProps.skip / searchProps.take)}
                    onChangePage={onPageChange}
                  />
                </div>
              }
            />
            <Switch>
              <Route
                path={`${path}/grant`}
                render={() => (
                  <PageContainer>
                    <GrantPlayerAchievementsPage
                      onClosed={onGrantPlayerAchievementsPageClosed}
                      onGranted={onGrantPlayerAchievementsPageGranted}
                    />
                  </PageContainer>
                )}
              />
            </Switch>
          </>
        );
      }}
    />
  );
};

const EmployeeAchievementsPage = styled(EmployeeAchievementsPage_)`
  > .buttonsWrapper {
    min-height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
    flex-wrap: wrap;
    .addAchievementButton {
      height: 36px;
      padding: 0 24px 0 16px;
      border-radius: 18px;
      background-color: transparent;
      border: 2px solid #2b3757;
      :hover {
        background-color: rgb(64, 76, 107) !important;
      }
    }
  }
  > .linkGroup {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 25px;
    .linkButton {
      height: 36px;
      width: 160px;
      border-radius: 8px;
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.hint};
      font-size: 12px;
      :first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      :last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.selected {
        background-color: ${({ theme }) => theme.colors.hint};
      }
    }
  }
  .cellWidth {
    &:before {
      position: absolute;
      bottom: -1px;
      right: 0;
      width: 100% !important;
      border-top: 1px solid ${({ theme }) => theme.colors.hint};
      content: ' ';
    }
    &:first-child {
      width: 9% !important;
    }
    &:nth-child(2) {
      width: 18% !important;
    }
    &:nth-child(3) {
      width: 44% !important;
      max-width: 44% !important;
    }
    &:nth-child(4) {
      width: 12% !important;
    }
    &:nth-child(5) {
      width: 12% !important;
    }
    &:nth-child(6) {
      width: 5% !important;
    }
  }
`;

const HeaderTitle = styled.span`
  display: flex;
  align-items: center;
  > .navLink {
    color: ${({ theme }) => theme.colors.slateBlue};
    font-size: 20px;
  }
  > .headerIcon {
    font-size: 18px;
    margin-left: 12px;
    margin-right: 12px;
    color: ${({ theme }) => theme.colors.slateBlue};
  }
`;

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default EmployeeAchievementsPage;
