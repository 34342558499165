import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import Toast from './Toast';

export type Toast = {
  id: number;
  className: string;
  delay: number;
  text: string;
};

const ToastList_: StyledFC = ({ className }) => {
  const { toastList } = useSelector<
    {
      toasts: {
        toastList: Toast[];
      };
    },
    { toastList: Toast[] }
  >((state) => ({
    toastList: state.toasts.toastList,
  }));

  return (
    <div className={`${className} toast-list container`}>
      <div className="row">
        <div className="col-sm-8 offset-sm-2 col-10 offset-1">
          {toastList.map((toast: Toast, index: number) => {
            return <Toast key={index} toast={toast} />;
          })}
        </div>
      </div>
    </div>
  );
};

const ToastList = styled(ToastList_)`
  position: absolute;
  top: auto;
  bottom: 40px;
  right: 0;
  left: 0;
  z-index: 1200;
  display: flex;
  justify-content: center;
  pointer-events: none;
`;

export default ToastList;
