import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from './types';

interface Props {
  imageSrc: string;
  assignmentImage?: string;
  isMemoriesPage?: boolean;
}

const ImageCell_: StyledFC<Props> = ({
  className,
  imageSrc,
  assignmentImage,
  isMemoriesPage,
}) => {
  return (
    <div className={className}>
      {assignmentImage && !isMemoriesPage && <div className="copy"> </div>}
      <img
        alt="main"
        src={isMemoriesPage ? assignmentImage : imageSrc}
        className="image"
      />
      {isMemoriesPage && (
        <img alt="alt" src={imageSrc} className="image miniImage" />
      )}
    </div>
  );
};

const ImageCell = styled(ImageCell_)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 48px !important;
  width: 48px !important;
  position: relative !important;
  > .copy {
    z-index: 2;
    position: absolute;
    width: 48px;
    height: 48px;
    transform: translate(4px, -4px) rotate(10deg);
    border-radius: 10px;
    background-color: var(--slate-blue);
  }
  > .image {
    z-index: 3;
    border-radius: 6px;
    object-fit: contain;
    height: 48px;
    width: 48px;
    &.miniImage {
      position: absolute;
      bottom: 2px;
      right: 2px;
      height: 24px;
      width: 24px;
    }
  }
`;

export default ImageCell;
