import { Dispatch } from 'redux';
import { ActionType } from '../../_constants';
import { logout } from '../auth-actions';

export const createRemoteAction = <Arg, Res>(
  requestType: ActionType,
  successType: ActionType,
  failureType: ActionType,
  serviceApi: (arg: Arg) => Promise<{ data: Res | null; errors: Error }>
) => (arg: Arg) => (
  dispatch: Dispatch
): Promise<{ data: Res | null; errors: Error }> => {
  const request = () => ({ type: requestType });
  const success = (data: Res) => ({
    type: successType,
    payload: data,
  });
  const failure = (error: Error) => ({
    type: failureType,
    payload: error,
  });
  dispatch(request());
  return new Promise(
    (
      resolve: (value: { data: Res | null; errors: Error }) => void,
      reject
    ): Promise<void> =>
      serviceApi(arg)
        .then(({ data, errors }) => {
          if (data) {
            dispatch(success(data));
          }
          if (errors) {
            dispatch(failure(errors));
          }
          resolve({ data, errors });
        })
        .catch((error) => {
          dispatch(failure(error));
          reject(error);
          if (failureType === ActionType.REFRESH_TOKEN_FAILURE) {
            dispatch(logout());
            console.error('refresh:', error);
          }
        })
  );
};
