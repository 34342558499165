import API from './api-service';

const importEmployees = (file: Blob) => {
  const formData = new FormData();
  formData.append('file', file);
  return API.post(`employee/import`, formData, {
    baseURL: process.env.REACT_APP_REST_API_URL,
  });
};
export const importsService = {
  importEmployees,
};
