import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDebounce } from '@react-hook/debounce';
import React, { useState } from 'react';
import {
  NavLink,
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { AssignmentOrderBy, OrderDir } from '../../../../../_graphql/schema';
import { dateToString } from '../../../../../_helpers/dateToString';
import { fileUrl } from '../../../../../_helpers/file';
import { theme } from '../../../../../_styled/theme';
import ChevronRightIcon from '../../../../icons/ChevronRightIcon';
import HighlightedText from '../../../../_shared/HighlightedText';
import ImageCell from '../../../../_shared/ImageCell';
import Paginator from '../../../../_shared/Paginator';
import QueryView from '../../../../_shared/QueryView';
import SearchBar from '../../../../_shared/SearchBar';
import SharedTable from '../../../../_shared/SharedTable';
import { HeadCell, StyledFC } from '../../../../_shared/types';
import GrantPlayerAchievementsPage from '../../MainPage/GrantPlayerAchievementsPage';
import AppHeader from '../../MainPage/_shared/AppHeader';
import Page from '../Page';
import AssignmentPage from './AssignmentPage';
import { useGetEmployeeAssignedListQuery } from './queries';

const searchBySource = [
  {
    label: 'Название',
    value: 'name',
  },
  {
    label: 'Вес',
    value: 'weight',
  },
  {
    label: 'Категория',
    value: 'category',
  },
  {
    label: 'ID',
    value: 'achievementId',
  },
];

const headCells: HeadCell<AssignmentOrderBy>[] = [
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Название',
    orderBy: AssignmentOrderBy.Name,
  },
  {
    id: 'weight',
    numeric: true,
    disablePadding: false,
    label: 'Вес',
    orderBy: AssignmentOrderBy.Weight,
  },
  {
    id: 'category',
    numeric: true,
    disablePadding: false,
    label: 'Категория',
    orderBy: AssignmentOrderBy.Category,
  },
  {
    id: 'createdAt',
    numeric: true,
    disablePadding: false,
    label: 'Дата получения',
    orderBy: AssignmentOrderBy.CreatedAt,
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'ID',
    orderBy: AssignmentOrderBy.Id,
  },
];

const EmployeeAchievementsPage_: StyledFC = ({ className }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    params: { playerId, projectId },
    path,
  } = useRouteMatch<{ playerId: string; projectId: string }>();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState({
    skip: 0,
    take: 10,
    searchField: 'name',
    orderBy: AssignmentOrderBy.Name as AssignmentOrderBy,
    orderDir: OrderDir.Asc,
  });

  const [highlightAchievement, setHighlightAchievement] = useState<{
    id: number;
  }>();

  const result = useGetEmployeeAssignedListQuery({
    variables: {
      employeeId: parseInt(playerId),
      ...(projectId ? { projectId: parseInt(projectId) } : {}),
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });
  /** Обновление списка для перехода со страницы выдачи */
  const isAssignments = location.pathname.endsWith('/assignments');
  if (isAssignments) result.refetch();

  const onAddAchievementClick = () => {
    console.log('onAddAchievementClick');
    history.push(`assignments/grant`);
  };
  const onAchievementRowClick = (id: number) => {
    history.push(`assignments/${id}`);
  };
  const onGrantPlayerAchievementsPageClosed = () => {
    console.log('onGrantPlayerAchievementsPageClosed');
    history.push(`${location.pathname.split('/').slice(0, -1).join('/')}`);
  };
  const onGrantPlayerAchievementsPageGranted = (highlightAchievementListIds: {
    id: number;
  }) => {
    setHighlightAchievement(highlightAchievementListIds);
    history.push(`${location.pathname.split('/').slice(0, -1).join('/')}`);
  };

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const onSearchFieldChange = (field: string) => {
    setSearchProps({
      ...searchProps,
      searchField: field,
    });
  };

  const onPageChange = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchProps({
      ...searchProps,
      skip: page * searchProps.take,
    });
  };

  const onRequestSort = (orderBy: AssignmentOrderBy, orderDir: OrderDir) => {
    setSearchProps({
      ...searchProps,
      orderBy: orderBy as AssignmentOrderBy,
      orderDir: orderDir as OrderDir,
    });
  };

  return (
    <QueryView
      result={result}
      renderData={(data) => {
        const Rows = (): JSX.Element[] => {
          const achievements = data.employee.assigned.items;
          const searchString = searchValue;
          const searchField = searchProps.searchField;

          return achievements.map((row, index) => {
            const labelId = `achievements-table-checkbox-${index}`;
            return (
              <TableRow
                className={
                  highlightAchievement?.id === row.achievement.id
                    ? 'highlighted'
                    : ''
                }
                hover
                tabIndex={-1}
                key={row.achievement.id}
                onClick={() => onAchievementRowClick(row.achievement.id)}
                style={{ height: 72 }}
              >
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  className="cell cellWidth"
                >
                  <ImageCell
                    imageSrc={fileUrl(row.achievement.image)}
                    assignmentImage={row.image ? row.image : undefined}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={row.achievement.name}
                    searchValue={searchString}
                    skip={searchField !== 'name'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={row.achievement.weight?.name}
                    searchValue={searchString}
                    skip={searchField !== 'weight'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={row.achievement.category?.name}
                    searchValue={searchString}
                    skip={searchField !== 'category'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={dateToString(new Date(row.createdAt), 'DD.MM.YYYY')}
                    searchValue={searchString}
                    skip={searchField !== 'createdAt'}
                  />
                </TableCell>
                <TableCell className="cell cellWidth">
                  <HighlightedText
                    text={String(row.achievement.id)}
                    searchValue={searchString}
                    skip={searchField !== 'id'}
                  />
                </TableCell>
              </TableRow>
            );
          });
        };

        const urlArray = location.pathname.split('/');
        const linkProfile = urlArray.slice(0, -1).join('/');
        const linkPlayers = urlArray.slice(0, -2).join('/');

        const headerTitle = (
          <div className={className}>
            <span
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <NavLink className="navLink" to={linkPlayers}>
                Игроки
              </NavLink>
              <ChevronRightIcon className="headerIcon" />
              <NavLink className="navLink" to={linkProfile}>
                Профиль игрока
              </NavLink>
              <ChevronRightIcon className="headerIcon" />
              Достижения
            </span>
          </div>
        );

        return (
          <>
            <Page
              routeEndsWith={`/players/:playerId(\\d+)/assignments`}
              header={<AppHeader title={headerTitle} />}
              content={
                <div className={className}>
                  <ButtonGroup
                    className="linkGroup"
                    variant="outlined"
                    color="primary"
                    aria-label="contained primary button group"
                  >
                    <Button
                      component={NavLink}
                      exact
                      to="assignments"
                      className="linkButton"
                      activeClassName="selected"
                    >
                      Достижения
                    </Button>
                    <Button
                      component={NavLink}
                      exact
                      to="memories"
                      className="linkButton"
                      activeClassName="selected"
                    >
                      Воспоминания
                    </Button>
                  </ButtonGroup>
                  <div className="buttonsWrapper">
                    <SearchBar
                      searchBySource={searchBySource}
                      placeholder={`Поиск достижений...`}
                      onSearchValueChange={onSearchValueChange}
                      onSearchFieldChange={onSearchFieldChange}
                    />
                    <div style={{ display: 'flex' }}>
                      <Button
                        className="addAchievementButton"
                        variant="contained"
                        color="primary"
                        disableElevation
                        type="submit"
                        onClick={onAddAchievementClick}
                      >
                        Выдать достижение
                      </Button>
                    </div>
                  </div>
                  <SharedTable<AssignmentOrderBy>
                    onRequestSort={onRequestSort}
                    rows={Rows()}
                    headCells={headCells}
                  />
                  <Paginator
                    count={data.employee.assigned?.total}
                    perPage={searchProps.take}
                    page={Math.ceil(searchProps.skip / searchProps.take)}
                    onChangePage={onPageChange}
                  />
                </div>
              }
            />
            <Switch>
              <Route
                path={`${path}/grant`}
                render={() => (
                  <PageContainer>
                    <GrantPlayerAchievementsPage
                      onClosed={onGrantPlayerAchievementsPageClosed}
                      onGranted={onGrantPlayerAchievementsPageGranted}
                    />
                  </PageContainer>
                )}
              />
              <Route
                path={`${path}/:achievementId(\\d+)`}
                render={() => (
                  <div className="pageContainer">
                    <AssignmentPage theme={theme} />
                  </div>
                )}
              />
            </Switch>
          </>
        );
      }}
    />
  );
};

const EmployeeAchievementsPage = styled(EmployeeAchievementsPage_)`
  > span > .navLink {
    color: ${({ theme }) => theme.colors.slateBlue};
  }
  > span > .headerIcon {
    font-size: 18px;
    margin-left: 12px;
    margin-right: 12px;
    color: ${({ theme }) => theme.colors.slateBlue};
  }
  > .linkGroup {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    margin-bottom: 25px;
    .linkButton {
      height: 36px;
      width: 160px;
      border-radius: 8px;
      border-width: 2px;
      border-color: ${({ theme }) => theme.colors.hint};
      font-size: 12px;
      :first-child {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
      :last-child {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
      &.selected {
        background-color: ${({ theme }) => theme.colors.hint};
      }
    }
  }
  > .buttonsWrapper {
    min-height: 56px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    //gap: 16px;
    margin-bottom: 8px;
    //grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    flex-wrap: wrap;
    .addAchievementButton {
      //align-self: end;
      height: 36px;
      padding: 0 24px 0 16px;
      border-radius: 18px;
      background-color: transparent;
      border: 2px solid #2b3757;
      margin-bottom: 16px;
      :hover {
        background-color: rgb(64, 76, 107) !important;
      }
    }
  }
  .cellWidth {
    &:before {
      position: absolute;
      bottom: -1px;
      right: 0;
      width: 100% !important;
      border-top: 1px solid ${({ theme }) => theme.colors.hint};
      content: ' ';
    }
    &:first-child {
      width: 10% !important;
      &:before {
        bottom: -1px;
      }
    }
    &:nth-child(2) {
      width: 25% !important;
    }
    &:nth-child(3) {
      width: 17% !important;
    }
    &:nth-child(4) {
      width: 25% !important;
    }
    &:nth-child(5) {
      width: 15% !important;
    }
    &:nth-child(6) {
      &:before {
        bottom: -1.5px;
      }
    }
  }
`;

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`;

export default EmployeeAchievementsPage;
