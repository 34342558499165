import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const SettingsIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M13.01 0c.727 0 1.344.521 1.464 1.238l.28 1.665a9.413 9.413 0 0 1 1.695.697l1.394-.993a1.472 1.472 0 0 1 1.905.16l1.494 1.495c.517.51.582 1.318.16 1.91L20.41 7.56c.276.522.502 1.068.677 1.635l1.685.286c.716.12 1.238.737 1.228 1.464v2.11c0 .727-.522 1.344-1.238 1.464l-1.685.281a9.444 9.444 0 0 1-.677 1.635l.993 1.393c.421.592.35 1.4-.16 1.91l-1.495 1.495c-.28.28-.651.436-1.047.436l-.155-.008a1.465 1.465 0 0 1-.708-.268L16.44 20.4a9.512 9.512 0 0 1-1.634.677l-.286 1.685A1.481 1.481 0 0 1 13.055 24H10.94a1.481 1.481 0 0 1-1.464-1.238l-.281-1.685a9.484 9.484 0 0 1-1.584-.652L6.2 21.428a1.472 1.472 0 0 1-1.905-.16l-1.493-1.494a1.478 1.478 0 0 1-.16-1.91l.987-1.374a9.278 9.278 0 0 1-.687-1.63l-1.705-.29A1.481 1.481 0 0 1 0 13.104v-2.11c0-.727.521-1.344 1.238-1.464l1.665-.28a9.29 9.29 0 0 1 .667-1.64L2.567 6.2a1.484 1.484 0 0 1 .16-1.91l1.5-1.493c.275-.281.651-.437 1.047-.437.311 0 .612.096.863.276l1.378.993a9.277 9.277 0 0 1 1.63-.687l.29-1.705A1.481 1.481 0 0 1 10.9 0zM12 6c-3.31 0-6 2.69-6 6s2.69 6 6 6 6-2.69 6-6-2.69-6-6-6zm0 3c1.655 0 3 1.345 3 3s-1.345 3-3 3-3-1.345-3-3 1.345-3 3-3z"
    />
  </SvgIcon>
);
export default SettingsIcon;
