import * as Types from '../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetPlayersQueryVariables = {
  id: Types.Scalars['Int'];
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  isActive?: Types.Maybe<Types.Scalars['Boolean']>;
  orderBy?: Types.Maybe<Types.EmployeeOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
  name?: Types.Maybe<Types.Scalars['String']>;
  rootSubdivisionName?: Types.Maybe<Types.Scalars['String']>;
  position?: Types.Maybe<Types.Scalars['String']>;
  playerId?: Types.Maybe<Types.Scalars['String']>;
};


export type GetPlayersQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { players: (
      { __typename?: 'EmployeePage' }
      & Pick<Types.EmployeePage, 'total'>
      & { items: Array<(
        { __typename?: 'Employee' }
        & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'rootSubdivisionName' | 'position' | 'isActive'>
      )> }
    ) }
  ) }
);

export type GetNotPlayersQueryVariables = {
  id: Types.Scalars['Int'];
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  isActive?: Types.Maybe<Types.Scalars['Boolean']>;
  orderBy?: Types.Maybe<Types.EmployeeOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
  name?: Types.Maybe<Types.Scalars['String']>;
  rootSubdivisionName?: Types.Maybe<Types.Scalars['String']>;
  position?: Types.Maybe<Types.Scalars['String']>;
  playerId?: Types.Maybe<Types.Scalars['String']>;
};


export type GetNotPlayersQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { notPlayers: (
      { __typename?: 'EmployeePage' }
      & Pick<Types.EmployeePage, 'total'>
      & { items: Array<(
        { __typename?: 'Employee' }
        & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'isActive' | 'position' | 'email' | 'rootSubdivisionName'>
      )> }
    ) }
  ) }
);

export type AddPlayersMutationVariables = {
  projectId: Types.Scalars['Int'];
  employeeIds: Array<Types.Scalars['Int']>;
};


export type AddPlayersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'addPlayers'>
);


export const GetPlayersDocument = gql`
    query getPlayers($id: Int!, $skip: Int, $take: Int, $isActive: Boolean, $orderBy: EmployeeOrderBy, $orderDir: OrderDir, $name: String, $rootSubdivisionName: String, $position: String, $playerId: String) {
  project(id: $id) {
    id
    players(skip: $skip, take: $take, isActive: $isActive, orderBy: $orderBy, orderDir: $orderDir, name: $name, rootSubdivisionName: $rootSubdivisionName, position: $position, id: $playerId) {
      items {
        id
        name
        avatar
        rootSubdivisionName
        position
        isActive
      }
      total
    }
  }
}
    `;

/**
 * __useGetPlayersQuery__
 *
 * To run a query within a React component, call `useGetPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlayersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      isActive: // value for 'isActive'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      name: // value for 'name'
 *      rootSubdivisionName: // value for 'rootSubdivisionName'
 *      position: // value for 'position'
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetPlayersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPlayersQuery, GetPlayersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPlayersQuery, GetPlayersQueryVariables>(GetPlayersDocument, baseOptions);
      }
export function useGetPlayersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPlayersQuery, GetPlayersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPlayersQuery, GetPlayersQueryVariables>(GetPlayersDocument, baseOptions);
        }
export type GetPlayersQueryHookResult = ReturnType<typeof useGetPlayersQuery>;
export type GetPlayersLazyQueryHookResult = ReturnType<typeof useGetPlayersLazyQuery>;
export type GetPlayersQueryResult = ApolloReactCommon.QueryResult<GetPlayersQuery, GetPlayersQueryVariables>;
export const GetNotPlayersDocument = gql`
    query getNotPlayers($id: Int!, $skip: Int, $take: Int, $isActive: Boolean, $orderBy: EmployeeOrderBy, $orderDir: OrderDir, $name: String, $rootSubdivisionName: String, $position: String, $playerId: String) {
  project(id: $id) {
    id
    notPlayers(skip: $skip, take: $take, isActive: $isActive, orderBy: $orderBy, orderDir: $orderDir, name: $name, rootSubdivisionName: $rootSubdivisionName, position: $position, id: $playerId) {
      items {
        id
        name
        avatar
        isActive
        position
        email
        rootSubdivisionName
      }
      total
    }
  }
}
    `;

/**
 * __useGetNotPlayersQuery__
 *
 * To run a query within a React component, call `useGetNotPlayersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotPlayersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotPlayersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      isActive: // value for 'isActive'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      name: // value for 'name'
 *      rootSubdivisionName: // value for 'rootSubdivisionName'
 *      position: // value for 'position'
 *      playerId: // value for 'playerId'
 *   },
 * });
 */
export function useGetNotPlayersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotPlayersQuery, GetNotPlayersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotPlayersQuery, GetNotPlayersQueryVariables>(GetNotPlayersDocument, baseOptions);
      }
export function useGetNotPlayersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotPlayersQuery, GetNotPlayersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotPlayersQuery, GetNotPlayersQueryVariables>(GetNotPlayersDocument, baseOptions);
        }
export type GetNotPlayersQueryHookResult = ReturnType<typeof useGetNotPlayersQuery>;
export type GetNotPlayersLazyQueryHookResult = ReturnType<typeof useGetNotPlayersLazyQuery>;
export type GetNotPlayersQueryResult = ApolloReactCommon.QueryResult<GetNotPlayersQuery, GetNotPlayersQueryVariables>;
export const AddPlayersDocument = gql`
    mutation addPlayers($projectId: Int!, $employeeIds: [Int!]!) {
  addPlayers(project: $projectId, employees: $employeeIds)
}
    `;

/**
 * __useAddPlayersMutation__
 *
 * To run a mutation, you first call `useAddPlayersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlayersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlayersMutation, { data, loading, error }] = useAddPlayersMutation({
 *   variables: {
 *      projectId: // value for 'projectId'
 *      employeeIds: // value for 'employeeIds'
 *   },
 * });
 */
export function useAddPlayersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddPlayersMutation, AddPlayersMutationVariables>) {
        return ApolloReactHooks.useMutation<AddPlayersMutation, AddPlayersMutationVariables>(AddPlayersDocument, baseOptions);
      }
export type AddPlayersMutationHookResult = ReturnType<typeof useAddPlayersMutation>;
export type AddPlayersMutationResult = ApolloReactCommon.MutationResult<AddPlayersMutation>;
export type AddPlayersMutationOptions = ApolloReactCommon.BaseMutationOptions<AddPlayersMutation, AddPlayersMutationVariables>;