import jwt from 'jsonwebtoken';
import { ActionType } from '../_constants';

const state = localStorage.getItem('auth');
const initialState = state
  ? JSON.parse(state)
  : {
      accessToken: null,
      refreshToken: null,
      loading: true,
      error: null,
    };

/** Поверка, есть ли в localStorage просроченный accessToken */
let accessToken: string;
let tokenExpiredDate: string | number | Date;
let tokenExpiredLimit: number;

const isTokenExpired = () => {
  const getDifferenceBetweenDates = (startDate: Date, endDate: Date) => {
    return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
  };
  const setTokenExpiredDate = (token: string) => {
    const expiredDate: any = jwt.decode(token);
    tokenExpiredDate = new Date(expiredDate?.exp);
    tokenExpiredLimit = getDifferenceBetweenDates(new Date(), tokenExpiredDate);
  };
  if (!accessToken) {
    accessToken = initialState.accessToken;
    setTokenExpiredDate(accessToken);
  }
  const timeLeft = getDifferenceBetweenDates(
    new Date(),
    new Date(tokenExpiredDate)
  );
  const limit = Math.floor(tokenExpiredLimit * 0.2);
  return timeLeft < limit;
};
//console.log('Устарел ли токен:', isTokenExpired());
if (isTokenExpired()) {
  window.localStorage.removeItem('auth');
  window.location.href = '/';
}
/** */

const auth = (
  state = initialState,
  action:
    | {
        type:
          | ActionType.LOGIN_REQUEST
          | ActionType.LOGIN_SUCCESS
          | ActionType.LOGIN_FAILURE
          | ActionType.REFRESH_TOKEN_REQUEST
          | ActionType.REFRESH_TOKEN_SUCCESS
          | ActionType.REFRESH_TOKEN_FAILURE;
        payload: { accessToken: string; refreshToken: string };
      }
    | { type: ActionType.LOGOUT }
) => {
  switch (action.type) {
    case ActionType.LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionType.LOGIN_SUCCESS:
      return {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        error: null,
      };

    case ActionType.LOGIN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionType.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };

    case ActionType.REFRESH_TOKEN_SUCCESS:
      return {
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
        loading: false,
        error: null,
      };

    case ActionType.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ActionType.LOGOUT:
      return {};

    default:
      return state;
  }
};
export default auth;
