import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ClearIcon from '@material-ui/icons/Clear';
import React, { cloneElement } from 'react';
import styled from 'styled-components/macro';
import { Subdivision } from '../../_graphql/schema';
import { StyledFC } from './types';

interface Props {
  buttonLabel?: string;
  component?: JSX.Element;
  list?: Array<{ id: number; name: string } | null>;
  onChange?: (value: ({ id: number; name: string } | null)[]) => void;
}

const FieldList_: StyledFC<Props> = ({
  className,
  buttonLabel = 'Добавить',
  component: Component = <input />,
  list = [],
  onChange = (value) => console.log('onChange', value),
}) => {
  const onAddClick = () => {
    onChange([...list, null]);
  };

  const onDeleteClick = (idx: number) => {
    onChange([...list.slice(0, idx), ...list.slice(idx + 1)]);
  };

  const onListItemChange = (value: Subdivision, idx: number) => {
    const arr = [...list];
    arr[idx] = value;
    onChange(arr);
  };

  return (
    <div className={className}>
      {list?.map((item, idx) => (
        <div
          className="field-list-item"
          key={idx}
          style={{
            position: 'relative',
            width: '100%',
          }}
        >
          {cloneElement(Component, {
            value: item,
            getOptionSelected: (option: { id: number }) =>
              option.id === item?.id,
            getOptionDisabled: (option: { id: number }) =>
              option.id === item?.id,
            onChange: (e: React.FormEvent, value: Subdivision) =>
              onListItemChange(value, idx),
          })}
          {
            <IconButton
              aria-label="delete"
              className="icon-button"
              size="small"
              onClick={(e) => {
                e.preventDefault();
                onDeleteClick(idx);
              }}
            >
              <ClearIcon fontSize="inherit" />
            </IconButton>
          }
        </div>
      ))}
      <Button
        fullWidth
        className="addButton"
        disabled={!list}
        onClick={onAddClick}
        startIcon={<AddCircleOutlineIcon fontSize="inherit" />}
        type={'button'}
      >
        {buttonLabel}
      </Button>
    </div>
  );
};

const FieldList = styled(FieldList_)`
  display: grid;
  gap: 16px;

  .icon-button {
    position: absolute;
    top: 50%;
    right: 0;

    color: ${({ theme }) => theme.colors.slateBlue};
    transform: translate(40px, -50%);
  }

  > .addButton {
    justify-content: left;
    height: auto;
    padding: 6px;

    color: ${({ theme }) => theme.colors.slateBlue};
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;

    &:disabled {
      opacity: 0.3;
    }

    & > * {
      color: inherit;
    }

    & .MuiButton-startIcon {
      margin-left: 0;
      margin-right: 12px;

      font-size: 24px;

      & > svg {
        font-size: 24px;
      }
    }
  }
`;

export default FieldList;
