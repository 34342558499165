import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { theme } from '../../../../_styled/theme';
import { StyledFC } from '../../../_shared/types';
import ProjectEdit from '../Projects/Edit';
import AdminRoute from '../_shared/AdminRoute';
import AppSidebar from './AppSidebar';
import AwardsPage from './AwardPage';
import ManagersPage from './ManagersPage';
import PlayersPage from './PlayersPage';

interface Props {
  location: { pathname: string };
  match: {
    params: { projectId: string };
    path: string;
    url: string;
  };
}

const MainPage_: StyledFC<Props> = ({ className, location, match }) => {
  return (
    <div className={className}>
      <CssBaseline />
      <Drawer
        className="drawer"
        variant="permanent"
        classes={{
          paper: 'drawerPaper',
        }}
        anchor="left"
      >
        <AppSidebar theme={theme} />
      </Drawer>
      <main className="content">
        <Switch>
          <Route
            path={`${match.path}`}
            exact
            render={() => <Redirect to={`${match.url}/awards`} />}
          />
          <Route path={`${match.path}/awards`} component={AwardsPage} />
          <Route path={`${match.path}/players`} component={PlayersPage} />

          <AdminRoute
            path={`${match.path}/managers`}
            component={ManagersPage}
          />
          <AdminRoute path={`${match.path}/settings`} component={ProjectEdit} />
          <Route
            render={() => (
              <Typography variant="h4">
                {' '}
                Страница {location.pathname} не найдена{' '}
              </Typography>
            )}
          />
        </Switch>
      </main>
    </div>
  );
};

const MainPage = styled(MainPage_)`
  display: grid;
  grid-template-areas: 'drawer content';
  grid-template-columns: 1fr 10fr;
  .MuiContainer-root {
    padding-top: 0;
  }

  @media (max-width: 897px) {
    grid-template-columns: 1fr 30fr;
    .drawer {
      max-width: 80px;
    }
    .drawerPaper {
      max-width: 80px;
    }
    .makeStyles-container-16 {
      padding: 0;
    }
  }
  .drawer {
    min-height: 100vh;
  }
  .drawerPaper {
    grid-area: drawer;
    border: none;
    position: sticky;
    height: 100vh;
    overflow: hidden;
  }
  > .content {
    grid-area: content;
    position: relative;
    height: 100%;
    margin: 0;
    padding: 0;
    scrollbar-width: none;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 0;
    }
  }
`;

export default MainPage;
