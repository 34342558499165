import { ActionType } from '../_constants';

const localStorageMiddleware = (/*store*/) => (
  next: (arg0: {
    type:
      | ActionType.LOGIN_SUCCESS
      | ActionType.REFRESH_TOKEN_SUCCESS
      | ActionType.LOGOUT;
    payload: { email: string; employee: number | null };
  }) => void
) => (action: {
  type:
    | ActionType.LOGIN_SUCCESS
    | ActionType.REFRESH_TOKEN_SUCCESS
    | ActionType.LOGOUT;
  payload: { email: string; employee: number | null };
}) => {
  if (
    [ActionType.LOGIN_SUCCESS, ActionType.REFRESH_TOKEN_SUCCESS].includes(
      action.type
    )
  ) {
    if (action.payload) {
      localStorage.setItem('auth', JSON.stringify(action.payload));
    }
  } else if ([ActionType.LOGOUT].includes(action.type)) {
    window.localStorage.removeItem('auth');
  }
  next(action);
};
export default localStorageMiddleware;
