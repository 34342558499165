import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import React, { useState } from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../_constants';
import ChevronDownIcon from '../icons/ChevronDownIcon';
import { SearchBySource, StyledFC } from './types';

interface Props {
  placeholder: string;
  minSelectWidth?: number;
  onFocusEnable?: boolean;
  searchBySource?: SearchBySource[];
  selectWidth?: number;
  type?: string;
  width?: number;
  onSearchValueChange: (str: string) => void;
  onSearchFieldChange: (str: string) => void;
}

const SearchBar_: StyledFC<Props> = ({
  className,
  placeholder,
  searchBySource,
  selectWidth = 160,
  onSearchValueChange,
  onSearchFieldChange,
}) => {
  const [searchString, setSearchString] = useState('');
  const [searchSource, setSearchSource] = useState('name');
  const [onFocus, setOnFocus] = useState(false);

  const clearBtnClickHandler = (e: React.MouseEvent) => {
    e.preventDefault();
    setSearchString('');
    onSearchValueChange('');
  };

  const onSearchFieldChangeHandler = (e: React.ChangeEvent<{ value: any }>) => {
    setSearchSource(e.target.value);
    onSearchFieldChange(e.target.value);
    console.log(e.target);
  };

  const onSearchValueChangeHandler = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const str = e.target.value;
    setSearchString(str);
    onSearchValueChange(str);
  };

  return (
    <div className={className}>
      <div
        className="searchGroup"
        /*style={{
          borderColor:
            !searchBySource && !onFocus ? 'transparent' : colors.slateBlue,
        }}*/
      >
        <InputBase
          className="searchInput"
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search google maps' }}
          value={searchString}
          onChange={onSearchValueChangeHandler}
          startAdornment={<SearchIcon className="searchIcon" />}
          onFocus={() => setOnFocus(!onFocus)}
          onBlur={() => setOnFocus(!onFocus)}
          endAdornment={
            searchString.length ? (
              <IconButton
                aria-label="delete"
                onClick={clearBtnClickHandler}
                size="small"
              >
                <ClearIcon color="inherit" fontSize="inherit" />
              </IconButton>
            ) : null
          }
        />
        {searchBySource ? (
          <>
            <Divider className="searchDivider" orientation="vertical" />
            <Select
              IconComponent={() => <ChevronDownIcon fontSize="inherit" />}
              disableUnderline
              className="searchSourceSelect"
              value={searchSource}
              style={{ width: selectWidth ? selectWidth + 'px' : '160px' }}
              onChange={onSearchFieldChangeHandler}
            >
              {searchBySource.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </Select>
          </>
        ) : null}
      </div>
    </div>
  );
};

const SearchBar = styled(SearchBar_)`
  > .searchGroup {
    height: 56px;
    min-width: 317px;
    border-radius: 15px;
    border-style: solid;
    border-width: 1px;
    border-color: ${colors.slateBlue};
    display: flex;
    align-items: center;
    width: 100%;
    margin-top: 16px;
    margin-bottom: 16px;
    .searchIcon {
      margin-right: 20px;
    }
    .searchInput {
      font-size: 20px;
      color: ${colors.slateBlue};
      flex-grow: 1;
      height: 100%;
      width: 100%;
      padding-left: 20px;
      padding-right: 20px;
      & > button {
        color: inherit;
      }
    }
    .searchDivider {
      background-color: ${colors.slateBlue};
    }
    .searchSourceSelect {
      font-size: 16px;
      font-weight: 600;
      color: ${colors.slateBlue};
      & > .MuiSelect-select {
        padding-left: 20px;
        padding-right: 44px;
      }
      & > svg {
        position: absolute;
        right: 20px;
      }
    }
  }
`;

export default SearchBar;
