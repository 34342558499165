import React, { FC } from 'react';
import { Input } from './';
import { InputCustomProps } from './types';

export const InputPassword: FC<InputCustomProps> = ({
  name,
  label = 'Пароль',
  ...rest
}) => (
  <Input
    autoComplete="current-password"
    label={label}
    name={name}
    type="password"
    {...rest}
  />
);
