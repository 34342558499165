import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  component: React.FunctionComponent<any>;
  path: string;
}

const AuthRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { isAuth } = useSelector<
    {
      auth: {
        accessToken: string;
      };
    },
    { isAuth: boolean }
  >((state) => ({
    isAuth: !!state.auth.accessToken,
  }));

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuth ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: '/auth', state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default AuthRoute;
