import React, { FC } from 'react';
import { colors } from '../../_constants';

interface Props {
  searchValue: string;
  skip: boolean;
  text: string;
}

const HighlightedText: FC<Props> = ({ searchValue, skip = false, text }) => {
  if (skip || searchValue === '') {
    return <>{text}</>;
  }

  const replace = `(\\${searchValue})`;
  const re = new RegExp(replace, 'gi');
  const parts = text.toString().split(re);

  return (
    <span>
      {' '}
      {parts.map((part, i) => (
        <span
          key={i}
          style={
            part.toLowerCase().includes(searchValue.toLowerCase())
              ? { backgroundColor: colors.slateBlue, borderRadius: 4 }
              : {}
          }
        >
          {part}
        </span>
      ))}{' '}
    </span>
  );
};

export default HighlightedText;
