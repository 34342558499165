import * as Types from '../../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type CategoryListFragment = (
  { __typename?: 'Category' }
  & Pick<Types.Category, 'id' | 'name'>
);

export type AchievementColorFragment = (
  { __typename?: 'AchievementColor' }
  & Pick<Types.AchievementColor, 'id' | 'name' | 'value'>
);

export type AchievementWeightFragment = (
  { __typename?: 'AchievementWeight' }
  & Pick<Types.AchievementWeight, 'id' | 'name'>
);

export type GetSelectListsQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetSelectListsQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { categories: (
      { __typename?: 'CategoryPage' }
      & { items: Array<(
        { __typename?: 'Category' }
        & CategoryListFragment
      )> }
    ) }
  ), achievementColors: Array<(
    { __typename?: 'AchievementColor' }
    & AchievementColorFragment
  )>, achievementWeights: Array<(
    { __typename?: 'AchievementWeight' }
    & AchievementWeightFragment
  )> }
);

export type BaseAchievementFieldsFragment = (
  { __typename?: 'Achievement' }
  & Pick<Types.Achievement, 'id' | 'animation' | 'description' | 'image' | 'isActive' | 'message' | 'name'>
  & { category: (
    { __typename?: 'Category' }
    & Pick<Types.Category, 'id' | 'name'>
  ), color: (
    { __typename?: 'AchievementColor' }
    & Pick<Types.AchievementColor, 'id' | 'name' | 'value'>
  ), weight: (
    { __typename?: 'AchievementWeight' }
    & Pick<Types.AchievementWeight, 'id' | 'name' | 'value'>
  ), assignments: (
    { __typename?: 'AssignmentPage' }
    & Pick<Types.AssignmentPage, 'total'>
  ) }
);

export type GetAchievementQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetAchievementQuery = (
  { __typename?: 'Query' }
  & { achievement: (
    { __typename?: 'Achievement' }
    & BaseAchievementFieldsFragment
  ) }
);

export type AchievementListFieldsFragment = (
  { __typename?: 'AchievementPage' }
  & Pick<Types.AchievementPage, 'total'>
  & { items: Array<(
    { __typename?: 'Achievement' }
    & Pick<Types.Achievement, 'id' | 'image' | 'name'>
    & { category: (
      { __typename?: 'Category' }
      & Pick<Types.Category, 'id' | 'name'>
    ), weight: (
      { __typename?: 'AchievementWeight' }
      & Pick<Types.AchievementWeight, 'id' | 'name'>
    ) }
  )> }
);

export type GetProjectAchievementsListQueryVariables = {
  id?: Types.Maybe<Types.Scalars['String']>;
  category?: Types.Maybe<Types.Scalars['String']>;
  name?: Types.Maybe<Types.Scalars['String']>;
  weight?: Types.Maybe<Types.Scalars['String']>;
  orderBy: Types.AchievementOrderBy;
  orderDir: Types.OrderDir;
  projectId: Types.Scalars['Int'];
  skip: Types.Scalars['Int'];
  take: Types.Scalars['Int'];
};


export type GetProjectAchievementsListQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { achievements: (
      { __typename?: 'AchievementPage' }
      & Pick<Types.AchievementPage, 'total'>
      & { items: Array<(
        { __typename?: 'Achievement' }
        & Pick<Types.Achievement, 'id' | 'image' | 'name'>
        & { category: (
          { __typename?: 'Category' }
          & Pick<Types.Category, 'id' | 'name'>
        ), weight: (
          { __typename?: 'AchievementWeight' }
          & Pick<Types.AchievementWeight, 'id' | 'name'>
        ) }
      )> }
    ) }
  ) }
);

export type GetValidEmployeesAchievementsListQueryVariables = {
  id: Types.Scalars['Int'];
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  isActive?: Types.Maybe<Types.Scalars['Boolean']>;
  orderBy?: Types.Maybe<Types.EmployeeOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
  name?: Types.Maybe<Types.Scalars['String']>;
  rootSubdivisionName?: Types.Maybe<Types.Scalars['String']>;
  position?: Types.Maybe<Types.Scalars['String']>;
};


export type GetValidEmployeesAchievementsListQuery = (
  { __typename?: 'Query' }
  & { achievement: (
    { __typename?: 'Achievement' }
    & Pick<Types.Achievement, 'id'>
    & { validEmployees: (
      { __typename?: 'EmployeePage' }
      & Pick<Types.EmployeePage, 'total'>
      & { items: Array<(
        { __typename?: 'Employee' }
        & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'isActive' | 'position' | 'rootSubdivisionName'>
      )> }
    ) }
  ) }
);

export type CreateAchievementMutationVariables = {
  animationBase64?: Types.Maybe<Types.Scalars['String']>;
  categoryId: Types.Scalars['Int'];
  colorId: Types.Scalars['Int'];
  description: Types.Scalars['String'];
  imageBase64: Types.Scalars['String'];
  isActive: Types.Scalars['Boolean'];
  message: Types.Scalars['String'];
  name: Types.Scalars['String'];
  weightId: Types.Scalars['Int'];
};


export type CreateAchievementMutation = (
  { __typename?: 'Mutation' }
  & { createAchievement: (
    { __typename?: 'Achievement' }
    & BaseAchievementFieldsFragment
  ) }
);

export type UpdateAchievementMutationVariables = {
  id: Types.Scalars['Int'];
  animationBase64?: Types.Maybe<Types.Scalars['String']>;
  categoryId: Types.Scalars['Int'];
  colorId: Types.Scalars['Int'];
  description: Types.Scalars['String'];
  imageBase64?: Types.Maybe<Types.Scalars['String']>;
  isActive: Types.Scalars['Boolean'];
  message: Types.Scalars['String'];
  name: Types.Scalars['String'];
  weightId: Types.Scalars['Int'];
};


export type UpdateAchievementMutation = (
  { __typename?: 'Mutation' }
  & { updateAchievement: (
    { __typename?: 'Achievement' }
    & BaseAchievementFieldsFragment
  ) }
);

export type DeleteAchievementMutationVariables = {
  id: Types.Scalars['Int'];
};


export type DeleteAchievementMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteAchievement'>
);

export const CategoryListFragmentDoc = gql`
    fragment categoryList on Category {
  id
  name
}
    `;
export const AchievementColorFragmentDoc = gql`
    fragment achievementColor on AchievementColor {
  id
  name
  value
}
    `;
export const AchievementWeightFragmentDoc = gql`
    fragment achievementWeight on AchievementWeight {
  id
  name
}
    `;
export const BaseAchievementFieldsFragmentDoc = gql`
    fragment baseAchievementFields on Achievement {
  id
  animation
  description
  image
  isActive
  message
  name
  category {
    id
    name
  }
  color {
    id
    name
    value
  }
  weight {
    id
    name
    value
  }
  assignments {
    total
  }
}
    `;
export const AchievementListFieldsFragmentDoc = gql`
    fragment achievementListFields on AchievementPage {
  items {
    id
    image
    name
    category {
      id
      name
    }
    weight {
      id
      name
    }
  }
  total
}
    `;
export const GetSelectListsDocument = gql`
    query getSelectLists($id: Int!) {
  project(id: $id) {
    id
    categories {
      items {
        ...categoryList
      }
    }
  }
  achievementColors {
    ...achievementColor
  }
  achievementWeights {
    ...achievementWeight
  }
}
    ${CategoryListFragmentDoc}
${AchievementColorFragmentDoc}
${AchievementWeightFragmentDoc}`;

/**
 * __useGetSelectListsQuery__
 *
 * To run a query within a React component, call `useGetSelectListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSelectListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSelectListsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSelectListsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSelectListsQuery, GetSelectListsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSelectListsQuery, GetSelectListsQueryVariables>(GetSelectListsDocument, baseOptions);
      }
export function useGetSelectListsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSelectListsQuery, GetSelectListsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSelectListsQuery, GetSelectListsQueryVariables>(GetSelectListsDocument, baseOptions);
        }
export type GetSelectListsQueryHookResult = ReturnType<typeof useGetSelectListsQuery>;
export type GetSelectListsLazyQueryHookResult = ReturnType<typeof useGetSelectListsLazyQuery>;
export type GetSelectListsQueryResult = ApolloReactCommon.QueryResult<GetSelectListsQuery, GetSelectListsQueryVariables>;
export const GetAchievementDocument = gql`
    query getAchievement($id: Int!) {
  achievement(id: $id) {
    ...baseAchievementFields
  }
}
    ${BaseAchievementFieldsFragmentDoc}`;

/**
 * __useGetAchievementQuery__
 *
 * To run a query within a React component, call `useGetAchievementQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAchievementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAchievementQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAchievementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAchievementQuery, GetAchievementQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAchievementQuery, GetAchievementQueryVariables>(GetAchievementDocument, baseOptions);
      }
export function useGetAchievementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAchievementQuery, GetAchievementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAchievementQuery, GetAchievementQueryVariables>(GetAchievementDocument, baseOptions);
        }
export type GetAchievementQueryHookResult = ReturnType<typeof useGetAchievementQuery>;
export type GetAchievementLazyQueryHookResult = ReturnType<typeof useGetAchievementLazyQuery>;
export type GetAchievementQueryResult = ApolloReactCommon.QueryResult<GetAchievementQuery, GetAchievementQueryVariables>;
export const GetProjectAchievementsListDocument = gql`
    query getProjectAchievementsList($id: String, $category: String, $name: String, $weight: String, $orderBy: AchievementOrderBy!, $orderDir: OrderDir!, $projectId: Int!, $skip: Int!, $take: Int!) {
  project(id: $projectId) {
    id
    achievements(id: $id, category: $category, name: $name, weight: $weight, orderBy: $orderBy, orderDir: $orderDir, skip: $skip, take: $take) {
      items {
        id
        image
        name
        category {
          id
          name
        }
        weight {
          id
          name
        }
      }
      total
    }
  }
}
    `;

/**
 * __useGetProjectAchievementsListQuery__
 *
 * To run a query within a React component, call `useGetProjectAchievementsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAchievementsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAchievementsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      category: // value for 'category'
 *      name: // value for 'name'
 *      weight: // value for 'weight'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      projectId: // value for 'projectId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetProjectAchievementsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectAchievementsListQuery, GetProjectAchievementsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectAchievementsListQuery, GetProjectAchievementsListQueryVariables>(GetProjectAchievementsListDocument, baseOptions);
      }
export function useGetProjectAchievementsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectAchievementsListQuery, GetProjectAchievementsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectAchievementsListQuery, GetProjectAchievementsListQueryVariables>(GetProjectAchievementsListDocument, baseOptions);
        }
export type GetProjectAchievementsListQueryHookResult = ReturnType<typeof useGetProjectAchievementsListQuery>;
export type GetProjectAchievementsListLazyQueryHookResult = ReturnType<typeof useGetProjectAchievementsListLazyQuery>;
export type GetProjectAchievementsListQueryResult = ApolloReactCommon.QueryResult<GetProjectAchievementsListQuery, GetProjectAchievementsListQueryVariables>;
export const GetValidEmployeesAchievementsListDocument = gql`
    query getValidEmployeesAchievementsList($id: Int!, $skip: Int, $take: Int, $isActive: Boolean, $orderBy: EmployeeOrderBy, $orderDir: OrderDir, $name: String, $rootSubdivisionName: String, $position: String) {
  achievement(id: $id) {
    id
    validEmployees(skip: $skip, take: $take, isActive: $isActive, orderBy: $orderBy, orderDir: $orderDir, name: $name, rootSubdivisionName: $rootSubdivisionName, position: $position) {
      items {
        id
        name
        avatar
        isActive
        position
        rootSubdivisionName
      }
      total
    }
  }
}
    `;

/**
 * __useGetValidEmployeesAchievementsListQuery__
 *
 * To run a query within a React component, call `useGetValidEmployeesAchievementsListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetValidEmployeesAchievementsListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetValidEmployeesAchievementsListQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      isActive: // value for 'isActive'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      name: // value for 'name'
 *      rootSubdivisionName: // value for 'rootSubdivisionName'
 *      position: // value for 'position'
 *   },
 * });
 */
export function useGetValidEmployeesAchievementsListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetValidEmployeesAchievementsListQuery, GetValidEmployeesAchievementsListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetValidEmployeesAchievementsListQuery, GetValidEmployeesAchievementsListQueryVariables>(GetValidEmployeesAchievementsListDocument, baseOptions);
      }
export function useGetValidEmployeesAchievementsListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetValidEmployeesAchievementsListQuery, GetValidEmployeesAchievementsListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetValidEmployeesAchievementsListQuery, GetValidEmployeesAchievementsListQueryVariables>(GetValidEmployeesAchievementsListDocument, baseOptions);
        }
export type GetValidEmployeesAchievementsListQueryHookResult = ReturnType<typeof useGetValidEmployeesAchievementsListQuery>;
export type GetValidEmployeesAchievementsListLazyQueryHookResult = ReturnType<typeof useGetValidEmployeesAchievementsListLazyQuery>;
export type GetValidEmployeesAchievementsListQueryResult = ApolloReactCommon.QueryResult<GetValidEmployeesAchievementsListQuery, GetValidEmployeesAchievementsListQueryVariables>;
export const CreateAchievementDocument = gql`
    mutation createAchievement($animationBase64: String, $categoryId: Int!, $colorId: Int!, $description: String!, $imageBase64: String!, $isActive: Boolean!, $message: String!, $name: String!, $weightId: Int!) {
  createAchievement(animation: $animationBase64, category: $categoryId, color: $colorId, description: $description, image: $imageBase64, isActive: $isActive, message: $message, name: $name, weight: $weightId) {
    ...baseAchievementFields
  }
}
    ${BaseAchievementFieldsFragmentDoc}`;

/**
 * __useCreateAchievementMutation__
 *
 * To run a mutation, you first call `useCreateAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAchievementMutation, { data, loading, error }] = useCreateAchievementMutation({
 *   variables: {
 *      animationBase64: // value for 'animationBase64'
 *      categoryId: // value for 'categoryId'
 *      colorId: // value for 'colorId'
 *      description: // value for 'description'
 *      imageBase64: // value for 'imageBase64'
 *      isActive: // value for 'isActive'
 *      message: // value for 'message'
 *      name: // value for 'name'
 *      weightId: // value for 'weightId'
 *   },
 * });
 */
export function useCreateAchievementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAchievementMutation, CreateAchievementMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAchievementMutation, CreateAchievementMutationVariables>(CreateAchievementDocument, baseOptions);
      }
export type CreateAchievementMutationHookResult = ReturnType<typeof useCreateAchievementMutation>;
export type CreateAchievementMutationResult = ApolloReactCommon.MutationResult<CreateAchievementMutation>;
export type CreateAchievementMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAchievementMutation, CreateAchievementMutationVariables>;
export const UpdateAchievementDocument = gql`
    mutation updateAchievement($id: Int!, $animationBase64: String, $categoryId: Int!, $colorId: Int!, $description: String!, $imageBase64: String, $isActive: Boolean!, $message: String!, $name: String!, $weightId: Int!) {
  updateAchievement(id: $id, animation: $animationBase64, category: $categoryId, color: $colorId, description: $description, image: $imageBase64, isActive: $isActive, message: $message, name: $name, weight: $weightId) {
    ...baseAchievementFields
  }
}
    ${BaseAchievementFieldsFragmentDoc}`;

/**
 * __useUpdateAchievementMutation__
 *
 * To run a mutation, you first call `useUpdateAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAchievementMutation, { data, loading, error }] = useUpdateAchievementMutation({
 *   variables: {
 *      id: // value for 'id'
 *      animationBase64: // value for 'animationBase64'
 *      categoryId: // value for 'categoryId'
 *      colorId: // value for 'colorId'
 *      description: // value for 'description'
 *      imageBase64: // value for 'imageBase64'
 *      isActive: // value for 'isActive'
 *      message: // value for 'message'
 *      name: // value for 'name'
 *      weightId: // value for 'weightId'
 *   },
 * });
 */
export function useUpdateAchievementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAchievementMutation, UpdateAchievementMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAchievementMutation, UpdateAchievementMutationVariables>(UpdateAchievementDocument, baseOptions);
      }
export type UpdateAchievementMutationHookResult = ReturnType<typeof useUpdateAchievementMutation>;
export type UpdateAchievementMutationResult = ApolloReactCommon.MutationResult<UpdateAchievementMutation>;
export type UpdateAchievementMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAchievementMutation, UpdateAchievementMutationVariables>;
export const DeleteAchievementDocument = gql`
    mutation deleteAchievement($id: Int!) {
  deleteAchievement(id: $id)
}
    `;

/**
 * __useDeleteAchievementMutation__
 *
 * To run a mutation, you first call `useDeleteAchievementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAchievementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAchievementMutation, { data, loading, error }] = useDeleteAchievementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAchievementMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAchievementMutation, DeleteAchievementMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAchievementMutation, DeleteAchievementMutationVariables>(DeleteAchievementDocument, baseOptions);
      }
export type DeleteAchievementMutationHookResult = ReturnType<typeof useDeleteAchievementMutation>;
export type DeleteAchievementMutationResult = ApolloReactCommon.MutationResult<DeleteAchievementMutation>;
export type DeleteAchievementMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAchievementMutation, DeleteAchievementMutationVariables>;