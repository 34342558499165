import React from 'react';
import { useSelector } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { StyledFC } from '../../_shared/types';
import RestoreForm from './RestoreForm';
import SignInForm from './SignInForm';

const AuthPage_: StyledFC = ({ className }) => {
  const history = useHistory();
  const { path, url } = useRouteMatch();
  const { isAuth } = useSelector<
    {
      auth: {
        accessToken: string;
      };
    },
    { isAuth: boolean }
  >((state) => ({
    isAuth: !!state.auth.accessToken,
  }));

  const handleCancel = () => {
    history.push('signin');
  };

  const handleRestore = () => {
    history.push('signin');
  };

  if (isAuth) {
    return <Redirect to={'/'} />;
  }

  return (
    <div className={className}>
      <div className="card-wrapper">
        <img className="logo" src="/assets/images/logo.svg" alt="logo" />
        <div className="card">
          <Switch>
            <Route
              path={`${url}`}
              exact
              render={() => <Redirect to={`${path}/signin`} />}
            />
            <Route path={`${url}/signin`} exact component={SignInForm} />
            <Route
              path={`${url}/restore`}
              exact
              render={() => (
                <RestoreForm
                  onCancel={handleCancel}
                  onRestore={handleRestore}
                />
              )}
            />
            <Route render={() => <Redirect to={`${path}/signin`} />} />
          </Switch>
        </div>
      </div>
    </div>
  );
};

const AuthPage = styled(AuthPage_)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;

  background-color: var(--dark-background);

  > .card-wrapper {
    display: flex;
    flex-direction: column;

    .logo {
      width: 85px;
      height: 98px;
      margin: 0 auto 36px;
    }

    .card {
      width: 464px;
      height: 380px;
      padding: 56px 56px 60px;

      border-radius: 15px;
      background-color: var(--card);
    }
  }
`;

export default AuthPage;
