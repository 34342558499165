import { Typography } from '@material-ui/core';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import {
  Checkbox,
  InputFile,
  Input,
  InputReadonly,
  SelectSubdivision,
  Textarea,
} from '../../../../_shared/fields';
import FormTemplate, { FormDecor } from '../../../../_shared/FormTemplate';
import QueryView from '../../../../_shared/QueryView';
import { useGetSubdivisionListQuery } from './queries';
import { Field, Fields, ProjectDelete } from './types';

interface Props {
  onDelete?: ProjectDelete;
}

const Form: FC<Props> = ({ onDelete }) => {
  const formikContext = useFormikContext<Fields>();

  const subdivisionListQueryResult = useGetSubdivisionListQuery({
    variables: {
      exclude: [],
      name: '',
      skip: 0,
      take: 50,
    },
  });

  return (
    <QueryView
      result={subdivisionListQueryResult}
      renderData={(data) => (
        <FormTemplate
          fieldsRender={[
            {
              title: `Основная информация`,
              render: () => (
                <>
                  <Input name={Field.name} label="Название" />
                  {formikContext.values.id ? (
                    <InputReadonly name={Field.id} label="ID" />
                  ) : null}
                  <Textarea name={Field.description} label="Краткое описание" />
                </>
              ),
            },
            {
              title: `Подразделения`,
              render: () => (
                <>
                  <SelectSubdivision
                    name={Field.subdivisions}
                    optionList={data.subdivisions.items}
                  />
                  <Typography className="hint">
                    Добавьте в проект подразделение и всех его сотрудников.
                    После создания проекта вы сможете добавлять в него отдельных
                    пользователей.
                  </Typography>
                </>
              ),
            },
            {
              title: `Изображение`,
              render: () => (
                <InputFile
                  file={Field.imageBase64}
                  src={Field.imageSrc}
                  mode="image"
                />
              ),
            },
            FormDecor.divider,
            {
              title: `Доступ`,
              render: () => (
                <Checkbox name={Field.isActive} label="Проект активен" />
              ),
            },
            {
              show: !!onDelete,
              render: () => (
                <button
                  className="button-reset deleteButton"
                  type="button"
                  onClick={onDelete}
                >
                  Удалить проект
                </button>
              ),
            },
          ]}
        />
      )}
    />
  );
};

export default Form;
