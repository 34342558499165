import * as Yup from 'yup';
import { ValidationError } from '../../../../../_constants';
import { Field, Fields } from './types';

export default Yup.object<Partial<Fields>>({
  [Field.description]: Yup.string().required(ValidationError.REQUIRED),
  [Field.imageSrc]: Yup.string().required(ValidationError.REQUIRED),
  [Field.name]: Yup.string()
    .min(3, `Название должно содержать минимум 3 символа`)
    .required(ValidationError.REQUIRED),
});
