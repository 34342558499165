import { combineReducers } from 'redux';
import auth from './auth-reducer';
import dialogs from './dialogs-reducer';
import toasts from './toasts-reducer';
import user from './user-reducer';

const reducer = combineReducers({
  auth,
  toasts,
  dialogs,
  user,
});
export default reducer;
