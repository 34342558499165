import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { toastsActions } from '../../../_actions';
import { StyledFC } from '../../_shared/types';

interface Props {
  toast: { id: number; delay: number; className: string; text: string };
}

const Toast_: StyledFC<Props> = ({ className, toast }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const timeout = setTimeout(
      () => dispatch(toastsActions.removeToast(toast.id)),
      toast.delay
    );
    return () => {
      clearTimeout(timeout);
    };
  });

  return (
    <div className={className}>
      <div className={`show ${toast.className ? toast.className : ''}`}>
        <div className="toast-body">
          <div>{toast.text}</div>
        </div>
      </div>
    </div>
  );
};

const Toast = styled(Toast_)`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-height: 60px;
  padding: 16px 32px;
  object-fit: contain;
  border-radius: 15px;
  background-color: var(--slate-blue);
  pointer-events: all;

  .toast-body {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    color: var(--white);
  }

  & ~ .app-toast {
    margin-top: 8px;
  }
`;

export default Toast;
