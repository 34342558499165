import { saveAs } from 'file-saver';
import API from './api-service';

const exportAssignments = (projectId: number) => {
  return API.get(`project/${projectId}/assignment/export`, {
    baseURL: process.env.REACT_APP_REST_API_URL,
    responseType: 'blob',
  }).then(({ data }) => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const fileName = `assignments.csv`;
    saveAs(blob, fileName);
  });
};

const exportAchievements = (projectId: number) => {
  return API.get(`project/${projectId}/achievement/export`, {
    baseURL: process.env.REACT_APP_REST_API_URL,
    responseType: 'blob',
  }).then(({ data }) => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const fileName = `achievements.csv`;
    saveAs(blob, fileName);
  });
};

const exportAchievementsImages = (projectId: number) => {
  return API.get(`project/${projectId}/achievement/image/export`, {
    baseURL: process.env.REACT_APP_REST_API_URL,
    responseType: 'blob',
  }).then(({ data }) => {
    const blob = new Blob([data], { type: 'application/octet-stream' });
    const fileName = `achievements-images-${new Date().toString()}.zip`;
    saveAs(blob, fileName);
  });
};

export const exportsService = {
  exportAssignments,
  exportAchievements,
  exportAchievementsImages,
};
