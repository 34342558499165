export const colors = {
  lightGreyBlue: '#aeb3be',
  slateBlue: '#5c6d99',
  hint: '#36425d',
  white: '#ffffff',
  sideBarSelected: '#3b6cb9',
  darkBackground: '#171f2f',
  darkishPink: '#d74471',
  inactiveButton: '#181c2e',
  darkBlueGrey: '#142342',
  card: '#242e45',
  blueGrey: '#7b87a3',
  green: '#19c4ac',
};
