import * as Types from '../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetSubdivisionListQueryVariables = {
  skip: Types.Scalars['Int'];
  take: Types.Scalars['Int'];
  name?: Types.Maybe<Types.Scalars['String']>;
  exclude: Array<Types.Scalars['Int']>;
};


export type GetSubdivisionListQuery = (
  { __typename?: 'Query' }
  & { subdivisions: (
    { __typename?: 'SubdivisionPage' }
    & Pick<Types.SubdivisionPage, 'total'>
    & { items: Array<(
      { __typename?: 'Subdivision' }
      & Pick<Types.Subdivision, 'id' | 'name'>
    )> }
  ) }
);

export type ProjectBaseFieldsFragment = (
  { __typename?: 'Project' }
  & Pick<Types.Project, 'id' | 'name' | 'description' | 'isActive' | 'image'>
  & { subdivisions: (
    { __typename?: 'SubdivisionPage' }
    & Pick<Types.SubdivisionPage, 'total'>
    & { items: Array<(
      { __typename?: 'Subdivision' }
      & Pick<Types.Subdivision, 'id' | 'name'>
    )> }
  ), managers: (
    { __typename?: 'EmployeePage' }
    & Pick<Types.EmployeePage, 'total'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Types.Employee, 'id' | 'name' | 'avatar'>
    )> }
  ), categories: (
    { __typename?: 'CategoryPage' }
    & Pick<Types.CategoryPage, 'total'>
    & { items: Array<(
      { __typename?: 'Category' }
      & Pick<Types.Category, 'id' | 'name'>
    )> }
  ) }
);

export type GetProjectQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetProjectQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & ProjectBaseFieldsFragment
  ) }
);

export type GetProjectListQueryVariables = {
  skip: Types.Scalars['Int'];
  take: Types.Scalars['Int'];
};


export type GetProjectListQuery = (
  { __typename?: 'Query' }
  & { projects: (
    { __typename?: 'ProjectPage' }
    & Pick<Types.ProjectPage, 'total'>
    & { items: Array<(
      { __typename?: 'Project' }
      & ProjectBaseFieldsFragment
    )> }
  ) }
);

export type CreateProjectMutationVariables = {
  name: Types.Scalars['String'];
  description?: Types.Maybe<Types.Scalars['String']>;
  isActive: Types.Scalars['Boolean'];
  subdivisionIds: Array<Types.Scalars['Int']>;
  imageBase64?: Types.Maybe<Types.Scalars['String']>;
};


export type CreateProjectMutation = (
  { __typename?: 'Mutation' }
  & { createProject: (
    { __typename?: 'Project' }
    & ProjectBaseFieldsFragment
  ) }
);

export type UpdateProjectMutationVariables = {
  id: Types.Scalars['Int'];
  name: Types.Scalars['String'];
  description?: Types.Maybe<Types.Scalars['String']>;
  isActive: Types.Scalars['Boolean'];
  subdivisionIds: Array<Types.Scalars['Int']>;
  imageBase64?: Types.Maybe<Types.Scalars['String']>;
};


export type UpdateProjectMutation = (
  { __typename?: 'Mutation' }
  & { updateProject: (
    { __typename?: 'Project' }
    & ProjectBaseFieldsFragment
  ) }
);

export type DeleteProjectMutationVariables = {
  id: Types.Scalars['Int'];
};


export type DeleteProjectMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteProject'>
);

export const ProjectBaseFieldsFragmentDoc = gql`
    fragment ProjectBaseFields on Project {
  id
  name
  description
  isActive
  image
  subdivisions {
    items {
      id
      name
    }
    total
  }
  managers {
    items {
      id
      name
      avatar
    }
    total
  }
  categories {
    items {
      id
      name
    }
    total
  }
}
    `;
export const GetSubdivisionListDocument = gql`
    query getSubdivisionList($skip: Int!, $take: Int!, $name: String, $exclude: [Int!]!) {
  subdivisions(name: $name, skip: $skip, take: $take, exclude: $exclude) {
    items {
      id
      name
    }
    total
  }
}
    `;

/**
 * __useGetSubdivisionListQuery__
 *
 * To run a query within a React component, call `useGetSubdivisionListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSubdivisionListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSubdivisionListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      exclude: // value for 'exclude'
 *   },
 * });
 */
export function useGetSubdivisionListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSubdivisionListQuery, GetSubdivisionListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetSubdivisionListQuery, GetSubdivisionListQueryVariables>(GetSubdivisionListDocument, baseOptions);
      }
export function useGetSubdivisionListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSubdivisionListQuery, GetSubdivisionListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetSubdivisionListQuery, GetSubdivisionListQueryVariables>(GetSubdivisionListDocument, baseOptions);
        }
export type GetSubdivisionListQueryHookResult = ReturnType<typeof useGetSubdivisionListQuery>;
export type GetSubdivisionListLazyQueryHookResult = ReturnType<typeof useGetSubdivisionListLazyQuery>;
export type GetSubdivisionListQueryResult = ApolloReactCommon.QueryResult<GetSubdivisionListQuery, GetSubdivisionListQueryVariables>;
export const GetProjectDocument = gql`
    query getProject($id: Int!) {
  project(id: $id) {
    ...ProjectBaseFields
  }
}
    ${ProjectBaseFieldsFragmentDoc}`;

/**
 * __useGetProjectQuery__
 *
 * To run a query within a React component, call `useGetProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
      }
export function useGetProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectQuery, GetProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectQuery, GetProjectQueryVariables>(GetProjectDocument, baseOptions);
        }
export type GetProjectQueryHookResult = ReturnType<typeof useGetProjectQuery>;
export type GetProjectLazyQueryHookResult = ReturnType<typeof useGetProjectLazyQuery>;
export type GetProjectQueryResult = ApolloReactCommon.QueryResult<GetProjectQuery, GetProjectQueryVariables>;
export const GetProjectListDocument = gql`
    query getProjectList($skip: Int!, $take: Int!) {
  projects(skip: $skip, take: $take) {
    items {
      ...ProjectBaseFields
    }
    total
  }
}
    ${ProjectBaseFieldsFragmentDoc}`;

/**
 * __useGetProjectListQuery__
 *
 * To run a query within a React component, call `useGetProjectListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetProjectListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectListQuery, GetProjectListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectListQuery, GetProjectListQueryVariables>(GetProjectListDocument, baseOptions);
      }
export function useGetProjectListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectListQuery, GetProjectListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectListQuery, GetProjectListQueryVariables>(GetProjectListDocument, baseOptions);
        }
export type GetProjectListQueryHookResult = ReturnType<typeof useGetProjectListQuery>;
export type GetProjectListLazyQueryHookResult = ReturnType<typeof useGetProjectListLazyQuery>;
export type GetProjectListQueryResult = ApolloReactCommon.QueryResult<GetProjectListQuery, GetProjectListQueryVariables>;
export const CreateProjectDocument = gql`
    mutation createProject($name: String!, $description: String, $isActive: Boolean!, $subdivisionIds: [Int!]!, $imageBase64: String) {
  createProject(name: $name, description: $description, isActive: $isActive, subdivisions: $subdivisionIds, image: $imageBase64) {
    ...ProjectBaseFields
  }
}
    ${ProjectBaseFieldsFragmentDoc}`;

/**
 * __useCreateProjectMutation__
 *
 * To run a mutation, you first call `useCreateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMutation, { data, loading, error }] = useCreateProjectMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isActive: // value for 'isActive'
 *      subdivisionIds: // value for 'subdivisionIds'
 *      imageBase64: // value for 'imageBase64'
 *   },
 * });
 */
export function useCreateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMutation, CreateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMutation, CreateProjectMutationVariables>(CreateProjectDocument, baseOptions);
      }
export type CreateProjectMutationHookResult = ReturnType<typeof useCreateProjectMutation>;
export type CreateProjectMutationResult = ApolloReactCommon.MutationResult<CreateProjectMutation>;
export type CreateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMutation, CreateProjectMutationVariables>;
export const UpdateProjectDocument = gql`
    mutation updateProject($id: Int!, $name: String!, $description: String, $isActive: Boolean!, $subdivisionIds: [Int!]!, $imageBase64: String) {
  updateProject(id: $id, name: $name, description: $description, isActive: $isActive, subdivisions: $subdivisionIds, image: $imageBase64) {
    ...ProjectBaseFields
  }
}
    ${ProjectBaseFieldsFragmentDoc}`;

/**
 * __useUpdateProjectMutation__
 *
 * To run a mutation, you first call `useUpdateProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectMutation, { data, loading, error }] = useUpdateProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      isActive: // value for 'isActive'
 *      subdivisionIds: // value for 'subdivisionIds'
 *      imageBase64: // value for 'imageBase64'
 *   },
 * });
 */
export function useUpdateProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectMutation, UpdateProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectMutation, UpdateProjectMutationVariables>(UpdateProjectDocument, baseOptions);
      }
export type UpdateProjectMutationHookResult = ReturnType<typeof useUpdateProjectMutation>;
export type UpdateProjectMutationResult = ApolloReactCommon.MutationResult<UpdateProjectMutation>;
export type UpdateProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectMutation, UpdateProjectMutationVariables>;
export const DeleteProjectDocument = gql`
    mutation deleteProject($id: Int!) {
  deleteProject(id: $id)
}
    `;

/**
 * __useDeleteProjectMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMutation, { data, loading, error }] = useDeleteProjectMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMutation, DeleteProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMutation, DeleteProjectMutationVariables>(DeleteProjectDocument, baseOptions);
      }
export type DeleteProjectMutationHookResult = ReturnType<typeof useDeleteProjectMutation>;
export type DeleteProjectMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMutation>;
export type DeleteProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMutation, DeleteProjectMutationVariables>;