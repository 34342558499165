import { Card } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar/AppBar';
import Button from '@material-ui/core/Button';
import CardActionArea from '@material-ui/core/CardActionArea';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dialogsActions } from '../../../../_actions';
import { ActionType } from '../../../../_constants';
import Paginator from '../../../_shared/Paginator';
import QueryView from '../../../_shared/QueryView';
import { StyledFC } from '../../../_shared/types';
import UserDropdownMenu from '../_shared/UserDropdownMenu';
import ProjectCard from './Card';
import {
  useDeleteProjectMutation,
  useGetProjectListQuery,
} from './_shared/queries';

const Projects_: StyledFC = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isAdmin } = useSelector<
    {
      user: {
        employee: number;
      };
    },
    { isAdmin: boolean }
  >((state) => ({
    isAdmin: !state.user.employee,
  }));
  const [deleteProjectMutation] = useDeleteProjectMutation();

  const [searchProps, setSearchProps] = useState({
    skip: 0,
    take: 10,
  });

  const getProjectListQuery = useGetProjectListQuery({
    variables: {
      skip: searchProps.skip,
      take: searchProps.take,
    },
  });
  /*console.log(getProjectListQuery.error?.message);

  const projectError = getProjectListQuery.error;
  useMemo(() => {
    if (projectError) {
      dispatch(logout());
    }
  }, [dispatch, projectError]);*/

  const deleteProject = async (id: number) => {
    const { data, errors } = await deleteProjectMutation({
      variables: { id },
    });

    if (data) {
      await getProjectListQuery.refetch();
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'bg-success text-light',
          delay: 5000,
          text: `Проект ${id} успешно удален`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибки удаления проекта ${errors.map(
            ({ message }) => message
          )}`,
        },
      });
    }
  };

  const handleDelete = (id: number) => {
    dispatch(
      dialogsActions.showConfirmDialog({
        title: 'Удалить проект?',
        message: 'Это приведет к удалению всех сущностей, созданных в проекте.',
        applyText: 'Удалить',
        cancelText: 'Отмена',
        onApply: () => deleteProject(id),
      })
    );
  };

  const handleCloneClick = (id: number) => console.log('clone:', id);
  const handleCardClick = (id: number) => {
    history.push(`${location.pathname}/${id}`);
  };
  const handleNewClick = () => {
    history.push(`${location.pathname}/new`);
  };
  const handlePageChange = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchProps({
      ...searchProps,
      skip: page * searchProps.take,
    });
  };

  return (
    <QueryView
      result={getProjectListQuery}
      renderData={({ projects }) => (
        <div className={className}>
          <CssBaseline />
          <AppBar className="appBar" position="fixed" elevation={0}>
            <Toolbar className="toolbar">
              <div style={{ paddingTop: 18, paddingBottom: 10 }}>
                {isAdmin && (
                  <Button
                    component={NavLink}
                    to="/players"
                    style={{ width: 208 }}
                    variant="outlined"
                    color="primary"
                  >
                    Пользователи
                  </Button>
                )}
              </div>
              <div style={{ margin: 'auto' }}>
                <img
                  style={{ height: 48, width: 48 }}
                  className="logo"
                  src="/assets/images/logo.svg"
                  alt="logo"
                />
              </div>
              <UserDropdownMenu />
            </Toolbar>
          </AppBar>
          <main className="content">
            <div className="toolbar" />
            <Container className="container" maxWidth="xl">
              <div className="grid">
                {isAdmin && (
                  <Card className="emptyCard" variant="outlined">
                    <CardActionArea
                      className="cardContent"
                      onClick={handleNewClick}
                    >
                      <Typography className="cardText">
                        Создать проект
                      </Typography>
                    </CardActionArea>
                  </Card>
                )}
                {projects.items.map((project) => (
                  <ProjectCard
                    key={project.id}
                    project={project}
                    onCardClick={handleCardClick}
                    onCloneClick={handleCloneClick}
                    onDeleteClick={handleDelete}
                  />
                ))}
              </div>
            </Container>
            <Paginator
              count={projects.total}
              perPage={searchProps.take}
              page={Math.ceil(searchProps.skip / searchProps.take)}
              onChangePage={handlePageChange}
            />
          </main>
        </div>
      )}
    />
  );
};

const Projects = styled(Projects_)`
  .appBar {
    padding-left: 32px;
    padding-right: 32px;
  }

  .toolbar {
    height: 72px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
  }

  > .content {
    flex-grow: 1;
    background-color: ${({ theme }) => theme.colors.darkBackground};
    padding: 4px;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(237px, 1fr));
    row-gap: 32px;
    column-gap: 36px;
    justify-items: center;
  }

  .emptyCard {
    width: 237px;
    height: 345px;
    border-radius: 15px;
    border: dashed 2px ${({ theme }) => theme.colors.slateBlue};
    background-color: ${({ theme }) => theme.colors.darkBackground};
  }
  .cardContent {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  .cardText {
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.slateBlue};
  }
`;

export default Projects;
