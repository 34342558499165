import { Typography } from '@material-ui/core';
import { useDebounce } from '@react-hook/debounce';
import plural from 'plural-ru';
import React, { FC, useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActionType, colors } from '../../../../../_constants';
import { useAssignAchievementToMutation } from './queries';
import {
  ElementMode,
  EmployeeOrderBy,
  OrderDir,
} from '../../../../../_graphql/schema';
import adaptFilePath from '../../../../../_helpers/adaptFilePath';
import QueryView from '../../../../_shared/QueryView';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import GrantEmployeesForm from '../_shared/GrantEmployeesForm';
import {
  useGetAchievementQuery,
  useGetValidEmployeesAchievementsListQuery,
} from './_shared/queries';
import { AchievementCancel, AchievementGrant } from './_shared/types';

interface Props {
  onCancel: AchievementCancel;
  onGrant: AchievementGrant;
}

const defaultParams = {
  skip: 0,
  take: 10,
  searchField: 'name',
  searchValue: '',
  orderBy: EmployeeOrderBy.Id,
  orderDir: OrderDir.Asc,
  isActive: true,
};

export type defaultParamsType = typeof defaultParams;

const Grant: FC<Props> = ({ onCancel, onGrant }) => {
  const { achievementId } = useParams();
  const dispatch = useDispatch();
  const [assignAchievement] = useAssignAchievementToMutation();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState(defaultParams);

  const queryResult = useGetValidEmployeesAchievementsListQuery({
    variables: {
      id: +achievementId,
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const achievementResult = useGetAchievementQuery({
    variables: {
      id: +achievementId,
    },
  });

  const setSearchParams = useCallback(
    (params: defaultParamsType) => {
      setSearchProps({
        ...searchProps,
        ...params,
      });
      setSearchValue(
        params.searchValue ? params.searchValue : searchProps.searchValue
      );
    },
    [setSearchProps, setSearchValue, searchProps]
  );

  const handleSubmit = async ({
    excluded,
    isAllSelect,
    selected,
  }: {
    excluded: number[];
    isAllSelect: boolean;
    selected: number[];
  }) => {
    const employeeIds = isAllSelect ? excluded : selected;
    const mode = isAllSelect
      ? excluded.length
        ? ElementMode.Exclude
        : ElementMode.IncludeAll
      : ElementMode.Include;

    const { data, errors } = await assignAchievement({
      variables: {
        achievementId: +achievementId,
        employeeIds,
        mode,
      },
    });

    if (data) {
      onGrant(+achievementId);
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: `success`,
          delay: 3000,
          text: `Достижение «${
            achievementResult.data?.achievement.name
          }» успешно выдано ${plural(
            data.assignAchievementTo.successCount,
            `%d игроку`,
            `%d игрокам`,
            `%d игрокам`
          )}`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: `error`,
          delay: 3000,
          text: `Ошибка выдачи достижения игрокам ${errors.map(
            ({ message }) => message
          )}`,
        },
      });
    }
  };

  return (
    <QueryView
      result={achievementResult}
      renderData={(aData) => {
        const { animation, image } = aData.achievement;
        const achievement = {
          ...aData.achievement,
          animation: animation && adaptFilePath(animation),
          image: adaptFilePath(image),
        };

        return (
          <GrantStyled>
            <Page
              header={<AppHeader title="Выдача достижения" />}
              content={
                <section className="grant">
                  <header className="grant__header">
                    <img alt="achievement" src={achievement.image} />
                    <Typography className="grant__name">
                      {achievement.name}
                    </Typography>
                  </header>
                  <QueryView
                    result={queryResult}
                    renderData={(data, result) => {
                      return (
                        <GrantEmployeesForm
                          showSelectAllMenuItem
                          showSelectAllOnPageMenuItem
                          onSubmitButtonClick={handleSubmit}
                          onCancelButtonClick={onCancel}
                          items={data.achievement.validEmployees.items}
                          total={data.achievement.validEmployees.total}
                          loading={result.loading}
                          searchParams={searchProps}
                          setSearchParams={setSearchParams}
                        />
                      );
                    }}
                  />
                </section>
              }
            />
          </GrantStyled>
        );
      }}
    />
  );
};

const GrantStyled = styled.div`
  width: 100%;
  height: 100%;

  .grant {
    display: grid;
    grid-template-rows: auto auto auto 1fr auto;

    height: 100%;
  }

  .grant__header {
    display: grid;
    justify-items: center;
    row-gap: 12px;

    margin-bottom: 20px;

    img {
      width: 80px;
      height: 80px;

      border-radius: 6px;
      object-fit: contain;
    }
  }

  .grant__name {
    color: ${colors.slateBlue};
    font-size: 20px;
    font-weight: 600;
    line-height: 1.2;
    text-align: center;
  }
`;

export default Grant;
