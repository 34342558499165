import API from './api-service';

const login = ({ email, password }: { email: string; password: string }) => {
  return API.post('', {
    query: `
            mutation signIn($email: String!, $password: String!) {
                signIn(email: $email, password: $password) {
                    accessToken, refreshToken
                }
            }`,
    variables: { email, password },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.signIn : null,
      errors,
    };
  });
};

const refreshToken = (refreshToken: string) => {
  return API.post('', {
    query: `
            mutation signRefresh($refreshToken: String!) {
                signRefresh(refreshToken: $refreshToken) {
                    accessToken, refreshToken
                }
            }`,
    variables: { refreshToken },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.signRefresh : null,
      errors,
    };
  });
};
const restore = (email: string) => {
  return API.post('', {
    query: `
            mutation signRestore($email: String!) {
                signRestore(email: $email) {
                    accessToken, refreshToken
                }
            }`,
    variables: { email },
  }).then(({ data: { data, errors } }) => {
    return {
      data: data ? data.signRestore : null,
      errors,
    };
  });
};

export const authService = {
  login,
  refreshToken,
  restore,
};
