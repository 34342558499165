import Button from '@material-ui/core/Button';
import { Form, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { authActions } from '../../../_actions';
import { ActionType, ValidationError } from '../../../_constants';
import { InputEmail, InputPassword } from '../../_shared/fields';
import { StyledFC } from '../../_shared/types';

export enum FieldName {
  email = 'email',
  password = 'password',
}

export interface FormValues {
  [FieldName.email]: string;
  [FieldName.password]: string;
}

const getEmptyInitialValues = (): FormValues => ({
  [FieldName.email]: ``,
  [FieldName.password]: ``,
});

const validation = Yup.object<Partial<FormValues>>({
  [FieldName.email]: Yup.string()
    .email(ValidationError.EMAIL)
    .required(ValidationError.REQUIRED),
  [FieldName.password]: Yup.string().required(ValidationError.REQUIRED),
});

interface Props {
  error: { response: { data: { message: string } } };
  loading: boolean;
}

const SignInForm_: StyledFC<Props> = ({ error, loading, className }) => {
  const dispatch = useDispatch();

  const handleSubmit = (formData: FormValues) => {
    const { email, password } = formData;

    authActions
      .login({ email, password })(dispatch)
      .then((data) => console.log(data))
      .catch((error: { message: string }) =>
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            className: 'error',
            delay: 5000,
            text: `Ошибка входа: ${
              error.message.indexOf('email password')
                ? 'проверьте правильность написания логина и пароля'
                : error.message
            }`,
          },
        })
      );
  };

  return (
    <Formik
      initialValues={getEmptyInitialValues()}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ isValid }) => (
        <Form className={className}>
          <InputEmail name={FieldName.email} />
          <InputPassword name={FieldName.password} />
          <div className="row">
            <p className="login-failed">
              {!loading && error?.response?.data?.message}
            </p>
            <Link to={'restore'} className="restore-password">
              Забыли пароль?
            </Link>
          </div>
          <Button
            className="submit-button"
            color="primary"
            disabled={!isValid}
            disableElevation
            type="submit"
            variant="contained"
          >
            Войти
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const SignInForm = styled(SignInForm_)`
  display: grid;
  gap: 24px;

  .row {
    display: grid;
    grid-auto-flow: column;
    justify-content: space-between;
    gap: 20px;

    .login-failed {
      min-height: 24px;
      margin: 0;

      color: var(--darkish-pink);
      font-size: 75%;
      line-height: 24px;
    }

    .restore-password {
      height: 24px;

      color: var(--slate-blue);
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;

      background: transparent;
      white-space: nowrap;
    }
  }
`;

export default SignInForm;
