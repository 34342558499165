import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { StyledFC } from '../_shared/types';
import AlertDialog from './AlertDialog';
import AuthPage from './AuthPage';
import AuthRoute from './AuthRoute';
import ConfirmDialog from './ConfirmDialog';
import LoggedInPage from './LoggedInPage';
import ToastList from './ToastList';

const App_: StyledFC = ({ className }) => {
  const location = useLocation();

  return (
    <div className={className}>
      <Switch>
        <Route path={`/auth`} component={AuthPage} />
        <AuthRoute path={`/`} component={LoggedInPage} />
        <Route
          render={() => (
            <Typography variant="h4">
              Страница {location.pathname} не найдена
            </Typography>
          )}
        />
      </Switch>
      <ConfirmDialog />
      <AlertDialog />
      <ToastList />
    </div>
  );
};

const App = styled(App_)`
  position: relative;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  min-height: 100%;
  width: 100%;
`;

export default App;
