import ReactHtmlParser from 'react-html-parser';

const toBase64 = (file: Blob): Promise<string | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      resolve(
        reader.result instanceof ArrayBuffer
          ? reader.result[Symbol.toStringTag]
          : reader.result
      );
    };
    reader.onerror = (error) => reject(error);
  });

const descendingComparator = (a: number[], b: number[], orderBy: number) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

const getComparator = (orderDir: string, orderBy: string) => {
  return orderDir === 'desc'
    ? (a: number[], b: number[]) => descendingComparator(a, b, +orderBy)
    : (a: number[], b: number[]) => -descendingComparator(a, b, +orderBy);
};

const stableSort = (
  array: number[],
  comparator: (a: number[], b: number[]) => number
) => {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a, b);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
};

//toReactHtmlParser: (str: string | undefined) => ReactElement[];
const toReactHtmlParser = (str: string) => {
  return ReactHtmlParser(str ? str : '');
};

export { toBase64, getComparator, stableSort, toReactHtmlParser };
