import jwt from 'jsonwebtoken';
import store from '../store';
import { ActionType } from '../_constants';
import client from '../_graphql/apollo-client';
import { RefreshTokenDocument } from '../_graphql/queries/auth-queries';

type RefreshTokenType = {
  accessToken: string;
  refreshToken: string;
  tokenExpiredDate: string | number | Date;
  tokenExpiredLimit: number;
};
let accessToken: string;
let refreshToken: string;

/** прослушивание изменения refreshToken в Local Storage */
/*const newAccessToken: any = jwt.decode(JSON.parse(e.newValue).accessToken);*/

const storageEventHandler = (e: {
  key: string | null;
  oldValue: string | null;
  newValue: string | null;
}) => {
  if (e.key === 'auth' && e.newValue) {
    refreshToken = JSON.parse(e.newValue).refreshToken;
    localStorage.setItem(e.key, e.newValue);
  }
};
window.addEventListener('storage', storageEventHandler, false);
/** end */

let tokenExpiredDate: string | number | Date;
let tokenExpiredLimit: number;

setInterval(() => {
  if (!store.getState().auth.accessToken) return;
  if (isTokenExpired()) {
    getNewTokens();
  }
}, 5000);

const isTokenExpired = () => {
  if (!accessToken) {
    accessToken = store.getState().auth.accessToken;
    refreshToken = store.getState().auth.refreshToken;
    setTokenExpiredDate(accessToken);
  }
  const timeLeft = getDifferenceBetweenDates(
    new Date(),
    new Date(tokenExpiredDate)
  );
  const limit = Math.floor(tokenExpiredLimit * 0.2);
  //console.log('timeLeft:', timeLeft);
  //console.log('limit:', limit);
  return timeLeft < limit;
};

const getDifferenceBetweenDates = (startDate: Date, endDate: Date) => {
  //console.log('startDate:', startDate);
  //console.log('endDate:', endDate);
  return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
};

const getNewTokens = () => {
  client
    .mutate({
      mutation: RefreshTokenDocument,
      variables: { refreshToken: refreshToken },
    })
    .then((response) => {
      accessToken = response.data.signRefresh.accessToken;
      refreshToken = response.data.signRefresh.refreshToken;
      store.dispatch(success(accessToken, refreshToken));
      console.log(response.data);
    })
    .catch((err) => {
      console.log(err);
      //window.location.reload();
      //window.location.href = '/';
      //logout();
    });
};

const refreshTokenMiddleware = (/*store*/) => (
  next: (arg0: {
    type: ActionType.LOGIN_SUCCESS | ActionType.REFRESH_TOKEN_SUCCESS;
    payload: RefreshTokenType;
  }) => void
) => (action: {
  type: ActionType.LOGIN_SUCCESS | ActionType.REFRESH_TOKEN_SUCCESS;
  payload: RefreshTokenType;
}) => {
  const payload = getPayload(action);
  //console.log('action:', action);
  //console.log('payload:', payload);
  if (payload.accessToken) {
    accessToken = payload.accessToken;
    refreshToken = payload.refreshToken;
    setTokenExpiredDate(accessToken);
  }
  next(action);
};

const getPayload = (action: {
  type: ActionType.LOGIN_SUCCESS | ActionType.REFRESH_TOKEN_SUCCESS;
  payload: RefreshTokenType;
}) => {
  if (
    [ActionType.LOGIN_SUCCESS, ActionType.REFRESH_TOKEN_SUCCESS].includes(
      action.type
    )
  ) {
    return action.payload;
  } else {
    return {
      accessToken: store.getState().auth.accessToken,
      refreshToken: store.getState().auth.refreshToken,
    };
  }
};

const setTokenExpiredDate = (token: string) => {
  const expiredDate:
    | { [p: string]: Array<{ p: string }> }
    | { exp?: number }
    | number
    | string
    | any
    | null = jwt.decode(token);

  tokenExpiredDate = new Date(expiredDate?.exp);
  tokenExpiredLimit = getDifferenceBetweenDates(new Date(), tokenExpiredDate);
  //console.log('tokenExpiredData:', tokenExpiredDate);
  //console.log('tokenExpiredLimit:', tokenExpiredLimit);
};

function success(
  accessToken: string,
  refreshToken: string
): {
  type: ActionType.REFRESH_TOKEN_SUCCESS;
  payload: { accessToken: string; refreshToken: string };
} {
  console.log({
    accessToken,
    refreshToken,
    tokenExpiredDate,
    tokenExpiredLimit,
  });
  return {
    type: ActionType.REFRESH_TOKEN_SUCCESS,
    payload: { accessToken, refreshToken },
  };
}

export default refreshTokenMiddleware;
