import { Card as MaterialCard } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import React from 'react';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../_shared/types';
import { ProjectBaseFieldsFragment } from './_shared/queries';

interface Props {
  project: ProjectBaseFieldsFragment;
  onCardClick: (id: number) => void;
  onCloneClick: (id: number) => void;
  onDeleteClick: (id: number) => void;
}

const Card_: StyledFC<Props> = ({
  project,
  className,
  onCardClick,
  onCloneClick,
  onDeleteClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      <MaterialCard elevation={0} className="card">
        <CardActionArea onClick={() => onCardClick(project.id)}>
          <CardMedia
            className="cardMedia"
            component="img"
            alt={project.name}
            height="135"
            image={process.env.REACT_APP_BASE_PATH + project.image}
            title={project.name}
          />
          <CardContent className="content">
            <Typography className="name">{project.name}</Typography>
            <Typography className="description">
              {project.description}
            </Typography>
            <Divider className="divider" />
            <div className="avatarGroup">
              {project.managers.items.map((manager) => (
                <Avatar
                  className="avatar"
                  alt={manager.name}
                  key={manager.id}
                  src={
                    manager.avatar
                      ? process.env.REACT_APP_BASE_PATH + manager.avatar
                      : '/assets/images/male-default-avatar.svg'
                  }
                />
              ))}
            </div>
          </CardContent>
        </CardActionArea>
        <IconButton
          className="iconButton"
          aria-label="settings"
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          className="menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          elevation={0}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              onCloneClick(project.id);
              handleClose();
            }}
          >
            Клонировать
          </MenuItem>
          <MenuItem
            onClick={() => {
              onDeleteClick(project.id);
              handleClose();
            }}
          >
            Удалить
          </MenuItem>
        </Menu>
      </MaterialCard>
    </div>
  );
};

const Card = styled(Card_)`
  > .card {
    width: 237px;
    height: 345px;
    border-radius: 15px;
    position: relative;
    .cardMedia {
      opacity: 0.5;
      background-color: ${({ theme }) => theme.colors.hint};
    }
    .content {
      padding: 20px 24px 24px 25px;
      height: 210px;
      display: flex;
      flex-direction: column;
      background-color: ${({ theme }) => theme.colors.card};
    }

    .iconButton {
      position: absolute;
      top: 0;
      right: 0;
    }

    .name {
      font-size: 14px;
      font-weight: 600;
      color: ${({ theme }) => theme.colors.white};
    }
    .description {
      font-size: 12px;
      font-weight: 300;
      line-height: 1.42;
      color: ${({ theme }) => theme.colors.blueGrey};
      margin-top: 16px;
      margin-bottom: auto;
      overflow: hidden;
      text-overflow: ellipsis;
      height: 51px;
    }
    .divider {
      margin: 16px 0;
    }
    .avatarGroup {
      display: flex;
      height: 28px;
    }
    .avatar {
      height: 28px;
      width: 28px;
      border: solid 1px ${({ theme }) => theme.colors.card};
      & ~ .avatar {
        margin-left: -6px;
      }
    }
  }
`;

export default Card;
