import Button from '@material-ui/core/Button';
import { Form as FormikForm, Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import * as Yup from 'yup';
import { authActions } from '../../../_actions';
import { ActionType, ValidationError } from '../../../_constants';
import { InputEmail } from '../../_shared/fields';
import { StyledFC } from '../../_shared/types';

export enum FieldName {
  email = 'email',
}

export interface FormValues {
  [FieldName.email]: string;
}

const getEmptyInitialValues = (): FormValues => ({
  [FieldName.email]: ``,
});

const validation = Yup.object<Partial<FormValues>>({
  [FieldName.email]: Yup.string()
    .email(ValidationError.EMAIL)
    .required(ValidationError.REQUIRED),
});

interface Props {
  onCancel: () => void;
  onRestore: () => void;
}

const RestoreForm_: StyledFC<Props> = ({ className, onCancel, onRestore }) => {
  const dispatch = useDispatch();

  const handleSubmit = (formData: FormValues) => {
    authActions
      .restore(formData.email)()
      .then(() => {
        onRestore();
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            className: 'bg-success text-light',
            delay: 3000,
            text: `Ссылка для сброса пароля отправлена на почту ${formData.email}`,
          },
        });
      })
      .catch(() => {
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            className: 'bg-danger text-light',
            delay: 3000,
            text: `Пользователь с таким Email не найден.`,
          },
        });
      });
  };

  return (
    <Formik
      initialValues={getEmptyInitialValues()}
      validationSchema={validation}
      onSubmit={handleSubmit}
    >
      {({ dirty, isValid, isSubmitting }) => (
        <FormikForm className={className}>
          <p className="info">
            На указанный адрес придет ссылка для сброса пароля
          </p>
          <InputEmail name={FieldName.email} />
          <Button
            className="submit-button"
            variant="contained"
            color="primary"
            disableElevation
            type="submit"
            disabled={!dirty || !isValid || isSubmitting}
          >
            Получить ссылку
          </Button>
          <Button
            className="register-button"
            variant="text"
            color="primary"
            disableElevation
            type="button"
            onClick={onCancel}
          >
            Отмена
          </Button>
        </FormikForm>
      )}
    </Formik>
  );
};

const RestoreForm = styled(RestoreForm_)`
  display: grid;

  .info {
    width: 274px;
    height: 48px;
    margin: 0 auto 36px;

    color: var(--slate-blue);
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    text-align: center;
  }

  .submit-button {
    margin-top: 24px;
  }

  .register-button {
    margin-top: 8px;
  }
`;

export default RestoreForm;
