import { TextField } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import React, { useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import { dateToString } from '../../../../../_helpers/dateToString';
import { fileUrl } from '../../../../../_helpers/file';
import { toBase64, toReactHtmlParser } from '../../../../../_utils';
import { useRemoteState } from '../../../../_shared/hooks';
import QueryView from '../../../../_shared/QueryView';
import { StyledFC } from '../../../../_shared/types';
import Uploader from '../../../../_shared/Uploader';
import Page from '../Page';
import Assignment from './Assignment';
import { useGetAssignmentQuery, useUpdateAssignmentMutation } from './queries';

const AssignmentPage_: StyledFC = ({ className }) => {
  /** Check isEdit */
  const history = useHistory();
  const location = useLocation();
  const {
    params: { playerId, achievementId },
  } = useRouteMatch();

  const isEdit = location.pathname.endsWith('/edit');

  const onIsEditChange = () => {
    history.push(`${location.pathname}/edit`);
  };

  /** Get data */

  const employeeId = +playerId;
  const achId = +achievementId;
  const result = useGetAssignmentQuery({
    variables: { employee: employeeId, achievement: achId },
  });

  const [imageFile, setImageFile] = useState<File>();
  const [image, setLocalImage] = useRemoteState(
    result.data?.assignment.image ? fileUrl(result.data?.assignment.image) : ''
  );
  const [desc, setLocalDesc] = useRemoteState(
    result.data?.assignment.description || ''
  );

  /** Update data */
  const [updateAssignment] = useUpdateAssignmentMutation();

  const onSave = async () => {
    const imageBase64: string | ArrayBuffer | null = imageFile
      ? await toBase64(imageFile)
      : null;

    const variables = {
      employeeId,
      achId,
      newDesc: desc,
      imageBase64,
    };
    if (!imageBase64) delete variables.imageBase64;
    await updateAssignment({
      variables,
    });
    await result.refetch();
    setLocalImage(undefined);
    setLocalDesc(undefined);
    history.goBack();
  };

  const onCancel = () => {
    setLocalImage(undefined);
    setLocalDesc(undefined);
    history.goBack();
  };

  return (
    <QueryView
      result={result}
      renderError={(result) => <div>Error: {result.error}</div>}
      renderData={(data) => (
        <Page
          routeEndsWith={
            location.pathname.endsWith('/edit')
              ? `/players/:playerId(\\d+)/assignments/:achievementId(\\d+)/edit`
              : `/players/:playerId(\\d+)/assignments/:achievementId(\\d+)`
          }
          header={<></>}
          content={
            <>
              {!result.loading && (
                <div className={className}>
                  <div className="wrap-content">
                    <div className="wrap-cards">
                      <Assignment assignment={data.assignment} />
                      {data.assignment.image && (
                        <div className="memory-card">
                          <h1>Воспоминание</h1>
                          {isEdit ? (
                            <>
                              <div className="wrap-uploader">
                                <Uploader
                                  mode={'image'}
                                  src={image}
                                  file={imageFile}
                                  isDeleteBtn={false}
                                  isLabelFileName={false}
                                  bgSize={'cover'}
                                  onChange={({ file, src }) => {
                                    setLocalImage(src ? src : undefined);
                                    setImageFile(file ? file : undefined);
                                  }}
                                >
                                  {' '}
                                </Uploader>
                              </div>
                              <TextField
                                fullWidth
                                multiline
                                rowsMax={5}
                                variant="outlined"
                                className="memory-content-input"
                                defaultValue={data.assignment.description}
                                onChange={(e) => setLocalDesc(e.target.value)}
                              />
                            </>
                          ) : (
                            <>
                              <div
                                className="memory-pict"
                                style={{ backgroundImage: `url(${image})` }}
                              />
                              <p className="memory-content">
                                {toReactHtmlParser(data.assignment.description)}
                              </p>
                              <IconButton
                                onClick={onIsEditChange}
                                className="edit-btn"
                              >
                                <EditIcon className="edit-icon" />
                              </IconButton>
                            </>
                          )}

                          <div className="memory-date">
                            {dateToString(
                              new Date(data.assignment.updatedAt),
                              'DD.MMMM.YYYY'
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {isEdit && (
                      <div className="buttons">
                        <Button
                          className="cancel-button"
                          variant="text"
                          color="primary"
                          disableElevation
                          type="button"
                          onClick={onCancel}
                        >
                          Отмена
                        </Button>
                        <Button
                          className="save-button"
                          variant="contained"
                          color="primary"
                          disableElevation
                          type="submit"
                          onClick={onSave}
                          disabled={
                            !(data.assignment.description !== desc || imageFile)
                          }
                        >
                          Сохранить
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </>
          }
        />
      )}
    />
  );
};

const AssignmentPage = styled(AssignmentPage_)`
  min-height: 1100px;
  width: 100%;
  z-index: 4;
  position: relative;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.darkBackground};

  .wrap-content {
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .buttons {
      display: flex;
      align-self: flex-end;
      .save-button {
        height: 36px;
        width: 208px;
        border-radius: 18px;
        margin-bottom: 18px;
        margin-right: 0;
      }
      .cancel-button {
        height: 36px;
        width: 160px;
        border-radius: 18px;
        margin-right: 18px;
        margin-bottom: 18px;
      }
    }
    .wrap-cards {
      width: 100%;
      margin: 5%;
      border-radius: 30px;
      overflow: hidden;
      text-align: center;
      h1 {
        font-size: 36px;
        font-weight: bold;
        line-height: 1.11;
        margin: 0;
        padding: 1rem 0;
      }
      p {
        font-size: 20px;
        line-height: 1.5;
        margin: 0.5rem;
      }
      span {
        font-size: 16px;
        padding: 1rem 0;
      }
      .memory-card {
        min-height: 600px;
        background-color: ${({ theme }) => theme.colors.card};
        display: flex;
        flex-direction: column;
        align-items: center;
        .edit-btn {
          align-self: flex-end;
          transform: translateY(-4rem);
          margin-right: 2rem;
          width: 5.5rem;
          height: 5.5rem;
          .edit-icon {
            width: 2rem;
            height: 2rem;
          }
        }

        .memory-pict {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 1rem;
          min-height: 500px;
          max-height: 500px;
          min-width: 500px;
          max-width: 500px;
          border-radius: 30px;
          overflow: hidden;
          background: no-repeat center;
          -webkit-background-size: cover;
          -moz-background-size: cover;
          -o-background-size: cover;
          background-size: cover;
        }
        .wrap-uploader {
          display: flex;
          flex-direction: column;
          margin: 1rem;
          min-height: 500px;
          max-height: 500px;
          min-width: 500px;
          max-width: 500px;
          border-radius: 30px;
          overflow: hidden;
        }
        .memory-content {
          width: 70%;
        }
        .memory-content-input {
          max-width: 80%;
          font-size: 20px;
          margin-bottom: 1rem;
        }
        .memory-date {
          margin-bottom: 2rem;
        }
      }
    }
  }
  @media (max-width: 897px) {
    .wrap-cards {
      width: 100%;
      margin: 2% 2% 2% 2%;
    }
  }
`;

export default AssignmentPage;
