import Container from '@material-ui/core/Container';
import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation, useRouteMatch } from 'react-router';
import styled from 'styled-components/macro';
import { StyledFC } from '../../../_shared/types';

interface Props {
  content: JSX.Element;
  header: JSX.Element;
  noScroll?: boolean;
  routeEndsWith?: string;
}

const Page_: StyledFC<Props> = ({
  className,
  content: Content,
  header: Header,
  noScroll = false,
  routeEndsWith = '',
}) => {
  const match: {
    params: { playerId: string; projectId: string; achievementId: string };
  } = useRouteMatch();
  const locPath = useLocation();

  const replacedRouteEndsWith = routeEndsWith
    .replace(':projectId(\\d+)', match.params.projectId)
    .replace(':playerId(\\d+)', match.params.playerId)
    .replace(':achievementId(\\d+)', match.params.achievementId);
  const hide = !locPath.pathname.endsWith(replacedRouteEndsWith);

  return (
    <div className={hide ? className + ' hide' : className}>
      <div className="header">{Header}</div>
      <PerfectScrollbar>
        <Container
          className={[`${noScroll ? 'no-scroll' : ''}`, 'container'].join(' ')}
          maxWidth="xl"
        >
          {Content}
        </Container>
      </PerfectScrollbar>
    </div>
  );
};
const Page = styled(Page_)`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  height: 100%;
  &.hide {
    display: none;
  }
  > .header {
    padding: 0 32px;
  }
  > div > .container {
    flex-grow: 1;
    overflow: auto;
    padding: 0 56px;
    display: flex;
    flex-direction: column;
    min-height: 100%;
    &.no-scroll {
      height: 100%;
      overflow: hidden;
    }
  }
`;

export default Page;
