import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import { useDebounce } from '@react-hook/debounce';
import React, { useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from '../../../../../_constants';
import { CategoryOrderBy, OrderDir } from '../../../../../_graphql/schema';
import EditIcon from '../../../../icons/EditIcon';
import HighlightedText from '../../../../_shared/HighlightedText';
import Paginator from '../../../../_shared/Paginator';
import QueryView from '../../../../_shared/QueryView';
import SearchBar from '../../../../_shared/SearchBar';
import SharedTable from '../../../../_shared/SharedTable';
import { StyledFC } from '../../../../_shared/types';
import Page from '../../_shared/Page';
import AwardsHeader from '../_shared/AwardsHeader';
import { useGetProjectCategoriesQuery } from './_shared/queries';
import { CategoryEditClickType, CategoryNewClickType } from './_shared/types';

const tableHeaders = [
  { id: 'name', numeric: true, disablePadding: false, label: 'Название' },
  {
    id: 'achievementsCount',
    numeric: true,
    disablePadding: false,
    label: 'Достижений',
  },
  { id: 'id', numeric: true, disablePadding: false, label: 'ID' },
  { id: 'button', numeric: true, disablePadding: false, label: '' },
];

type EditButtonClickType = (e: React.MouseEvent<HTMLButtonElement>) => void;
type SortRequestType = (orderBy: CategoryOrderBy, orderDir: OrderDir) => void;

const getHeaderTitle = (total: number, searchValue: string) => {
  return searchValue === ``
    ? `Категории (${total})`
    : `Категории (${total} из TODO: ${total})`;
};
interface Props {
  highlightedId: number | undefined;
  onEditClick: CategoryEditClickType;
  onNewClick: CategoryNewClickType;
}

const Main_: StyledFC<Props> = ({
  className,
  highlightedId,
  onEditClick,
  onNewClick,
}) => {
  const { projectId } = useParams<{ projectId: string }>();

  const [orderBy, setOrderBy] = useState(CategoryOrderBy.Name);
  const [orderDir, setOrderDir] = useState(OrderDir.Asc);
  const [perPage] = useState(10);
  const [searchField, setSearchField] = useState(`name`);
  const [searchValue, setSearchValue] = useDebounce(``, 500);
  const [skip, setSkip] = useState(0);

  const projectCategoriesQueryResult = useGetProjectCategoriesQuery({
    variables: {
      id: +projectId,
      name: searchValue,
      orderBy,
      orderDir,
      skip,
      take: perPage,
    },
  });
  /** Обновление списка для перехода со страниц добавления/редактирования */
  const location = useLocation();
  const isCategories = location.pathname.endsWith('/categories');
  if (isCategories) projectCategoriesQueryResult.refetch();

  const handleEditClick: EditButtonClickType = (e) => {
    const { id } = e.currentTarget.dataset;
    id ? onEditClick(id) : console.warn(`Table row must have data-id`);
  };

  const handlePageChange = (_e: any, page: number) => {
    setSkip(page * perPage);
  };

  const handleRequestSort: SortRequestType = (orderBy, orderDir) => {
    setOrderBy(orderBy);
    setOrderDir(orderDir);
  };

  return (
    <QueryView
      result={projectCategoriesQueryResult}
      renderData={(data) => (
        <div className={className}>
          <Page
            header={
              <AwardsHeader
                title={getHeaderTitle(
                  data.project.categories.total,
                  searchValue
                )}
              />
            }
            noScroll
            content={
              <>
                <div className="buttonsWrapper">
                  <SearchBar
                    placeholder="Поиск категории..."
                    type="categories"
                    onFocusEnable
                    onSearchValueChange={setSearchValue}
                    onSearchFieldChange={setSearchField}
                  />
                  <Button
                    className="addCategoryButton"
                    variant="contained"
                    color="primary"
                    disableElevation
                    type="submit"
                    onClick={onNewClick}
                    style={{
                      backgroundColor: 'transparent',
                      border: '2px solid #2b3757',
                    }}
                  >
                    <AddIcon
                      htmlColor={colors.slateBlue}
                      style={{ marginRight: 12 }}
                    />
                    Категория
                  </Button>
                </div>
                <SharedTable<CategoryOrderBy>
                  headCells={tableHeaders}
                  hideLastSortIcon
                  headerPaddingLeft
                  onRequestSort={handleRequestSort}
                  rows={data.project.categories.items.map((category) => (
                    <TableRow
                      className={
                        highlightedId === category.id ? 'highlighted' : ''
                      }
                      key={category.id}
                      tabIndex={-1}
                      style={{ height: 72, cursor: 'default' }}
                      hover
                    >
                      <TableCell className="cellWidth">
                        <HighlightedText
                          text={category.name}
                          searchValue={searchValue}
                          skip={searchField !== 'name'}
                        />
                      </TableCell>
                      <TableCell className="cellWidth">
                        <HighlightedText
                          text={String(category.achievementsCount)}
                          searchValue={searchValue}
                          skip={false}
                        />
                      </TableCell>
                      <TableCell className="cellWidth">
                        <HighlightedText
                          text={String(category.id)}
                          searchValue={searchValue}
                          skip={false}
                        />
                      </TableCell>
                      <TableCell className="cellWidth">
                        <IconButton
                          className="action-button"
                          data-id={category.id}
                          onClick={handleEditClick}
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                />
                <Paginator
                  count={data.project.categories.total}
                  perPage={perPage}
                  page={Math.ceil(skip / perPage)}
                  onChangePage={handlePageChange}
                />
              </>
            }
          />
        </div>
      )}
    />
  );
};

const Main = styled(Main_)`
  > div > .scrollbar-container {
    .addCategoryButton {
      height: 36px;
      padding: 0 24px 0 16px;
      border-radius: 18px;
      /*background-color: transparent;
      border: 2px solid #2b3757;*/
      :hover {
        background-color: rgb(64, 76, 107) !important;
      }
      margin-top: 8px;
      margin-bottom: 24px;
    }
    .buttonsWrapper {
      min-height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      .searchGroup {
        margin-top: 0;
      }
    }
    .cellWidth {
      &:before {
        position: absolute;
        bottom: -2px;
        right: 0;
        width: 100% !important;
        border-top: ${({ theme }) => theme.colors.darkBlueGrey};
        content: ' ';
      }
      &:first-child {
        width: 40% !important;
        padding-left: 48px !important;
      }
      &:nth-child(2) {
        width: 25% !important;
      }
      &:nth-child(3) {
        width: 25% !important;
      }
      &:nth-child(4) {
        width: 10% !important;
        &:before {
          bottom: -1.5px;
        }
      }
    }
  }
`;

export default Main;
