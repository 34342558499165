import * as Types from '../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetEmployeeListQueryVariables = {
  skip: Types.Scalars['Int'];
  take: Types.Scalars['Int'];
  name?: Types.Maybe<Types.Scalars['String']>;
  email?: Types.Maybe<Types.Scalars['String']>;
  rootSubdivisionName?: Types.Maybe<Types.Scalars['String']>;
  position?: Types.Maybe<Types.Scalars['String']>;
  id?: Types.Maybe<Types.Scalars['String']>;
  orderBy: Types.EmployeeOrderBy;
  orderDir: Types.OrderDir;
  isActive: Types.Scalars['Boolean'];
};


export type GetEmployeeListQuery = (
  { __typename?: 'Query' }
  & { employees: (
    { __typename?: 'EmployeePage' }
    & Pick<Types.EmployeePage, 'total'>
    & { items: Array<(
      { __typename?: 'Employee' }
      & Pick<Types.Employee, 'id' | 'name' | 'email' | 'avatar' | 'isActive' | 'position' | 'rootSubdivisionName'>
    )> }
  ) }
);


export const GetEmployeeListDocument = gql`
    query getEmployeeList($skip: Int!, $take: Int!, $name: String, $email: String, $rootSubdivisionName: String, $position: String, $id: String, $orderBy: EmployeeOrderBy!, $orderDir: OrderDir!, $isActive: Boolean!) {
  employees(skip: $skip, take: $take, rootSubdivisionName: $rootSubdivisionName, name: $name, email: $email, position: $position, id: $id, orderBy: $orderBy, orderDir: $orderDir, isActive: $isActive) {
    items {
      id
      name
      email
      avatar
      isActive
      position
      email
      rootSubdivisionName
    }
    total
  }
}
    `;

/**
 * __useGetEmployeeListQuery__
 *
 * To run a query within a React component, call `useGetEmployeeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeListQuery({
 *   variables: {
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      rootSubdivisionName: // value for 'rootSubdivisionName'
 *      position: // value for 'position'
 *      id: // value for 'id'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      isActive: // value for 'isActive'
 *   },
 * });
 */
export function useGetEmployeeListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeListQuery, GetEmployeeListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeListQuery, GetEmployeeListQueryVariables>(GetEmployeeListDocument, baseOptions);
      }
export function useGetEmployeeListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeListQuery, GetEmployeeListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeListQuery, GetEmployeeListQueryVariables>(GetEmployeeListDocument, baseOptions);
        }
export type GetEmployeeListQueryHookResult = ReturnType<typeof useGetEmployeeListQuery>;
export type GetEmployeeListLazyQueryHookResult = ReturnType<typeof useGetEmployeeListLazyQuery>;
export type GetEmployeeListQueryResult = ApolloReactCommon.QueryResult<GetEmployeeListQuery, GetEmployeeListQueryVariables>;