import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const EditIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g fill="currentColor" fillRule="nonzero">
      <path d="M5.975 20.818c.117.11.166.274.132.432a.482.482 0 0 1-.29.35l-4.652 1.882a.496.496 0 0 1-.534-.1.474.474 0 0 1-.103-.524l1.92-4.56a.493.493 0 0 1 .798-.153l2.729 2.673zM23.402 4.882L21.3 6.942a.494.494 0 0 1-.695 0l-3.203-3.135a.5.5 0 0 1-.142-.34c0-.13.053-.25.142-.342l2.106-2.059a2.072 2.072 0 0 1 2.89 0l1.004.98a1.98 1.98 0 0 1 0 2.836zM19.395 8.611a.49.49 0 0 1-.142.34L7.939 20.036a.507.507 0 0 1-.696 0l-3.198-3.134a.477.477 0 0 1 0-.682l11.31-11.088a.52.52 0 0 1 .695 0l3.203 3.14a.5.5 0 0 1 .142.34z" />
    </g>
  </SvgIcon>
);
export default EditIcon;
