import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDebounce } from '@react-hook/debounce';
import React, { createRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActionType, colors } from '../../../../_constants';
import { EmployeeOrderBy, OrderDir } from '../../../../_graphql/schema';
import { importsService } from '../../../../_services';
import QueryView from '../../../_shared/QueryView';
import ChevronRightIcon from '../../../icons/ChevronRightIcon';
import HighlightedText from '../../../_shared/HighlightedText';
import Paginator from '../../../_shared/Paginator';
import SearchBar from '../../../_shared/SearchBar';
import SharedTable from '../../../_shared/SharedTable';
import { HeadCell, StyledFC } from '../../../_shared/types';
import AppHeader from '../MainPage/_shared/AppHeader';
import Loader from '../_shared/Loader';
import Page from '../_shared/Page';
import PlayerPage from '../_shared/PlayerPage';
import { useGetEmployeeListQuery } from './queries';

const headCells: HeadCell<EmployeeOrderBy>[] = [
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Имя',
    orderBy: EmployeeOrderBy.Name,
  },
  {
    id: 'rootSubdivisionName',
    numeric: true,
    disablePadding: false,
    label: 'Подразделение',
    orderBy: EmployeeOrderBy.RootSubdivisionName,
  },
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: 'Должность',
    orderBy: EmployeeOrderBy.Position,
  },
  {
    id: 'email',
    numeric: true,
    disablePadding: false,
    label: 'Email',
    orderBy: EmployeeOrderBy.Email,
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Табельный номер',
    orderBy: EmployeeOrderBy.Id,
  },
];

const searchBySource = [
  {
    label: 'ФИО сотрудника',
    value: 'name',
  },
  {
    label: 'Подразделение',
    value: 'rootSubdivisionName',
  },
  {
    label: 'Должность',
    value: 'position',
  },
  {
    label: 'Email',
    value: 'email',
  },
  {
    label: 'Табельный номер',
    value: 'id',
  },
];

const EmployeesPage_: StyledFC = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { path, url } = useRouteMatch();

  // const [importEmployees, setImportEmployees] = useState({
  //   loading: false,
  // });

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState({
    skip: 0,
    take: 10,
    searchField: 'name',
    orderBy: EmployeeOrderBy.Name,
    orderDir: OrderDir.Asc,
    isActive: true,
  });

  // const [highlightEmployee, setHighlightEmployee] = useState({ id: 1 });

  const uploadFile = createRef<any>();

  const result = useGetEmployeeListQuery({
    variables: {
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const onEmployeeClick = (employee: { id: number }) => {
    history.push(`${url}/${employee.id}`);
  };

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const onSearchFieldChange = (field: string) => {
    setSearchProps({
      ...searchProps,
      searchField: field,
    });
  };

  const onPageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchProps({
      ...searchProps,
      skip: page * searchProps.take,
    });
  };

  const onRequestSort = (orderBy: EmployeeOrderBy, orderDir: OrderDir) => {
    setSearchProps({
      ...searchProps,
      orderBy: orderBy,
      orderDir: orderDir,
    });
  };
  const onIsActiveChange = () => {
    setSearchProps(() => ({
      ...searchProps,
      isActive: !searchProps.isActive,
      skip: 0,
    }));
  };

  const onCsvUploaded = (e: { target: { files: any } }) => {
    const files = e.target.files;
    const csv = (files.length && files[0]) || null;
    if (csv) {
      // setImportEmployees(() => ({ loading: true }));
      importsService
        .importEmployees(csv)
        .then(() => {
          // setImportEmployees(() => ({
          //   loading: false,
          // }));
          result.refetch().then();
        })
        .catch((error) => {
          dispatch({
            type: ActionType.ADD_TOAST,
            payload: {
              text: `Ошибка импорта списка сотрудников ${error.message}`,
              className: 'error',
              delay: 5000,
            },
          });
          // setImportEmployees(() => ({
          //   loading: false,
          // }));
        });
    }
  };

  const onImportEmployeeButtonFocus = () => {
    uploadFile.current.value = null;
  };

  const headerTitle = (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <NavLink className="navLink" to="projects">
        Проекты
      </NavLink>
      <ChevronRightIcon className="headerIcon" />
      Пользователи
    </span>
  );

  return (
    <div className={className}>
      <Page
        header={<AppHeader title={headerTitle} />}
        noScroll
        content={
          <>
            <div className="buttonsWrapper">
              <SearchBar
                searchBySource={searchBySource}
                placeholder={`Поиск пользователей...`}
                onSearchValueChange={onSearchValueChange}
                onSearchFieldChange={onSearchFieldChange}
                className="searchBar"
                selectWidth={240}
              />
              <Button
                className="importEmployeesButton"
                variant="outlined"
                color="primary"
                disableElevation
                type="button"
                onFocus={onImportEmployeeButtonFocus}
                onClick={() => uploadFile.current.click()}
              >
                Импортировать
                <input
                  accept=".csv"
                  type="file"
                  style={{
                    opacity: 0,
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    borderRadius: '18px',
                    cursor: 'pointer',
                  }}
                  hidden
                  ref={uploadFile}
                  onChange={onCsvUploaded}
                />
              </Button>
            </div>
            <div className="row" style={{ marginBottom: 24 }}>
              <FormControlLabel
                className="isActiveCheckbox"
                control={
                  <Checkbox
                    checked={searchProps.isActive}
                    onChange={onIsActiveChange}
                    color="primary"
                  />
                }
                label="Действующий сотрудник"
              />
            </div>
            <QueryView
              result={result}
              renderData={(data, result) => {
                const Rows = (): JSX.Element[] => {
                  const employees = data.employees.items;
                  const searchString = searchValue;
                  const searchField = searchProps.searchField;
                  //if (!employeeList?.employees?.items[0]?.email) return null;
                  return employees.map((row) => {
                    return (
                      <TableRow
                        // className={
                        //   highlightEmployee && highlightEmployee.id === row.id
                        //     ? 'highlighted'
                        //     : ''
                        // }
                        hover
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => onEmployeeClick(row)}
                        style={{ height: row.avatar ? 92 : 72 }}
                      >
                        <TableCell align="left" className="cell cellWidth">
                          <Badge
                            className={`badge ${row.isActive ? 'active' : ''}`}
                            overlap="circle"
                            badgeContent=" "
                            variant="dot"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                          >
                            <Avatar
                              className="avatar"
                              src={
                                row?.avatar
                                  ? process.env.REACT_APP_BASE_PATH + row.avatar
                                  : '/assets/images/male-default-avatar.svg'
                              }
                            />
                          </Badge>
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.name}
                            searchValue={searchString}
                            skip={searchField !== 'name'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.rootSubdivisionName}
                            searchValue={searchString}
                            skip={searchField !== 'rootSubdivisionName'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.position}
                            searchValue={searchString}
                            skip={searchField !== 'position'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.email}
                            searchValue={searchString}
                            skip={searchField !== 'email'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.id.toString()}
                            searchValue={searchString}
                            skip={searchField !== 'id'}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  });
                };

                return (
                  <div className="relative">
                    <SharedTable<EmployeeOrderBy>
                      onRequestSort={onRequestSort}
                      rows={Rows()}
                      headCells={headCells}
                    />

                    <Paginator
                      className="paginator"
                      count={data.employees.total}
                      perPage={searchProps.take}
                      page={Math.ceil(searchProps.skip / searchProps.take)}
                      onChangePage={onPageChange}
                    />
                    {result.loading && <Loader blurred />}
                  </div>
                );
              }}
            />
          </>
        }
      />
      <Switch>
        <Route
          path={`${path}/:playerId(\\d+)`}
          render={() => (
            <div className="pageContainer">
              <PlayerPage />
            </div>
          )}
        />
        <Route exact render={() => <Redirect to={`${url}`} />} />
      </Switch>
    </div>
  );
};

const EmployeesPage = styled(EmployeesPage_)`
  > div > .scrollbar-container {
    .importEmployeesButton {
      height: 36px;
      width: 208px;
      padding: 0 24px 0 16px;
      margin-left: 16px;
      border-radius: 18px;
      background-color: transparent;
      border: 2px solid #2b3757;
      cursor: pointer;
      :hover {
        background-color: rgb(64, 76, 107) !important;
      }
    }
    .buttonsWrapper {
      min-height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .paginator {
      margin-top: auto;
      height: 64px;
      align-items: center;
      flex-shrink: 0;
    }
    .searchBar {
      width: 70%;
    }
    .isActiveCheckbox {
      padding-left: 20px;
      margin: 10px 0 0;
      & .MuiIconButton-root {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      & > .MuiCheckbox-root {
        padding: 0;
      }
      & svg {
        font-size: 16px;
      }
      & > .MuiTypography-root {
        font-size: 12px;
        font-weight: 600;
        line-height: 2;
        color: ${({ theme }) => theme.colors.slateBlue};
      }
    }
    .avatar {
      width: 60px;
      height: 60px;
    }
    .badge {
      & > .MuiBadge-badge {
        width: 12px;
        height: 12px;
        border: solid 2px ${colors.card};
        background-color: ${({ theme }) => theme.colors.lightGreyBlue};
        border-radius: 50%;
      }
      &.active {
        & > .MuiBadge-badge {
          background-color: #19c4ac;
        }
      }
    }
    .row {
      display: flex;
      align-items: center;
    }
    .relative {
      position: relative;
    }
    .cell {
      &:nth-child(5) {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .cellWidth {
      &:first-child {
        width: 75px !important;
      }
      &:nth-child(2) {
        width: 19% !important;
      }
      &:nth-child(3) {
        width: 12% !important;
      }
      &:nth-child(4) {
        width: 24% !important;
      }
      &:nth-child(5) {
        width: 24% !important;
      }
      &:nth-child(6) {
        width: 13% !important;
      }
    }
  }
  > div > .header {
    .headerIcon {
      font-size: 18px;
      margin-left: 12px;
      margin-right: 12px;
      color: ${({ theme }) => theme.colors.slateBlue};
    }
    .navLink {
      color: ${({ theme }) => theme.colors.slateBlue};
      font-size: 20px;
    }
  }
  > .pageContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
`;

export default EmployeesPage;
