export type AchievementCancel = () => void;
export type AchievementChange = (id: number) => void;
export type AchievementCreate = (id: number) => void;
export type AchievementDelete = () => void;
export type AchievementGrant = (id: number) => void;

export type AchievementEditClick = (id: string) => void;
export type AchievementGrantClick = (id: string) => void;
export type AchievementNewClick = () => void;

export enum Field {
  animationBase64 = 'animationBase64',
  animationSrc = 'animationSrc',
  category = 'categoryId',
  color = 'colorId',
  description = 'description',
  id = 'id',
  imageBase64 = 'imageBase64',
  imageSrc = 'imageSrc',
  isActive = 'isActive',
  message = 'message',
  name = 'name',
  weight = 'weightId',
}

export interface Fields {
  [Field.animationBase64]: string;
  [Field.animationSrc]: string;
  [Field.category]: number;
  [Field.color]: string;
  [Field.description]: string;
  [Field.id]: number;
  [Field.imageBase64]: string;
  [Field.imageSrc]: string;
  [Field.isActive]: boolean;
  [Field.message]: string;
  [Field.name]: string;
  [Field.weight]: string;
}
