import Button from '@material-ui/core/Button';
import plural from 'plural-ru';
import React, { FC, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import styled from 'styled-components/macro';
import {
  AchievementOrderBy,
  OrderDir,
} from '../../../../../../_graphql/schema';
import Paginator from '../../../../../_shared/Paginator';
import QueryView from '../../../../../_shared/QueryView';
import SearchBar from '../../../../../_shared/SearchBar';
import { defaultParamsType } from '../index';
import { GetEmployeeNotAssignedListGrantQuery } from '../queries';
import GrantPlayerAchievementsTable from './GrantPlayerAchievementsTable';
import { QueryResult } from '@apollo/react-common';

const searchBySource = [
  {
    label: 'Название',
    value: 'name',
  },
  {
    label: 'Вес',
    value: 'weight',
  },
  {
    label: 'Категория',
    value: 'category',
  },
  {
    label: 'ID',
    value: 'id',
  },
];

const GrantPlayerAchievementsForm: FC<{
  showSelectAllMenuItem?: boolean;
  searchResult: QueryResult<GetEmployeeNotAssignedListGrantQuery>;
  searchParams: defaultParamsType;
  setSearchParams: (p: defaultParamsType) => void;
  onCancelButtonClick: () => void;
  onSubmitButtonClick: (p: {
    excluded: number[];
    isAllSelect: boolean;
    selected: number[];
  }) => void;
  showSelectAllOnPageMenuItem: boolean;
}> = ({
  showSelectAllMenuItem = false,
  showSelectAllOnPageMenuItem = false,
  searchResult,
  searchParams,
  setSearchParams,
  onSubmitButtonClick,
  onCancelButtonClick,
}) => {
  const [selected, setSelected] = useState<number[]>([]);
  const [excluded, setExcluded] = useState<number[]>([]);

  const [isAllSelect, setIsAllSelect] = useState(false);
  const [isHeadChecked, setIsHeadChecked] = useState(false);
  const [isHeadIndeterminate, setIsHeadIndeterminate] = useState(false);

  const selectedLength = selected.length;
  const excludedLength = excluded.length;

  const isRowSelected = (id: number) =>
    selected.indexOf(id) !== -1 || (isAllSelect && excluded.indexOf(id) === -1);

  const onSelectAllClick = (checked: boolean) => {
    setSelected([]);
    setExcluded([]);
    setIsAllSelect(checked);
  };

  const onSelectAllOnPageClick = () => {
    const itemIds:
      | number[]
      | undefined = searchResult.data?.employee.notAssigned.items.map(
      (i: { id: number }) => i.id
    );

    if (!isAllSelect) {
      if (itemIds?.every((id) => selected.includes(id as number))) {
        setSelected(selected.filter((id: number) => !itemIds.includes(id)));
      } else {
        if (itemIds) {
          setSelected(
            selected
              .filter((id: number): boolean => !itemIds?.includes(id))
              .concat(itemIds)
          );
        }
      }
    } else {
      if (itemIds?.every((id) => excluded.includes(id as number))) {
        setExcluded(excluded.filter((id: number) => !itemIds?.includes(id)));
      } else {
        if (itemIds) {
          setExcluded(
            excluded.filter((id) => !itemIds?.includes(id)).concat(itemIds)
          );
        }
      }
    }
  };

  const setSelectedArray = (arr: number[], id: number) => {
    const idx = arr.indexOf(id);
    switch (true) {
      case idx === -1:
        return [...arr, id];
      case idx === 0:
        return [...arr.slice(1)];
      case idx === arr.length - 1:
        return [...arr.slice(0, -1)];
      default:
        return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
    }
  };

  const onRowClick = (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number
  ) => {
    if (!isAllSelect) {
      setSelected(setSelectedArray(selected, id));
    } else {
      setExcluded(setSelectedArray(excluded, id));
    }
  };

  const onRequestSort = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    property: AchievementOrderBy
  ) => {
    setSearchParams({
      ...searchParams,
      orderDir:
        searchParams.orderBy === property &&
        searchParams.orderDir === OrderDir.Asc
          ? OrderDir.Desc
          : OrderDir.Asc,
      orderBy: property,
    });
  };

  /** Footer Кнопки Отмена и Выдать*/
  const onPageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchParams({
      ...searchParams,
      skip: page * searchParams.take,
    });
  };

  const onSubmitButtonClickFunction = () => {
    onSubmitButtonClick({ isAllSelect, selected, excluded });
  };
  const onCancelButtonClickFunction = () => {
    onCancelButtonClick();
  };

  const [totalSelected, setTotalSelected] = useState(0);

  const onSearchValueChange = (string: string) => {
    setSelected([]);
    setExcluded([]);

    setSearchParams({
      ...searchParams,
      searchValue: string,
      skip: 0,
    });
    setIsAllSelect(false);
  };
  const onSearchFieldChange = (field: string) => {
    setExcluded([]);
    setSearchParams({
      ...searchParams,
      searchField: field,
      skip: 0,
    });
    setIsAllSelect(false);
    setSelected([]);
  };
  return (
    <>
      <QueryView
        result={searchResult}
        renderData={(data, result) => {
          if (
            (selectedLength &&
              selectedLength === data.employee.notAssigned.total) ||
            (isAllSelect && excludedLength === 0)
          ) {
            setIsHeadChecked(true);
          } else {
            setIsHeadChecked(false);
          }
          if (
            (data.employee.notAssigned.total &&
              selectedLength &&
              selectedLength < data.employee.notAssigned.total) ||
            (data.employee.notAssigned.total &&
              isAllSelect &&
              excludedLength &&
              excludedLength < data.employee.notAssigned.total)
          ) {
            setIsHeadIndeterminate(true);
          } else {
            setIsHeadIndeterminate(false);
          }
          setTotalSelected(
            isAllSelect && data.employee.notAssigned.total
              ? data.employee.notAssigned.total - excludedLength
              : selectedLength
          );
          return (
            <>
              <SearchGroup>
                <div className="row">
                  <SearchBar
                    placeholder={'Поиск достижений...'}
                    className={'searchBar'}
                    searchBySource={searchBySource}
                    onSearchValueChange={onSearchValueChange}
                    onSearchFieldChange={onSearchFieldChange}
                  />
                </div>
              </SearchGroup>
              <Selected>
                <div>
                  {totalSelected} из{' '}
                  {data.employee.notAssigned.total &&
                    plural(
                      data.employee.notAssigned.total,
                      '%d достижения',
                      '%d достижений',
                      '%d достижений'
                    )}
                </div>
              </Selected>
              <GrantPlayerAchievementsTable
                order={searchParams.orderDir.toLowerCase()}
                orderBy={searchParams.orderBy}
                searchValue={searchParams.searchValue}
                searchField={searchParams.searchField}
                loading={result.loading}
                isHeadChecked={isHeadChecked}
                isHeadIndeterminate={isHeadIndeterminate}
                selected={selected}
                total={data.employee.notAssigned.total}
                rows={data.employee.notAssigned.items}
                isSelected={isRowSelected}
                onSelectAllClick={onSelectAllClick}
                onSelectAllOnPageClick={onSelectAllOnPageClick}
                onRowClick={onRowClick}
                onRequestSort={onRequestSort}
                showSelectAllMenuItem={showSelectAllMenuItem}
                showSelectAllOnPageMenuItem={showSelectAllOnPageMenuItem}
              />
              <Footer>
                <Paginator
                  count={data.employee.notAssigned.total}
                  perPage={searchParams.take}
                  page={Math.ceil(searchParams.skip / searchParams.take)}
                  onChangePage={onPageChange}
                />
                <div className="actions">
                  <Button
                    className="cancelButton"
                    variant="text"
                    color="primary"
                    disableElevation
                    type="button"
                    onClick={onCancelButtonClickFunction}
                  >
                    Отмена
                  </Button>
                  <Button
                    className="saveButton"
                    variant={totalSelected ? 'contained' : 'text'}
                    color="primary"
                    disableElevation
                    type="submit"
                    disabled={totalSelected === 0}
                    onClick={onSubmitButtonClickFunction}
                  >
                    Добавить{' '}
                    {totalSelected > 0 &&
                      plural(
                        totalSelected,
                        '%d достижение',
                        '%d достижения',
                        '%d достижений'
                      )}
                  </Button>
                </div>
              </Footer>
            </>
          );
        }}
      />
    </>
  );
};

const SearchGroup = styled.div`
  .searchGroup {
    display: flex;
    .searchBar {
      max-width: 55%;
      flex-grow: 1;
      margin-right: 16px;
      &.no-margin {
        margin-right: 0;
      }
    }
    .uploadButton {
      margin-left: auto;
      width: 208px;
    }
    .selectedCsv {
      margin-left: auto;
      justify-content: flex-end;
      align-items: center;
      display: flex;
      width: 208px;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.71;
      color: ${({ theme }) => theme.colors.white};
      & > button {
        color: ${({ theme }) => theme.colors.slateBlue};
      }
    }
    .row {
      display: flex;
      align-items: center;
    }
  }
`;

const Footer = styled.div`
  margin-top: auto;
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  .actions {
    display: flex;
    margin-left: auto;
  }
  .saveButton {
    height: 36px;
    min-width: 208px;
    border-radius: 18px;
  }
  .cancelButton {
    height: 36px;
    width: 160px;
    border-radius: 18px;
  }
`;

const Selected = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
  & > div {
    display: flex;
    margin-left: auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.slateBlue};
  }
`;

export default GrantPlayerAchievementsForm;
