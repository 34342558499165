import { Dispatch, useEffect, useState } from 'react';

export function useRemoteState<T>(
  remoteState: T
): [T, Dispatch<T | undefined>] {
  const [state, setState] = useState(remoteState);
  const [localState, setLocalState] = useState<T | undefined>();
  useEffect(() => {
    setState(remoteState);
  }, [remoteState]);
  useEffect(() => {
    if (localState === undefined) setState(remoteState);
    else setState(localState);
  }, [remoteState, localState]);
  return [state, setLocalState];
}
