import TextField from '@material-ui/core/TextField';
import Autocomplete, {
  createFilterOptions,
  RenderInputParams,
} from '@material-ui/lab/Autocomplete';
import { useField } from 'formik';
import React, { FC } from 'react';
import { SelectAutocompleteProps } from './types';

export const SelectAutocomplete: FC<SelectAutocompleteProps> = ({
  name,
  label,
  optionList,
}) => {
  const [{ value, onBlur }, { error, touched }, { setValue }] = useField<
    number
  >(name);

  const categoryOption =
    optionList.find((option) => option.id === value) || null;

  const shouldMarkError = !!(error && touched);

  const handleChange = (
    _e: React.ChangeEvent<{}>,
    value: typeof categoryOption
  ) => {
    setValue(value ? value.id : 0);
  };

  const AutocompleteTextField = (params: RenderInputParams) => (
    <TextField
      {...params}
      error={shouldMarkError}
      fullWidth
      helperText={shouldMarkError ? error : ``}
      label={label}
      variant="outlined"
    />
  );

  return (
    <Autocomplete
      className="category"
      disableListWrap
      filterOptions={createFilterOptions({
        stringify: ({ name }) => name,
      })}
      disableClearable={true}
      options={optionList}
      getOptionLabel={({ name }) => name}
      getOptionSelected={(option, value) => option.id === value.id}
      value={categoryOption}
      onBlur={onBlur}
      onChange={handleChange}
      renderInput={AutocompleteTextField}
    />
  );
};
