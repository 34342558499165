import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory, useParams, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import AchievementsIcon from '../../../../icons/AchievementsIcon';
import ChevronRightIcon from '../../../../icons/ChevronRightIcon';
import ManagersIcon from '../../../../icons/ManagersIcon';
import PlayersIcon from '../../../../icons/PlayersIcon';
import SettingsIcon from '../../../../icons/SettingsIcon';
import { StyledFC } from '../../../../_shared/types';
import { useGetProjectNameQuery } from './queries';

const groups = [
  [
    // {
    //     label: "Обзор проекта",
    //     path: '/dashboard',
    //     icon: <DashboardIcon/>,
    //             forAdmin:true
    // },
    {
      label: 'Игроки',
      path: '/players',
      icon: <PlayersIcon />,
      forAdmin: false,
    },
    {
      label: 'Администраторы',
      path: '/managers',
      icon: <ManagersIcon />,
      forAdmin: true,
    },
  ],
  [
    {
      label: 'Достижения',
      path: '/awards',
      icon: <AchievementsIcon />,
      forAdmin: false,
    },
    // {
    //     label: "Очки",
    //     path: '/scores',
    //     icon: <ScoresIcon/>,
    //             forAdmin:true
    // },
    // {
    //     label: "Рейтинги",
    //     path: '/ratings',
    //     icon: <RatingsIcon/>,
    //             forAdmin:true
    // },
    // {
    //     label: "Навыки",
    //     path: '/skills',
    //     icon: <SkillsIcon/>,
    //             forAdmin:true
    // },
  ],
  [
    // {
    //     label: "История",
    //     path: '/history',
    //     icon: <HistoryIcon/>,
    //             forAdmin:true
    // },
    {
      label: 'Настройки',
      path: '/settings',
      icon: <SettingsIcon />,
      forAdmin: true,
    },
  ],
];

interface Props {
  theme: string;
  props?: { location: { pathname: string } };
}

const AppSidebar_: StyledFC<Props> = ({ className, theme, ...props }) => {
  const history = useHistory();
  const match = useRouteMatch();
  const { projectId } = useParams();

  const { isAdmin } = useSelector<
    {
      user: {
        employee: number;
      };
    },
    { isAdmin: boolean }
  >((state) => ({
    isAdmin: !state.user.employee,
  }));

  const { data } = useGetProjectNameQuery({ variables: { id: +projectId } });
  const projectName = data ? data.project.name : '';

  const onBackToProjects = () => history.push(`/projects`);

  return (
    <>
      <div className={className}>
        <div className="toolbar">
          <img
            className="logo"
            src="/assets/images/logo.svg"
            alt="logo"
            onClick={onBackToProjects}
          />
          <ChevronRightIcon className="icon" onClick={onBackToProjects} />
          <div className="title">{projectName}</div>
        </div>

        {groups.map((group, idx) => (
          <Fragment key={idx}>
            {idx > 0 && <Divider className="divider" />}
            <List className="list" component="ul">
              {group.map((link) => {
                const isShow = (link.forAdmin && isAdmin) || !link.forAdmin;
                return (
                  isShow && (
                    <ListItem
                      button
                      selected={props.props?.location.pathname.includes(
                        `${match.url}${link.path}`
                      )}
                      key={link.path}
                      className="listItem"
                      component={Link}
                      to={`${match.url}${link.path}`}
                    >
                      <ListItemIcon>{link.icon}</ListItemIcon>

                      <ListItemText
                        className="list-item-text"
                        primary={link.label}
                      />
                    </ListItem>
                  )
                );
              })}
            </List>
          </Fragment>
        ))}
      </div>
    </>
  );
};

const AppSidebar = styled(AppSidebar_)`
  > .divider {
    margin: 0 8px;
  }
  > .list {
    padding-top: 24px;
    padding-bottom: 24px;
    .listItem {
      color: ${({ theme }) => theme.colors.lightGreyBlue};
      padding-left: 26px;
      & ~ .listItem {
        margin-top: 16px;
      }
    }
  }
  .toolbar {
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 1;
    cursor: pointer;
    & > .logo {
      width: 28px;
      height: 28px;
      flex-shrink: 0;
      margin-left: 26px;
      cursor: pointer;
      //flex: 1;
    }
    & > .icon {
      font-size: 18px;
      padding-left: 12px;
      padding-right: 12px;
      color: ${({ theme }) => theme.colors.slateBlue};
      flex: 1;
      cursor: pointer;
    }
    & > .title {
      font-size: 11px;
      line-height: 1.27;
      color: ${({ theme }) => theme.colors.lightGreyBlue};
      flex: 3;
      cursor: default;
    }
  }
  @media (max-width: 897px) {
    .list-item-text,
    .icon,
    .title {
      display: none;
    }
    .toolbar {
      .logo {
        margin-left: 0;
      }
    }
  }
`;

export default AppSidebar;
