import * as Types from '../../../../../../_graphql/schema';

export type CategoryCancelType = () => void;
export type CategoryEditType = (id: number) => void;
export type CategoryCreateType = (id: number) => void;
export type CategoryDeleteType = () => void;

export type CategoryEditClickType = (id: string) => void;
export type CategoryNewClickType = () => void;

export type SubdivisionType = Pick<Types.Subdivision, 'id' | 'name'>;

export enum Field {
  id = 'id',
  isActive = 'isActive',
  name = 'name',
  subdivisions = 'subdivisions',
}

export interface Fields {
  [Field.id]: number;
  [Field.isActive]: boolean;
  [Field.name]: string;
  [Field.subdivisions]: SubdivisionType[];
}
