import React, { Component } from 'react';
import { theme } from '../../_styled/theme';
//import { useDispatch } from 'react-redux';
//import { ActionType } from '../../_constants';
/*import { bindActionCreators, compose, Dispatch, AnyAction } from 'redux';
//import { toastsActions } from '../../_actions';
//import { connect } from 'react-redux';*/

/*const ErrorBoundary: FC<{ children: any }> = ({ children }) => {
  const dispatch = useDispatch();
  try {
    return children;
  } catch (error) {
    console.log(error);
    return <div>Error!!!!!!!! {error}</div>;
    /!*dispatch({
      type: ActionType.ADD_TOAST,
      payload: {
        text: `Ошибка ${error}`,
        className: 'error',
      },
    });*!/
  }
};
export default ErrorBoundary;*/
class ErrorBoundary extends Component {
  state = {
    hasError: false,
    error: { message: '', stack: '' },
    errorInfo: { componentStack: '' },
  };

  componentDidCatch(error: Error, errorInfo: { componentStack: string }) {
    this.setState({
      hasError: true,
      error,
      errorInfo,
    });
  }

  render() {
    const { children } = this.props;
    const { hasError } = this.state;
    const { error } = this.state;
    const { errorInfo } = this.state;
    if (hasError) {
      console.log(this.props);
      return (
        <div
          style={{
            padding: '1rem',
            color: `${theme.colors.white}`,
            backgroundColor: `${theme.colors.slateBlue}`,
          }}
        >
          <h3>Error message:</h3>
          <div> {error.message}</div>
          <h3>Error stack: </h3>
          <div>{error.stack}</div>
          <h3>Component stack: </h3>
          <div>{errorInfo.componentStack}</div>
          <hr />
          <i>Handled by ErrorBoundary</i>
        </div>
      );

      /*
        //@ts-ignore
        this.props.showToast({
          text: `Ошибка ${this.state.error.message}`,
          className: 'error',
        })
      */
    }
    return children;
  }
}

export default ErrorBoundary;
