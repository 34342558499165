import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import Edit from './Edit';
import Main from './Main';
import New from './New';
import {
  CategoryCancelType,
  CategoryCreateType,
  CategoryDeleteType,
  CategoryEditClickType,
  CategoryEditType,
  CategoryNewClickType,
} from './_shared/types';

const CategoriesPage: FC = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const [highlightedId, setHighlightedId] = useState<number | undefined>();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHighlightedId(undefined);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [highlightedId]);

  const handleCancel: CategoryCancelType = () => {
    history.push(url);
  };
  const handleEdit: CategoryEditType = (id) => {
    history.push(url);
    setHighlightedId(id);
  };
  const handleCreate: CategoryCreateType = (id) => {
    history.push(url);
    setHighlightedId(id);
  };
  const handleDelete: CategoryDeleteType = () => {
    history.push(url);
  };

  const handleEditClick: CategoryEditClickType = (id) => {
    history.push(`${url}/${id}`);
  };
  const handleNewClick: CategoryNewClickType = () => {
    history.push(`${url}/new`);
  };

  return (
    <Switch>
      <Route
        path={`${path}/:categoryId(\\d+)`}
        render={() => (
          <PageContainer>
            <Edit
              onCancel={handleCancel}
              onDelete={handleDelete}
              onEdit={handleEdit}
            />
          </PageContainer>
        )}
      />
      <Route
        path={`${path}/new`}
        render={() => (
          <PageContainer>
            <New onCancel={handleCancel} onCreate={handleCreate} />
          </PageContainer>
        )}
      />
      <Route
        path={path}
        exact
        render={() => (
          <Main
            highlightedId={highlightedId}
            onEditClick={handleEditClick}
            onNewClick={handleNewClick}
          />
        )}
      />
    </Switch>
  );
};

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`;

export default CategoriesPage;
