import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';

interface Props {
  component: React.FunctionComponent<any>;
  path: string;
}

const AdminRoute: FC<Props> = ({ component: Component, ...rest }) => {
  const { isAdmin } = useSelector<
    {
      user: {
        employee: number;
      };
    },
    { isAdmin: boolean }
  >((state) => ({
    isAdmin: !state.user.employee,
  }));

  return (
    <Route
      {...rest}
      render={(props) =>
        isAdmin ? <Component {...props} /> : <Redirect to={{ pathname: '/' }} />
      }
    />
  );
};

export default AdminRoute;
