import Avatar from '@material-ui/core/Avatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { ActionType, colors } from '../../../../_constants';
import DropdownIcon from '../../../icons/DropdownIcon';
import { StyledFC } from '../../../_shared/types';

const UserDropdownMenu_: StyledFC = ({ className }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onCloseClick = () => {
    dispatch({ type: ActionType.LOGOUT });
    handleClose();
  };

  return (
    <>
      <div
        className={className}
        aria-label="account of current user"
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Avatar
          alt="Remy Sharp"
          src="/assets/images/male-default-avatar.svg"
          className="avatar"
        />
        <div className={anchorEl !== null ? 'inverted' : ''}>
          <DropdownIcon htmlColor={colors.slateBlue} />
        </div>
      </div>

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        style={{ marginTop: 8 }}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        elevation={0}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleClose}>Настройки аккаунта</MenuItem>
        <MenuItem onClick={onCloseClick}>Выход</MenuItem>
      </Menu>
    </>
  );
};

const UserDropdownMenu = styled(UserDropdownMenu_)`
  margin-left: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  position: relative;

  .avatar {
    width: 3rem;
    height: 3rem;
  }

  .inverted {
    transform: rotate(180deg);
    transition: all 0.2s;
  }
`;

export default UserDropdownMenu;
