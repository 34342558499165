import * as Types from '../../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetProjectAvailableSubdivisionsQueryVariables = {
  id: Types.Scalars['Int'];
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  name?: Types.Maybe<Types.Scalars['String']>;
  fullName?: Types.Maybe<Types.Scalars['String']>;
  exclude?: Types.Maybe<Array<Types.Scalars['Int']>>;
};


export type GetProjectAvailableSubdivisionsQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { availableSubdivisions: (
      { __typename?: 'SubdivisionPage' }
      & Pick<Types.SubdivisionPage, 'total'>
      & { items: Array<(
        { __typename?: 'Subdivision' }
        & Pick<Types.Subdivision, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type CategoryFieldsFragment = (
  { __typename?: 'Category' }
  & Pick<Types.Category, 'id' | 'isActive' | 'name'>
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id' | 'name'>
  ), subdivisions: (
    { __typename?: 'SubdivisionPage' }
    & { items: Array<(
      { __typename?: 'Subdivision' }
      & Pick<Types.Subdivision, 'id' | 'name'>
    )> }
  ) }
);

export type GetCategoryQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetCategoryQuery = (
  { __typename?: 'Query' }
  & { category: (
    { __typename?: 'Category' }
    & CategoryFieldsFragment
  ) }
);

export type CategoryListFieldsFragment = (
  { __typename?: 'CategoryPage' }
  & Pick<Types.CategoryPage, 'total'>
  & { items: Array<(
    { __typename?: 'Category' }
    & Pick<Types.Category, 'id' | 'achievementsCount' | 'name'>
  )> }
);

export type GetProjectCategoriesQueryVariables = {
  id: Types.Scalars['Int'];
  isActive?: Types.Maybe<Types.Scalars['Boolean']>;
  name?: Types.Maybe<Types.Scalars['String']>;
  orderBy?: Types.Maybe<Types.CategoryOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
};


export type GetProjectCategoriesQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { categories: (
      { __typename?: 'CategoryPage' }
      & Pick<Types.CategoryPage, 'total'>
      & { items: Array<(
        { __typename?: 'Category' }
        & Pick<Types.Category, 'id' | 'achievementsCount' | 'name'>
      )> }
    ) }
  ) }
);

export type CreateCategoryMutationVariables = {
  isActive: Types.Scalars['Boolean'];
  name: Types.Scalars['String'];
  projectId: Types.Scalars['Int'];
  subdivisionIds: Array<Types.Scalars['Int']>;
};


export type CreateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createCategory: (
    { __typename?: 'Category' }
    & Pick<Types.Category, 'id' | 'isActive' | 'name'>
    & { project: (
      { __typename?: 'Project' }
      & Pick<Types.Project, 'id' | 'name'>
    ), subdivisions: (
      { __typename?: 'SubdivisionPage' }
      & Pick<Types.SubdivisionPage, 'total'>
      & { items: Array<(
        { __typename?: 'Subdivision' }
        & Pick<Types.Subdivision, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type UpdateCategoryMutationVariables = {
  id: Types.Scalars['Int'];
  isActive: Types.Scalars['Boolean'];
  name: Types.Scalars['String'];
  subdivisionIds: Array<Types.Scalars['Int']>;
};


export type UpdateCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateCategory: (
    { __typename?: 'Category' }
    & Pick<Types.Category, 'id' | 'isActive' | 'name'>
    & { project: (
      { __typename?: 'Project' }
      & Pick<Types.Project, 'id' | 'name'>
    ), subdivisions: (
      { __typename?: 'SubdivisionPage' }
      & Pick<Types.SubdivisionPage, 'total'>
      & { items: Array<(
        { __typename?: 'Subdivision' }
        & Pick<Types.Subdivision, 'id' | 'name'>
      )> }
    ) }
  ) }
);

export type DeleteCategoryMutationVariables = {
  id: Types.Scalars['Int'];
};


export type DeleteCategoryMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'deleteCategory'>
);

export const CategoryFieldsFragmentDoc = gql`
    fragment categoryFields on Category {
  id
  isActive
  name
  project {
    id
    name
  }
  subdivisions {
    items {
      id
      name
    }
  }
}
    `;
export const CategoryListFieldsFragmentDoc = gql`
    fragment categoryListFields on CategoryPage {
  total
  items {
    id
    achievementsCount
    name
  }
}
    `;
export const GetProjectAvailableSubdivisionsDocument = gql`
    query getProjectAvailableSubdivisions($id: Int!, $skip: Int, $take: Int, $name: String, $fullName: String, $exclude: [Int!]) {
  project(id: $id) {
    id
    availableSubdivisions(skip: $skip, take: $take, name: $name, fullName: $fullName, exclude: $exclude) {
      items {
        id
        name
      }
      total
    }
  }
}
    `;

/**
 * __useGetProjectAvailableSubdivisionsQuery__
 *
 * To run a query within a React component, call `useGetProjectAvailableSubdivisionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectAvailableSubdivisionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectAvailableSubdivisionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      fullName: // value for 'fullName'
 *      exclude: // value for 'exclude'
 *   },
 * });
 */
export function useGetProjectAvailableSubdivisionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectAvailableSubdivisionsQuery, GetProjectAvailableSubdivisionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectAvailableSubdivisionsQuery, GetProjectAvailableSubdivisionsQueryVariables>(GetProjectAvailableSubdivisionsDocument, baseOptions);
      }
export function useGetProjectAvailableSubdivisionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectAvailableSubdivisionsQuery, GetProjectAvailableSubdivisionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectAvailableSubdivisionsQuery, GetProjectAvailableSubdivisionsQueryVariables>(GetProjectAvailableSubdivisionsDocument, baseOptions);
        }
export type GetProjectAvailableSubdivisionsQueryHookResult = ReturnType<typeof useGetProjectAvailableSubdivisionsQuery>;
export type GetProjectAvailableSubdivisionsLazyQueryHookResult = ReturnType<typeof useGetProjectAvailableSubdivisionsLazyQuery>;
export type GetProjectAvailableSubdivisionsQueryResult = ApolloReactCommon.QueryResult<GetProjectAvailableSubdivisionsQuery, GetProjectAvailableSubdivisionsQueryVariables>;
export const GetCategoryDocument = gql`
    query getCategory($id: Int!) {
  category(id: $id) {
    ...categoryFields
  }
}
    ${CategoryFieldsFragmentDoc}`;

/**
 * __useGetCategoryQuery__
 *
 * To run a query within a React component, call `useGetCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCategoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
        return ApolloReactHooks.useQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, baseOptions);
      }
export function useGetCategoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCategoryQuery, GetCategoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetCategoryQuery, GetCategoryQueryVariables>(GetCategoryDocument, baseOptions);
        }
export type GetCategoryQueryHookResult = ReturnType<typeof useGetCategoryQuery>;
export type GetCategoryLazyQueryHookResult = ReturnType<typeof useGetCategoryLazyQuery>;
export type GetCategoryQueryResult = ApolloReactCommon.QueryResult<GetCategoryQuery, GetCategoryQueryVariables>;
export const GetProjectCategoriesDocument = gql`
    query getProjectCategories($id: Int!, $isActive: Boolean, $name: String, $orderBy: CategoryOrderBy, $orderDir: OrderDir, $skip: Int, $take: Int) {
  project(id: $id) {
    id
    categories(isActive: $isActive, name: $name, orderBy: $orderBy, orderDir: $orderDir, skip: $skip, take: $take) {
      total
      items {
        id
        achievementsCount
        name
      }
    }
  }
}
    `;

/**
 * __useGetProjectCategoriesQuery__
 *
 * To run a query within a React component, call `useGetProjectCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProjectCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProjectCategoriesQuery({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *      name: // value for 'name'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetProjectCategoriesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetProjectCategoriesQuery, GetProjectCategoriesQueryVariables>) {
        return ApolloReactHooks.useQuery<GetProjectCategoriesQuery, GetProjectCategoriesQueryVariables>(GetProjectCategoriesDocument, baseOptions);
      }
export function useGetProjectCategoriesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetProjectCategoriesQuery, GetProjectCategoriesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetProjectCategoriesQuery, GetProjectCategoriesQueryVariables>(GetProjectCategoriesDocument, baseOptions);
        }
export type GetProjectCategoriesQueryHookResult = ReturnType<typeof useGetProjectCategoriesQuery>;
export type GetProjectCategoriesLazyQueryHookResult = ReturnType<typeof useGetProjectCategoriesLazyQuery>;
export type GetProjectCategoriesQueryResult = ApolloReactCommon.QueryResult<GetProjectCategoriesQuery, GetProjectCategoriesQueryVariables>;
export const CreateCategoryDocument = gql`
    mutation createCategory($isActive: Boolean!, $name: String!, $projectId: Int!, $subdivisionIds: [Int!]!) {
  createCategory(isActive: $isActive, name: $name, project: $projectId, subdivisions: $subdivisionIds) {
    id
    isActive
    name
    project {
      id
      name
    }
    subdivisions {
      items {
        id
        name
      }
      total
    }
  }
}
    `;

/**
 * __useCreateCategoryMutation__
 *
 * To run a mutation, you first call `useCreateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCategoryMutation, { data, loading, error }] = useCreateCategoryMutation({
 *   variables: {
 *      isActive: // value for 'isActive'
 *      name: // value for 'name'
 *      projectId: // value for 'projectId'
 *      subdivisionIds: // value for 'subdivisionIds'
 *   },
 * });
 */
export function useCreateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCategoryMutation, CreateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCategoryMutation, CreateCategoryMutationVariables>(CreateCategoryDocument, baseOptions);
      }
export type CreateCategoryMutationHookResult = ReturnType<typeof useCreateCategoryMutation>;
export type CreateCategoryMutationResult = ApolloReactCommon.MutationResult<CreateCategoryMutation>;
export type CreateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCategoryMutation, CreateCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation updateCategory($id: Int!, $isActive: Boolean!, $name: String!, $subdivisionIds: [Int!]!) {
  updateCategory(id: $id, isActive: $isActive, name: $name, subdivisions: $subdivisionIds) {
    id
    isActive
    name
    project {
      id
      name
    }
    subdivisions {
      items {
        id
        name
      }
      total
    }
  }
}
    `;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCategoryMutation, { data, loading, error }] = useUpdateCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isActive: // value for 'isActive'
 *      name: // value for 'name'
 *      subdivisionIds: // value for 'subdivisionIds'
 *   },
 * });
 */
export function useUpdateCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, baseOptions);
      }
export type UpdateCategoryMutationHookResult = ReturnType<typeof useUpdateCategoryMutation>;
export type UpdateCategoryMutationResult = ApolloReactCommon.MutationResult<UpdateCategoryMutation>;
export type UpdateCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation deleteCategory($id: Int!) {
  deleteCategory(id: $id)
}
    `;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCategoryMutation, { data, loading, error }] = useDeleteCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCategoryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, baseOptions);
      }
export type DeleteCategoryMutationHookResult = ReturnType<typeof useDeleteCategoryMutation>;
export type DeleteCategoryMutationResult = ApolloReactCommon.MutationResult<DeleteCategoryMutation>;
export type DeleteCategoryMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>;