import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from '../../../../../_constants';
import { exportsService } from '../../../../../_services';
import DownloadIcon from '../../../../icons/DownloadIcon';
import DropdownIcon from '../../../../icons/DropdownIcon';
import { StyledFC } from '../../../../_shared/types';

const Dropdown_: StyledFC = ({ className }) => {
  const { projectId } = useParams();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAchievementsClick = async () => {
    try {
      // TODO export don't work (actually all 3 export functions don't work)
      const res = await exportsService.exportAchievements(projectId);
      console.log(res);
    } catch (error) {
      console.log(error);
    }

    setAnchorEl(null);
  };

  const handleAchievementsImagesClick = async () => {
    try {
      const res = await exportsService.exportAchievementsImages(projectId);
      console.log(res);
    } catch (error) {
      console.log(error);
    }

    setAnchorEl(null);
  };

  const handleAssignmentsClick = async () => {
    try {
      const res = await exportsService.exportAssignments(projectId);
      console.log(res);
    } catch (error) {
      console.log(error);
    }

    setAnchorEl(null);
  };

  return (
    <>
      <div className={className} aria-haspopup="true">
        <Button
          className="download"
          color="primary"
          type="button"
          variant="contained"
          disableElevation
          onClick={handleButtonClick}
        >
          <DownloadIcon />
          <DropdownIcon className="dropdown-icon" />
        </Button>
      </div>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          horizontal: `left`,
          vertical: `bottom`,
        }}
        elevation={0}
        getContentAnchorEl={null}
        style={{ marginTop: 8 }}
        keepMounted
        open={!!anchorEl}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleAssignmentsClick}>
          Скачать CSV (выданные достижения)
        </MenuItem>
        <MenuItem onClick={handleAchievementsClick}>
          Скачать CSV (список достижений)
        </MenuItem>
        <MenuItem onClick={handleAchievementsImagesClick}>
          Скачать изображения
        </MenuItem>
      </Menu>
    </>
  );
};

const Dropdown = styled(Dropdown_)`
  .download {
    width: 80px;
    height: 36px;
    margin-right: 12px;
    padding: 0 16px 0 20px;

    background-color: transparent;
    border: 2px solid #2b3757;
    border-radius: 18px;
  }

  .dropdown-icon {
    width: 20px;
    height: 20px;
    margin-left: 4px;

    fill: ${colors.slateBlue};
  }
`;

export default Dropdown;
