import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionType } from '../../../../../_constants';
import QueryView from '../../../../_shared/QueryView';
import { StyledFC } from '../../../../_shared/types';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import Form from './_shared/Form';
import {
  CategoryFieldsFragment,
  UpdateCategoryMutationVariables,
  useDeleteCategoryMutation,
  useGetCategoryQuery,
  useUpdateCategoryMutation,
} from './_shared/queries';
import {
  CategoryCancelType,
  CategoryDeleteType,
  CategoryEditType,
  Fields,
} from './_shared/types';
import validation from './_shared/validation';

interface Props {
  onCancel: CategoryCancelType;
  onDelete: CategoryDeleteType;
  onEdit: CategoryEditType;
}

const adaptForUpdate = ({
  id,
  isActive,
  name,
  subdivisions,
}: Fields): UpdateCategoryMutationVariables => ({
  id,
  isActive,
  name,
  subdivisionIds: subdivisions.map((item) => item.id),
});

const getInitialValues = ({
  id,
  isActive,
  name,
  subdivisions,
}: CategoryFieldsFragment): Fields => ({
  id,
  isActive,
  name,
  subdivisions: subdivisions.items,
});

const Edit: StyledFC<Props> = ({ onCancel, onDelete, onEdit }) => {
  const dispatch = useDispatch();
  const { categoryId } = useParams<{ categoryId: string }>();
  const [deleteCategoryMutation] = useDeleteCategoryMutation();
  const [updateCategory] = useUpdateCategoryMutation();

  const categoryQueryResult = useGetCategoryQuery({
    variables: { id: +categoryId },
  });

  const deleteCategory = async () => {
    const { data, errors } = await deleteCategoryMutation({
      variables: {
        id: +categoryId,
      },
    });

    if (data) {
      onDelete();
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'success',
          delay: 5000,
          text: `Категория «${categoryQueryResult.data?.category.name}» успешно удалена`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибки удаления ${errors.map(({ message }) => message)}`,
        },
      });
    }
  };

  const handleDelete = () => {
    dispatch({
      type: ActionType.SHOW_CONFIRM_DIALOG,
      payload: {
        title: 'Удалить категорию?',
        message:
          'Это приведет к удалению всех достижений, созданных в категории.',
        applyText: 'Удалить',
        cancelText: 'Отмена',
        onApply: () => deleteCategory(),
      },
    });
  };

  const handleSubmit = async (formData: Fields) => {
    const { data, errors } = await updateCategory({
      variables: adaptForUpdate(formData),
    });

    if (data) {
      onEdit(data.updateCategory.id);
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'success',
          delay: 5000,
          text: `Категория «${data.updateCategory.name}» успешно отредактирована`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибки сохранения ${errors.map(({ message }) => message)}`,
        },
      });
    }
  };

  return (
    <QueryView
      result={categoryQueryResult}
      renderData={(data) => (
        <Page
          noScroll
          header={<AppHeader title={data.category.name} />}
          content={
            <Formik
              initialValues={getInitialValues(data.category)}
              validationSchema={validation}
              onReset={onCancel}
              onSubmit={handleSubmit}
            >
              <Form onDelete={handleDelete} />
            </Formik>
          }
        />
      )}
    />
  );
};

export default Edit;
