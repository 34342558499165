import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components/macro';
import { ActionType } from '../../_constants';
import CrossTrickIcon from '../icons/CrossTrickIcon';
import { DialogType, StyledFC } from '../_shared/types';

const AlertDialog_: StyledFC = ({ className, children }) => {
  const dispatch = useDispatch();
  const { applyText, isOpen, title, message, onApply, onClose } = useSelector<
    {
      dialogs: {
        alert: DialogType;
      };
    },
    DialogType
  >((state) => ({
    applyText: state.dialogs.alert.applyText,
    cancelText: state.dialogs.alert.cancelText,
    isOpen: state.dialogs.alert.isOpen,
    title: state.dialogs.alert.title,
    message: state.dialogs.alert.message,
    onApply: state.dialogs.alert.onApply,
    onClose: state.dialogs.alert.onClose,
  }));

  const onApplyClick = () => {
    onApply();
    dispatch({ type: ActionType.HIDE_ALERT_DIALOG });
  };
  const onCloseClick = () => {
    onClose();
    dispatch({ type: ActionType.HIDE_ALERT_DIALOG });
  };

  return (
    <div className={className}>
      {children}
      <Dialog
        className="dialog"
        open={isOpen}
        onClose={onCloseClick}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <CrossTrickIcon className="addIcon" onClick={onCloseClick} />
        <DialogTitle className="title" id="alert-dialog-title">
          {title}
        </DialogTitle>
        <DialogContent className="content">
          <DialogContentText className="message" id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="buttons">
          <Button className="button" onClick={onApplyClick} variant="outlined">
            {applyText}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const AlertDialog = styled(AlertDialog_)`
  .dialog {
    width: 100vw !important;
    height: 100vh !important;
    -webkit-backdrop-filter: blur(10px) !important;
    backdrop-filter: blur(10px) !important;
    background-color: rgba(23, 31, 47, 0.1) !important;
    & > div > div {
      padding: 36px !important;
      max-width: 100% !important;
      border-radius: 15px !important;

      & > * {
        padding: 0 !important;
      }
    }
    .addIcon {
      position: absolute !important;
      top: 28px !important;
      right: 28px !important;
      color: #aeb3be !important;
      cursor: pointer !important;
    }
    .title {
      text-align: center !important;
      margin-bottom: 22px !important;

      & > h2 {
        font-size: 36px !important;
      }
    }
    .content {
      width: 39.25rem !important;
      .message {
        text-align: center !important;
        font-size: 20px !important;
        font-weight: 300 !important;
        width: 80% !important;
        margin: 0 auto 58px auto !important;
      }
    }
    .buttons {
      justify-content: center !important;
      .button {
        width: 208px !important;
        height: 36px !important;
        border-radius: 25px !important;
        background-color: var(--slate-blue) !important;
        border: none !important;
        transition: 0.3s !important;
        &:hover {
          filter: brightness(80%) !important;
        }
      }
    }
  }
`;

export default AlertDialog;
