import React, { FC } from 'react';
import { Input } from './';
import { InputCustomProps } from './types';

export const InputEmail: FC<InputCustomProps> = ({
  name,
  label = 'Email',
  ...rest
}) => (
  <Input
    autoComplete="email"
    label={label}
    name={name}
    type="email"
    {...rest}
  />
);
