import { ActionType } from '../_constants';

const showConfirmDialog = ({
  title = 'Действие?',
  message = 'Повлечет последствия.',
  applyText = 'Подтвердить',
  cancelText = 'Отмена',
  onApply = () => console.log('onApply'),
  onClose = () => console.log('onClose'),
}) => {
  return {
    type: ActionType.SHOW_CONFIRM_DIALOG,
    payload: {
      title,
      message,
      applyText,
      cancelText,
      onApply,
      onClose,
    },
  };
};
const hideConfirmDialog = () => {
  return {
    type: ActionType.HIDE_CONFIRM_DIALOG,
  };
};
const showAlertDialog = ({
  title = 'Заголовок',
  message = 'Сообщение',
  applyText = 'Продолжить',
  onApply = () => console.log('onApply'),
  onClose = () => console.log('onClose'),
}) => {
  return {
    type: ActionType.SHOW_ALERT_DIALOG,
    payload: {
      title,
      message,
      applyText,
      onApply,
      onClose,
    },
  };
};
const hideAlertDialog = () => {
  return {
    type: ActionType.HIDE_ALERT_DIALOG,
  };
};

export const dialogsActions = {
  showAlertDialog,
  hideAlertDialog,
  showConfirmDialog,
  hideConfirmDialog,
};
