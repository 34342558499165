import { useDebounce } from '@react-hook/debounce';
import plural from 'plural-ru';
import React, { FC, useCallback, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../../../../_constants';
import { EmployeeOrderBy, OrderDir } from '../../../../../_graphql/schema';
import QueryView from '../../../../_shared/QueryView';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import GrantEmployeesForm from '../_shared/GrantEmployeesForm';
import { useAddPlayersMutation, useGetNotPlayersQuery } from './queries';

interface Props {
  projectId: number;
  onClosed: () => void;
  onGranted: () => void;
}

const defaultParams = {
  skip: 0,
  take: 10,
  searchField: 'name',
  searchValue: '',
  orderBy: EmployeeOrderBy.Id,
  orderDir: OrderDir.Asc,
  isActive: true,
};

export type defaultParamsType = typeof defaultParams;

const GrantProjectPlayersPage: FC<Props> = ({
  projectId,
  onClosed,
  onGranted,
}) => {
  const dispatch = useDispatch();
  const showToast = (payload: { text: string; className: string }) =>
    dispatch({ type: ActionType.ADD_TOAST, payload });

  const [addPlayersMutation] = useAddPlayersMutation();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState(defaultParams);

  const queryResult = useGetNotPlayersQuery({
    variables: {
      id: projectId,
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const setSearchParams = useCallback(
    (params: defaultParamsType) => {
      setSearchProps({
        ...searchProps,
        ...params,
      });
      setSearchValue(
        params.searchValue ? params.searchValue : searchProps.searchValue
      );
    },
    [setSearchProps, setSearchValue, searchProps]
  );

  const onCancelButtonClick = () => {
    onClosed();
  };

  const onSubmitButtonClick = async ({
    isAllSelect,
    selected,
    excluded,
  }: {
    isAllSelect: boolean;
    selected: number[];
    excluded: number[];
  }) => {
    const employeeIds = isAllSelect ? excluded : selected;
    const { data, errors } = await addPlayersMutation({
      variables: {
        projectId,
        employeeIds,
      },
    });

    if (errors) {
      showToast({
        text: `Ошибка при создании достижения ${errors.map(
          (error) => error.message
        )}`,
        className: 'error',
      });
    } else if (data) {
      showToast({
        text: `${plural(
          selected.length,
          'Добавлен %d игрок',
          'Добавлено %d игрока',
          'Добавлено %d игроков'
        )}`,
        className: 'success',
      });
      onGranted();
    }
  };

  return (
    <Page
      noScroll
      header={<AppHeader title={'Добавление игроков'} />}
      content={
        <QueryView
          result={queryResult}
          renderData={(data, result) => {
            return (
              <GrantEmployeesForm
                csvLoadDisabled
                showSelectAllMenuItem
                showSelectAllOnPageMenuItem
                onSubmitButtonClick={onSubmitButtonClick}
                onCancelButtonClick={onCancelButtonClick}
                items={data.project.notPlayers.items}
                total={data.project.notPlayers.total}
                loading={result.loading}
                searchParams={searchProps}
                setSearchParams={setSearchParams}
              />
            );
          }}
        />
      }
    />
  );
};

export default GrantProjectPlayersPage;
