import { combineReducers } from 'redux';
import { ActionType } from '../_constants';

const confirm = (
  state = {
    isOpen: false,
    title: 'Действие?',
    message: 'Повлечет последствия.',
    applyText: 'Подтвердить',
    cancelText: 'Отмена',
    onApply: () => console.log('onApply'),
    onClose: () => console.log('onClose'),
  },
  action: {
    type: ActionType.SHOW_CONFIRM_DIALOG | ActionType.HIDE_CONFIRM_DIALOG;
    payload: { isOpen: boolean };
  }
) => {
  switch (action.type) {
    case ActionType.SHOW_CONFIRM_DIALOG:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case ActionType.HIDE_CONFIRM_DIALOG:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
const alert = (
  state = {
    isOpen: false,
    title: 'Действие?',
    message: 'Повлечет последствия.',
    applyText: 'Подтвердить',
    cancelText: 'Отмена',
    onApply: () => console.log('onApply'),
    onClose: () => console.log('onClose'),
  },
  action: {
    type: ActionType.SHOW_ALERT_DIALOG | ActionType.HIDE_ALERT_DIALOG;
    payload: { isOpen: boolean };
  }
) => {
  switch (action.type) {
    case ActionType.SHOW_ALERT_DIALOG:
      return {
        ...state,
        ...action.payload,
        isOpen: true,
      };
    case ActionType.HIDE_ALERT_DIALOG:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
const dialogs = combineReducers({ alert, confirm });
export default dialogs;
