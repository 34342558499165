import { useDebounce } from '@react-hook/debounce';
import { ActionType, colors } from '../../../../../_constants';
import React, { useCallback, useState } from 'react';
import {
  AchievementOrderBy,
  ElementMode,
  OrderDir,
} from '../../../../../_graphql/schema';
import QueryView from '../../../../_shared/QueryView';
import { StyledFC } from '../../../../_shared/types';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import { Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import GrantPlayerAchievementsForm from './GrantPlayerAchievementsForm';
import Badge from '@material-ui/core/Badge/Badge';
import Avatar from '@material-ui/core/Avatar';
import plural from 'plural-ru';
import styled from 'styled-components/macro';
import {
  useAssignToEmployeeMutation,
  useGetEmployeeGrantPlayerQuery,
  useGetEmployeeNotAssignedListGrantQuery,
} from './queries';

const defaultParams = {
  skip: 0,
  take: 10,
  searchField: 'name',
  searchValue: '',
  orderBy: 'name' as AchievementOrderBy,
  orderDir: 'ASC' as OrderDir,
};

export type defaultParamsType = typeof defaultParams;

const GrantPlayerAchievementsPage_: StyledFC<{
  onClosed: () => void;
  onGranted: (filter: any) => void;
  className?: string;
}> = ({ onClosed, onGranted, className }) => {
  const match = useRouteMatch<{ playerId: string; projectId: string }>();
  const playerId = parseInt(match.params.playerId);
  const projectId = parseInt(match.params.projectId);

  const dispatch = useDispatch();
  const showToast = (payload: { text: string; className: string }) =>
    dispatch({ type: ActionType.ADD_TOAST, payload });

  const [assignToEmployeeMutation] = useAssignToEmployeeMutation();
  const result = useGetEmployeeGrantPlayerQuery({
    variables: {
      id: playerId,
    },
  });

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState(defaultParams);

  const queryResult = useGetEmployeeNotAssignedListGrantQuery({
    variables: {
      employeeId: playerId,
      projectId,
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const setSearchParams = useCallback(
    (params: defaultParamsType) => {
      setSearchProps({
        ...searchProps,
        ...params,
      });
      setSearchValue(
        params.searchValue ? params.searchValue : searchProps.searchValue
      );
    },
    [setSearchProps, setSearchValue, searchProps]
  );

  // const onSearchValueChange = (string: string) => {
  //   setSearchValue(string);
  //   // setSelected([]);
  //   // setExcluded([]);
  //   // setSearchParams({
  //   //   ...searchParams,
  //   //   searchValue: string,
  //   //   skip: 0,
  //   // });
  //   // setIsAllSelect(false);
  // };
  // const onSearchFieldChange = (field: string) => {
  //   setSearchProps({
  //     ...searchProps,
  //     searchField: field,
  //   });
  //   // setExcluded([]);
  //   // setSearchParams({
  //   //   ...searchParams,
  //   //   searchField: field,
  //   //   skip: 0,
  //   // });
  //   // setIsAllSelect(false);
  //   // setSelected([]);
  // };

  const onCancelButtonClick = () => {
    onClosed();
  };

  const onSubmitButtonClick = async ({
    isAllSelect,
    selected,
    excluded,
  }: {
    excluded: number[];
    isAllSelect: boolean;
    selected: number[];
  }) => {
    if (result.data) {
      const mode = isAllSelect
        ? excluded.length
          ? ElementMode.Exclude
          : ElementMode.IncludeAll
        : ElementMode.Include;
      const achievementIds = isAllSelect ? excluded : selected;
      const { data, errors } = await assignToEmployeeMutation({
        variables: {
          employeeId: result.data.employee.id,
          achievementIds: achievementIds.length ? achievementIds : undefined,
          mode,
        },
      });
      if (errors) {
        showToast({
          text: `Ошибка выдачи достижений игроку ${errors.map(
            (error) => error.message
          )}`,
          className: 'error',
        });
      } else if (data) {
        if (data.assignToEmployee.failed.length) {
          showToast({
            text: `${plural(
              data.assignToEmployee.failed.length,
              '%d достижение',
              '%d достижения',
              '%d достижений'
            )} не могут быть выданы игроку «${result.data.employee.name}»`,
            className: 'error',
          });
        } else if (data.assignToEmployee.successCount > 0) {
          showToast({
            text: `Игроку «${
              result.data.employee.name
            }» успешно выдано ${plural(
              data.assignToEmployee.successCount,
              '%d достижение',
              '%d достижения',
              '%d достижений'
            )}`,
            className: 'success',
          });
        }

        onGranted(
          achievementIds.filter(
            (id: number) => !data.assignToEmployee.failed.includes(id)
          )
        );
      }
    }
  };

  return (
    <QueryView
      result={result}
      renderData={(data) => {
        return (
          <div className={className}>
            <Page
              noScroll
              header={<AppHeader title={'Выдача достижений'} />}
              content={
                <div className="root">
                  <div className="header">
                    <Badge
                      className={`badge ${
                        data.employee.isActive ? 'active' : ''
                      }`}
                      overlap="circle"
                      badgeContent=" "
                      variant="dot"
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                      }}
                    >
                      <Avatar
                        className="avatar"
                        src={
                          data.employee.avatar
                            ? process.env.REACT_APP_BASE_PATH +
                              data.employee.avatar
                            : '/assets/images/male-default-avatar.svg'
                        }
                      />
                    </Badge>
                    <Typography component="div" className="name">
                      {data.employee.name}
                    </Typography>
                  </div>

                  <GrantPlayerAchievementsForm
                    showSelectAllMenuItem
                    showSelectAllOnPageMenuItem
                    onSubmitButtonClick={onSubmitButtonClick}
                    onCancelButtonClick={onCancelButtonClick}
                    searchResult={queryResult}
                    searchParams={searchProps}
                    setSearchParams={setSearchParams}
                  />
                </div>
              }
            />
          </div>
        );
      }}
    />
  );
};

const GrantPlayerAchievementsPage = styled(GrantPlayerAchievementsPage_)`
  width: 100%;
  height: 100%;
  > div > .scrollbar-container {
    .root {
      display: flex;
      flex-direction: column;
      height: 100%;
      z-index: 3;
      background-color: ${colors.darkBackground};
    }
    .header {
      display: flex;
      flex-direction: column;
      max-width: 200px;
      margin: auto auto 20px;
      .badge {
        margin: auto;
        & > .MuiBadge-badge {
          width: 12px;
          height: 12px;
          border: solid 2px ${colors.card};
          background-color: ${colors.lightGreyBlue};
          border-radius: 50%;
        }
        &.active {
          & > .MuiBadge-badge {
            background-color: ${colors.green};
          }
        }
        .avatar {
          width: 80px;
          height: 80px;
        }
      }
      .name {
        font-size: 20px;
        line-height: 1.2;
        color: ${colors.slateBlue};
        margin-top: 12px;
        margin-left: auto;
        margin-right: auto;
        text-align: center;
      }
    }
  }
`;

export default GrantPlayerAchievementsPage;
