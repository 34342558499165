import * as Types from '../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetAssignmentQueryVariables = {
  employee: Types.Scalars['Int'];
  achievement: Types.Scalars['Int'];
};


export type GetAssignmentQuery = (
  { __typename?: 'Query' }
  & { assignment: (
    { __typename?: 'Assignment' }
    & Pick<Types.Assignment, 'createdAt' | 'updatedAt' | 'description' | 'image'>
    & { achievement: (
      { __typename?: 'Achievement' }
      & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'image'>
      & { category: (
        { __typename?: 'Category' }
        & Pick<Types.Category, 'id' | 'name'>
        & { project: (
          { __typename?: 'Project' }
          & Pick<Types.Project, 'description'>
        ) }
      ) }
    ) }
  ) }
);

export type UpdateAssignmentMutationVariables = {
  employeeId: Types.Scalars['Int'];
  achId: Types.Scalars['Int'];
  newDesc?: Types.Maybe<Types.Scalars['String']>;
  imageBase64?: Types.Maybe<Types.Scalars['String']>;
};


export type UpdateAssignmentMutation = (
  { __typename?: 'Mutation' }
  & { updateAssignment: (
    { __typename?: 'Assignment' }
    & Pick<Types.Assignment, 'description' | 'image'>
  ) }
);

export type GetEmployeeQueryVariables = {
  id: Types.Scalars['Int'];
};


export type GetEmployeeQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'id' | 'isActive' | 'avatar' | 'name' | 'position' | 'email'>
    & { subdivision: (
      { __typename?: 'Subdivision' }
      & Pick<Types.Subdivision, 'fullName'>
    ) }
  ) }
);

export type GetEmployeeAssignedListQueryVariables = {
  employeeId: Types.Scalars['Int'];
  projectId?: Types.Maybe<Types.Scalars['Int']>;
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  orderBy?: Types.Maybe<Types.AssignmentOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
  withImage?: Types.Maybe<Types.Scalars['Boolean']>;
  name?: Types.Maybe<Types.Scalars['String']>;
  achievementId?: Types.Maybe<Types.Scalars['String']>;
  weight?: Types.Maybe<Types.Scalars['String']>;
  category?: Types.Maybe<Types.Scalars['String']>;
};


export type GetEmployeeAssignedListQuery = (
  { __typename?: 'Query' }
  & { employee: (
    { __typename?: 'Employee' }
    & Pick<Types.Employee, 'id'>
    & { assigned: (
      { __typename?: 'AssignmentPage' }
      & Pick<Types.AssignmentPage, 'total'>
      & { items: Array<(
        { __typename?: 'Assignment' }
        & Pick<Types.Assignment, 'createdAt' | 'image'>
        & { achievement: (
          { __typename?: 'Achievement' }
          & Pick<Types.Achievement, 'id' | 'name' | 'description' | 'message' | 'isActive' | 'image' | 'animation'>
          & { category: (
            { __typename?: 'Category' }
            & Pick<Types.Category, 'id' | 'name'>
          ), weight: (
            { __typename?: 'AchievementWeight' }
            & Pick<Types.AchievementWeight, 'id' | 'name' | 'value'>
          ), color: (
            { __typename?: 'AchievementColor' }
            & Pick<Types.AchievementColor, 'id' | 'name' | 'value'>
          ), assignments: (
            { __typename?: 'AssignmentPage' }
            & Pick<Types.AssignmentPage, 'total'>
          ) }
        ) }
      )> }
    ) }
  ) }
);


export const GetAssignmentDocument = gql`
    query getAssignment($employee: Int!, $achievement: Int!) {
  assignment(employee: $employee, achievement: $achievement) {
    achievement {
      id
      name
      description
      image
      category {
        id
        name
        project {
          description
        }
      }
    }
    createdAt
    updatedAt
    description
    image
  }
}
    `;

/**
 * __useGetAssignmentQuery__
 *
 * To run a query within a React component, call `useGetAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAssignmentQuery({
 *   variables: {
 *      employee: // value for 'employee'
 *      achievement: // value for 'achievement'
 *   },
 * });
 */
export function useGetAssignmentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
        return ApolloReactHooks.useQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, baseOptions);
      }
export function useGetAssignmentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAssignmentQuery, GetAssignmentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetAssignmentQuery, GetAssignmentQueryVariables>(GetAssignmentDocument, baseOptions);
        }
export type GetAssignmentQueryHookResult = ReturnType<typeof useGetAssignmentQuery>;
export type GetAssignmentLazyQueryHookResult = ReturnType<typeof useGetAssignmentLazyQuery>;
export type GetAssignmentQueryResult = ApolloReactCommon.QueryResult<GetAssignmentQuery, GetAssignmentQueryVariables>;
export const UpdateAssignmentDocument = gql`
    mutation updateAssignment($employeeId: Int!, $achId: Int!, $newDesc: String, $imageBase64: String) {
  updateAssignment(employee: $employeeId, achievement: $achId, description: $newDesc, image: $imageBase64) {
    description
    image
  }
}
    `;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      achId: // value for 'achId'
 *      newDesc: // value for 'newDesc'
 *      imageBase64: // value for 'imageBase64'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(UpdateAssignmentDocument, baseOptions);
      }
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = ApolloReactCommon.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>;
export const GetEmployeeDocument = gql`
    query getEmployee($id: Int!) {
  employee(id: $id) {
    id
    isActive
    avatar
    name
    position
    email
    subdivision {
      fullName
    }
  }
}
    `;

/**
 * __useGetEmployeeQuery__
 *
 * To run a query within a React component, call `useGetEmployeeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEmployeeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, baseOptions);
      }
export function useGetEmployeeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeQuery, GetEmployeeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeQuery, GetEmployeeQueryVariables>(GetEmployeeDocument, baseOptions);
        }
export type GetEmployeeQueryHookResult = ReturnType<typeof useGetEmployeeQuery>;
export type GetEmployeeLazyQueryHookResult = ReturnType<typeof useGetEmployeeLazyQuery>;
export type GetEmployeeQueryResult = ApolloReactCommon.QueryResult<GetEmployeeQuery, GetEmployeeQueryVariables>;
export const GetEmployeeAssignedListDocument = gql`
    query getEmployeeAssignedList($employeeId: Int!, $projectId: Int, $skip: Int, $take: Int, $orderBy: AssignmentOrderBy, $orderDir: OrderDir, $withImage: Boolean, $name: String, $achievementId: String, $weight: String, $category: String) {
  employee(id: $employeeId) {
    id
    assigned(name: $name, id: $achievementId, weight: $weight, category: $category, project: $projectId, skip: $skip, take: $take, orderBy: $orderBy, orderDir: $orderDir, withImage: $withImage) {
      items {
        achievement {
          id
          name
          description
          message
          isActive
          image
          animation
          category {
            id
            name
          }
          weight {
            id
            name
            value
          }
          color {
            id
            name
            value
          }
          assignments {
            total
          }
        }
        createdAt
        image
      }
      total
    }
  }
}
    `;

/**
 * __useGetEmployeeAssignedListQuery__
 *
 * To run a query within a React component, call `useGetEmployeeAssignedListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmployeeAssignedListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmployeeAssignedListQuery({
 *   variables: {
 *      employeeId: // value for 'employeeId'
 *      projectId: // value for 'projectId'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *      withImage: // value for 'withImage'
 *      name: // value for 'name'
 *      achievementId: // value for 'achievementId'
 *      weight: // value for 'weight'
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetEmployeeAssignedListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetEmployeeAssignedListQuery, GetEmployeeAssignedListQueryVariables>) {
        return ApolloReactHooks.useQuery<GetEmployeeAssignedListQuery, GetEmployeeAssignedListQueryVariables>(GetEmployeeAssignedListDocument, baseOptions);
      }
export function useGetEmployeeAssignedListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEmployeeAssignedListQuery, GetEmployeeAssignedListQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetEmployeeAssignedListQuery, GetEmployeeAssignedListQueryVariables>(GetEmployeeAssignedListDocument, baseOptions);
        }
export type GetEmployeeAssignedListQueryHookResult = ReturnType<typeof useGetEmployeeAssignedListQuery>;
export type GetEmployeeAssignedListLazyQueryHookResult = ReturnType<typeof useGetEmployeeAssignedListLazyQuery>;
export type GetEmployeeAssignedListQueryResult = ApolloReactCommon.QueryResult<GetEmployeeAssignedListQuery, GetEmployeeAssignedListQueryVariables>;