import { Card } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import CardContent from '@material-ui/core/CardContent';
import React, { Fragment, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components/macro';
import ChevronRightIcon from '../../../../icons/ChevronRightIcon';
import QueryView from '../../../../_shared/QueryView';
import { StyledFC } from '../../../../_shared/types';
import { useGetEmployeeQuery } from './queries';

const EmployeeInfo_: StyledFC = ({ className }) => {
  const {
    params: { playerId },
  } = useRouteMatch();

  const [isShowAllSubdivisions, setIsShowAllSubdivisions] = useState(false);

  const showAllSubdivision = () => {
    setIsShowAllSubdivisions(true);
  };

  const result = useGetEmployeeQuery({
    variables: { id: +playerId },
  });

  return (
    <QueryView
      result={result}
      renderData={(data) => {
        const RenderSubdivisions = () => {
          if (!data.employee?.subdivision.fullName) return null;
          const splittedSubdivision = data.employee?.subdivision?.fullName.split(
            '|'
          );
          if (!isShowAllSubdivisions) {
            return (
              <div>
                <div className="label">Подразделение</div>
                <span className="text">
                  {splittedSubdivision ? splittedSubdivision[0] : ''}
                </span>
                <ChevronRightIcon className="icon" />
                &nbsp;
                <span onClick={showAllSubdivision} className="tripleDot">
                  ...
                </span>
                &nbsp;
                <ChevronRightIcon className="icon" />
                <span className="text">
                  {splittedSubdivision ? [splittedSubdivision.length - 1] : ''}
                </span>
              </div>
            );
          } else {
            return (
              <div>
                <div className="label">Подразделение</div>
                {splittedSubdivision.map((subdivision, index) => {
                  return (
                    <Fragment key={subdivision}>
                      <span className="text">{subdivision}</span>
                      {index !== splittedSubdivision.length - 1 && (
                        <ChevronRightIcon className="icon" />
                      )}
                    </Fragment>
                  );
                })}
              </div>
            );
          }
        };

        return (
          <div className={className}>
            <Card elevation={0} className="card">
              <CardContent className="card-content">
                <div className="avatar-wrapper">
                  <Badge
                    className={`'badge' ${
                      data.employee?.isActive ? 'active' : ''
                    }`}
                    overlap="circle"
                    badgeContent=" "
                    variant="dot"
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                  >
                    <Avatar
                      className="avatar"
                      src={
                        data.employee?.avatar
                          ? process.env.REACT_APP_BASE_PATH +
                            data.employee.avatar
                          : '/assets/images/male-default-avatar.svg'
                      }
                    />
                  </Badge>
                </div>

                <div className="name">{data.employee?.name}</div>
                <div className="position">{data.employee?.position}</div>
                <RenderSubdivisions />
                <div>
                  <div className="label">Email</div>
                  <div className="text">{data.employee?.email}</div>
                </div>
                <div>
                  <div className="label">Табельный номер</div>
                  <div className="text">{playerId}</div>
                </div>
              </CardContent>
            </Card>
          </div>
        );
      }}
    />
  );
};
const EmployeeInfo = styled(EmployeeInfo_)`
  padding-top: 70px;
  > .card {
    overflow: visible;
    .card-content {
      padding: 0 40px 68px;
      &.last-child {
        padding-bottom: 68px;
      }
    }
    .avatar-wrapper {
      height: 70px;
      position: relative;
      display: flex;
      justify-content: center;
      .avatar {
        top: -70px;
        width: 140px;
        height: 140px;
      }
    }
    .badge {
      position: absolute;
      & > .MuiBadge-badge {
        width: 20px;
        height: 20px;
        //border: solid 2px theme.colors.card;
        background-color: ${({ theme }) => theme.colors.lightGreyBlue};
        border-radius: 50%;
      }
      &:active {
        & > .MuiBadge-badge {
          background-color: ${({ theme }) => theme.colors.lightSlatePurple};
        }
      }
    }
    .name {
      width: 220px;
      margin-top: 20px;
      margin-left: auto;
      margin-right: auto;
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      color: ${({ theme }) => theme.colors.white};
    }
    .position {
      width: 220px;
      margin: 12px auto 46px;
      font-size: 12px;
      font-weight: 300;
      line-height: 1.42;
      text-align: center;
      color: ${({ theme }) => theme.colors.slateBlue};
    }
    .label {
      margin-top: 14px;
      margin-left: auto;
      margin-right: auto;
      font-size: 12px;
      font-weight: 300;
      line-height: 2;
      color: ${({ theme }) => theme.colors.slateBlue};
    }
    .text {
      font-size: 14px;
      line-height: 1.71;
      color: ${({ theme }) => theme.colors.white};
      /*text-overflow: ellipsis;
      overflow: hidden;*/
      word-wrap: break-word;
    }

    .icon {
      font-size: 12px;
      margin-left: 6px;
      margin-right: 6px;
      color: ${({ theme }) => theme.colors.slateBlue};
    }

    .tripleDot {
      padding: 0 7px;
      background-color: ${({ theme }) => theme.colors.hint};
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: ${({ theme }) => theme.colors.slateBlue};
      }
    }
  }
`;
export default EmployeeInfo;
