import { Formik } from 'formik';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ActionType } from '../../../../../_constants';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import Form from './_shared/Form';
import {
  CreateCategoryMutationVariables,
  useCreateCategoryMutation,
} from './_shared/queries';
import {
  CategoryCancelType,
  CategoryCreateType,
  Field,
  Fields,
} from './_shared/types';
import validation from './_shared/validation';

interface Props {
  onCancel: CategoryCancelType;
  onCreate: CategoryCreateType;
}

interface Params {
  projectId: string;
}

const getEmptyInitialValues = (): Fields => ({
  [Field.id]: 0,
  [Field.isActive]: true,
  [Field.name]: ``,
  [Field.subdivisions]: [],
});

const New: FC<Props> = ({ onCancel, onCreate }) => {
  const dispatch = useDispatch();
  const { projectId } = useParams<Params>();
  const [createCategory] = useCreateCategoryMutation();

  const adaptForCreate = ({
    isActive,
    name,
    subdivisions,
  }: Fields): CreateCategoryMutationVariables => ({
    isActive,
    name,
    projectId: +projectId,
    subdivisionIds: subdivisions.map((item) => item.id),
  });

  const handleSubmit = async (formData: Fields) => {
    const { data, errors } = await createCategory({
      variables: adaptForCreate(formData),
    });

    if (data) {
      onCreate(data.createCategory.id);
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'success',
          delay: 5000,
          text: `Категория «${data.createCategory.name}» создана`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибки при создании категории ${errors.map(
            ({ message }) => message
          )}`,
        },
      });
    }
  };

  return (
    <Page
      noScroll
      header={<AppHeader title="Новая категория" />}
      content={
        <Formik
          initialValues={getEmptyInitialValues()}
          validationSchema={validation}
          onReset={onCancel}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      }
    />
  );
};

export default New;
