import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const ChevronRightIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon viewBox="0 0 8.9 16.4" {...props}>
    <path
      d="M8.207 8.207l-7.5 7.5zm0 0l-7.5-7.5z"
      fill="none"
      fillRule="evenodd"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </SvgIcon>
);
export default ChevronRightIcon;
