import * as Types from '../../../../../_graphql/schema';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';

export type GetManagersQueryVariables = {
  id: Types.Scalars['Int'];
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  isActive?: Types.Maybe<Types.Scalars['Boolean']>;
  orderBy?: Types.Maybe<Types.EmployeeOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
};


export type GetManagersQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { managers: (
      { __typename?: 'EmployeePage' }
      & Pick<Types.EmployeePage, 'total'>
      & { items: Array<(
        { __typename?: 'Employee' }
        & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'rootSubdivisionName' | 'position'>
      )> }
    ) }
  ) }
);

export type AddManagersMutationVariables = {
  project: Types.Scalars['Int'];
  employees: Array<Types.Scalars['Int']>;
};


export type AddManagersMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'addManagers'>
);

export type RemoveManagerMutationVariables = {
  project: Types.Scalars['Int'];
  employee: Types.Scalars['Int'];
};


export type RemoveManagerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Types.Mutation, 'removeManager'>
);

export type GetNotManagersQueryVariables = {
  id: Types.Scalars['Int'];
  skip?: Types.Maybe<Types.Scalars['Int']>;
  take?: Types.Maybe<Types.Scalars['Int']>;
  name?: Types.Maybe<Types.Scalars['String']>;
  isActive?: Types.Maybe<Types.Scalars['Boolean']>;
  orderBy?: Types.Maybe<Types.EmployeeOrderBy>;
  orderDir?: Types.Maybe<Types.OrderDir>;
};


export type GetNotManagersQuery = (
  { __typename?: 'Query' }
  & { project: (
    { __typename?: 'Project' }
    & Pick<Types.Project, 'id'>
    & { notManagers: (
      { __typename?: 'EmployeePage' }
      & Pick<Types.EmployeePage, 'total'>
      & { items: Array<(
        { __typename?: 'Employee' }
        & Pick<Types.Employee, 'id' | 'name' | 'avatar' | 'rootSubdivisionName' | 'position'>
      )> }
    ) }
  ) }
);


export const GetManagersDocument = gql`
    query getManagers($id: Int!, $skip: Int, $take: Int, $isActive: Boolean, $orderBy: EmployeeOrderBy, $orderDir: OrderDir) {
  project(id: $id) {
    id
    managers(skip: $skip, take: $take, isActive: $isActive, orderBy: $orderBy, orderDir: $orderDir) {
      items {
        id
        name
        avatar
        rootSubdivisionName
        position
      }
      total
    }
  }
}
    `;

/**
 * __useGetManagersQuery__
 *
 * To run a query within a React component, call `useGetManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManagersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      isActive: // value for 'isActive'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *   },
 * });
 */
export function useGetManagersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, baseOptions);
      }
export function useGetManagersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetManagersQuery, GetManagersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetManagersQuery, GetManagersQueryVariables>(GetManagersDocument, baseOptions);
        }
export type GetManagersQueryHookResult = ReturnType<typeof useGetManagersQuery>;
export type GetManagersLazyQueryHookResult = ReturnType<typeof useGetManagersLazyQuery>;
export type GetManagersQueryResult = ApolloReactCommon.QueryResult<GetManagersQuery, GetManagersQueryVariables>;
export const AddManagersDocument = gql`
    mutation addManagers($project: Int!, $employees: [Int!]!) {
  addManagers(project: $project, employees: $employees)
}
    `;

/**
 * __useAddManagersMutation__
 *
 * To run a mutation, you first call `useAddManagersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddManagersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addManagersMutation, { data, loading, error }] = useAddManagersMutation({
 *   variables: {
 *      project: // value for 'project'
 *      employees: // value for 'employees'
 *   },
 * });
 */
export function useAddManagersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddManagersMutation, AddManagersMutationVariables>) {
        return ApolloReactHooks.useMutation<AddManagersMutation, AddManagersMutationVariables>(AddManagersDocument, baseOptions);
      }
export type AddManagersMutationHookResult = ReturnType<typeof useAddManagersMutation>;
export type AddManagersMutationResult = ApolloReactCommon.MutationResult<AddManagersMutation>;
export type AddManagersMutationOptions = ApolloReactCommon.BaseMutationOptions<AddManagersMutation, AddManagersMutationVariables>;
export const RemoveManagerDocument = gql`
    mutation removeManager($project: Int!, $employee: Int!) {
  removeManager(project: $project, employee: $employee)
}
    `;

/**
 * __useRemoveManagerMutation__
 *
 * To run a mutation, you first call `useRemoveManagerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveManagerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeManagerMutation, { data, loading, error }] = useRemoveManagerMutation({
 *   variables: {
 *      project: // value for 'project'
 *      employee: // value for 'employee'
 *   },
 * });
 */
export function useRemoveManagerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveManagerMutation, RemoveManagerMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveManagerMutation, RemoveManagerMutationVariables>(RemoveManagerDocument, baseOptions);
      }
export type RemoveManagerMutationHookResult = ReturnType<typeof useRemoveManagerMutation>;
export type RemoveManagerMutationResult = ApolloReactCommon.MutationResult<RemoveManagerMutation>;
export type RemoveManagerMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveManagerMutation, RemoveManagerMutationVariables>;
export const GetNotManagersDocument = gql`
    query getNotManagers($id: Int!, $skip: Int, $take: Int, $name: String, $isActive: Boolean, $orderBy: EmployeeOrderBy, $orderDir: OrderDir) {
  project(id: $id) {
    id
    notManagers(skip: $skip, take: $take, name: $name, isActive: $isActive, orderBy: $orderBy, orderDir: $orderDir) {
      items {
        id
        name
        avatar
        rootSubdivisionName
        position
      }
      total
    }
  }
}
    `;

/**
 * __useGetNotManagersQuery__
 *
 * To run a query within a React component, call `useGetNotManagersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNotManagersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotManagersQuery({
 *   variables: {
 *      id: // value for 'id'
 *      skip: // value for 'skip'
 *      take: // value for 'take'
 *      name: // value for 'name'
 *      isActive: // value for 'isActive'
 *      orderBy: // value for 'orderBy'
 *      orderDir: // value for 'orderDir'
 *   },
 * });
 */
export function useGetNotManagersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNotManagersQuery, GetNotManagersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetNotManagersQuery, GetNotManagersQueryVariables>(GetNotManagersDocument, baseOptions);
      }
export function useGetNotManagersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNotManagersQuery, GetNotManagersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetNotManagersQuery, GetNotManagersQueryVariables>(GetNotManagersDocument, baseOptions);
        }
export type GetNotManagersQueryHookResult = ReturnType<typeof useGetNotManagersQuery>;
export type GetNotManagersLazyQueryHookResult = ReturnType<typeof useGetNotManagersLazyQuery>;
export type GetNotManagersQueryResult = ApolloReactCommon.QueryResult<GetNotManagersQuery, GetNotManagersQueryVariables>;