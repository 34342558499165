import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import React, { ChangeEvent } from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../_constants';
import DropdownIcon from '../icons/DropdownIcon';
import { StyledFC } from './types';

interface Props {
  checked: boolean;
  indeterminate: boolean;
  showSelectAllMenuItem: boolean;
  showSelectAllOnPageMenuItem: boolean;
  onSelectAllClick: (checked: boolean) => void;
  onSelectAllOnPageClick: () => void;
}

const CheckboxDropdownMenu_: StyledFC<Props> = ({
  className,
  checked,
  indeterminate,
  showSelectAllMenuItem = false,
  showSelectAllOnPageMenuItem = false,
  onSelectAllClick,
  onSelectAllOnPageClick,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
  };
  const handleSelectAllClick = (
    event:
      | React.MouseEvent<HTMLLIElement, MouseEvent>
      | ChangeEvent<HTMLInputElement>
  ) => {
    showSelectAllMenuItem || showSelectAllOnPageMenuItem
      ? onSelectAllClick(indeterminate || !checked)
      : // @ts-ignore
        onSelectAllClick(event.target.checked);
    handleClose();
  };
  const handleSelectAllOnPageClick = () => {
    onSelectAllOnPageClick();
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className={className}>
      {showSelectAllMenuItem || showSelectAllOnPageMenuItem ? (
        <>
          <Button
            className={[
              'checkboxWrapper',
              Boolean(anchorEl) ? 'open' : '',
            ].join(' ')}
            aria-haspopup="true"
            onClick={handleClick}
          >
            <Checkbox
              className="checkbox"
              indeterminate={indeterminate}
              checked={checked}
            />
            <DropdownIcon className="dropdownIcon" />
          </Button>
          <Menu
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            PaperProps={{ style: { backgroundColor: colors.hint } }}
            keepMounted
            elevation={0}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {showSelectAllMenuItem && (
              <MenuItem className="menuItem" onClick={handleSelectAllClick}>
                Все
              </MenuItem>
            )}
            {showSelectAllOnPageMenuItem && (
              <MenuItem
                className="menuItem"
                onClick={handleSelectAllOnPageClick}
              >
                Все на странице
              </MenuItem>
            )}
          </Menu>
        </>
      ) : (
        <Checkbox
          className="checkbox"
          indeterminate={indeterminate}
          checked={checked}
          onChange={handleSelectAllClick}
        />
      )}
    </div>
  );
};

const CheckboxDropdownMenu = styled(CheckboxDropdownMenu_)`
  > .checkboxWrapper {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    height: 32px;
    border-radius: 4px;
    padding: 0;
    margin: 0;
    min-width: unset;
    .dropdownIcon {
      font-size: 18px;
      color: ${colors.slateBlue};
    }
  }
  .checkbox {
    padding: 0;
  }
`;

export default CheckboxDropdownMenu;
