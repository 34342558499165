import { useDebounce } from '@react-hook/debounce';
import plural from 'plural-ru';
import React, { FC, useCallback, useState } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';
import { useDispatch } from 'react-redux';
import { ActionType } from '../../../../../_constants';
import { EmployeeOrderBy, OrderDir } from '../../../../../_graphql/schema';
import QueryView from '../../../../_shared/QueryView';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import GrantEmployeesForm from '../_shared/GrantEmployeesForm';

import { useAddManagersMutation, useGetNotManagersQuery } from './queries';

interface Props {
  projectId: number;
  onClosed: () => void;
  onGranted: () => void;
}

const defaultParams = {
  skip: 0,
  take: 10,
  searchField: 'name',
  searchValue: '',
  orderBy: EmployeeOrderBy.Id,
  orderDir: OrderDir.Asc,
  isActive: true,
};

export type defaultParamsType = typeof defaultParams;

const GrantProjectManagersPage: FC<Props> = ({
  projectId,
  onClosed,
  onGranted,
}) => {
  const dispatch = useDispatch();
  const [addManagers] = useAddManagersMutation();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState(defaultParams);

  const queryResult = useGetNotManagersQuery({
    variables: {
      id: +projectId,
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const setSearchParams = useCallback(
    (params: defaultParamsType) => {
      setSearchProps({
        ...searchProps,
        ...params,
      });
      setSearchValue(
        params.searchValue ? params.searchValue : searchProps.searchValue
      );
    },
    [setSearchProps, setSearchValue, searchProps]
  );

  const assign = ({
    isAllSelect,
    selected,
    excluded,
  }: {
    isAllSelect: boolean;
    selected: number[];
    excluded: number[];
  }) => {
    //console.log('assign:', isAllSelect, selected, excluded);
    //const { showToast, projectId, onGranted }: any = this.props;

    const employeeIds = isAllSelect ? excluded : selected;
    /*projectsService
      .projectAddManagers({ projectId, employeeIds })
      .then((data) => console.log({ data }));*/
    addManagers({ variables: { project: projectId, employees: employeeIds } })
      .then(({ data }) => {
        if (data?.addManagers) {
          dispatch({
            type: ActionType.ADD_TOAST,
            payload: {
              text: `${plural(
                selected.length,
                'Добавлен %d администратор',
                'Добавлено %d администратора',
                'Добавлено %d администраторов'
              )}`,
              className: 'success',
              delay: 5000,
            },
          });
          onGranted();
        } else {
          dispatch({
            type: ActionType.ADD_TOAST,
            payload: {
              text: `TODO: неудача ${data}`,
              className: 'error',
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            text: `Ошибка добавления администраторов ${error.message}`,
            className: 'error',
          },
        });
      });
  };

  const onCancelButtonClick = () => {
    //const { onClosed }: any = this.props;
    onClosed();
  };

  const onSubmitButtonClick = ({
    isAllSelect,
    selected,
    excluded,
  }: {
    isAllSelect: boolean;
    selected: number[];
    excluded: number[];
  }) => {
    assign({ isAllSelect, selected, excluded });
  };

  return (
    <Page
      noScroll
      header={<AppHeader title={'Добавление администраторов'} />}
      content={
        <QueryView
          result={queryResult}
          renderData={(data, result) => {
            return (
              <GrantEmployeesForm
                csvLoadDisabled
                showSelectAllOnPageMenuItem
                onSubmitButtonClick={onSubmitButtonClick}
                onCancelButtonClick={onCancelButtonClick}
                items={data.project.notManagers.items}
                total={data.project.notManagers.total}
                loading={result.loading}
                searchParams={searchProps}
                setSearchParams={setSearchParams}
              />
            );
          }}
        />
      }
    />
  );
};

export default GrantProjectManagersPage;
