import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { useDebounce } from '@react-hook/debounce';
import React, { useState } from 'react';
import {
  NavLink,
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { colors } from '../../../../../_constants';
import { EmployeeOrderBy, OrderDir } from '../../../../../_graphql/schema';
import HighlightedText from '../../../../_shared/HighlightedText';
import Paginator from '../../../../_shared/Paginator';
import QueryView from '../../../../_shared/QueryView';
import SearchBar from '../../../../_shared/SearchBar';
import SharedTable from '../../../../_shared/SharedTable';
import { HeadCell, StyledFC } from '../../../../_shared/types';
import Loader from '../../_shared/Loader';
import Page from '../../_shared/Page';
import PlayerPage from '../../_shared/PlayerPage';
import AppHeader from '../_shared/AppHeader';
import GrantProjectPlayersPage from './GrantProjectPlayersPage';
import { useGetPlayersQuery } from './queries';

const searchBySource = [
  {
    label: 'ФИО сотрудника',
    value: 'name',
  },
  {
    label: 'Подразделение',
    value: 'rootSubdivisionName',
  },
  {
    label: 'Должность',
    value: 'position',
  },
  {
    label: 'Табельный номер',
    value: 'playerId',
  },
];

const headCells: HeadCell<EmployeeOrderBy>[] = [
  {
    id: 'image',
    numeric: false,
    disablePadding: false,
    label: '',
    sortable: false,
  },
  {
    id: 'name',
    numeric: true,
    disablePadding: false,
    label: 'Имя',
    orderBy: EmployeeOrderBy.Name,
  },
  {
    id: 'rootSubdivisionName',
    numeric: true,
    disablePadding: false,
    label: 'Подразделение',
    orderBy: EmployeeOrderBy.RootSubdivisionName,
  },
  {
    id: 'position',
    numeric: true,
    disablePadding: false,
    label: 'Должность',
    orderBy: EmployeeOrderBy.Position,
  },
  {
    id: 'id',
    numeric: true,
    disablePadding: false,
    label: 'Табельный номер',
    orderBy: EmployeeOrderBy.Id,
  },
];

const PlayersPage_: StyledFC = ({ className }) => {
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);
  const { path, url } = match;
  const history = useHistory();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState({
    skip: 0,
    take: 10,
    searchField: 'name',
    orderBy: EmployeeOrderBy.Name,
    orderDir: OrderDir.Asc,
    isActive: true,
    withoutAchievements: [],
  });

  const result = useGetPlayersQuery({
    variables: {
      id: projectId,
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const onAddPlayerClick = () => {
    history.push(`players/grant`);
  };

  const onPlayerRowClick = (
    player: { __typename?: 'Employee' | undefined } & Pick<
      import('../../../../../_graphql/schema').Employee,
      'id' | 'name' | 'avatar' | 'rootSubdivisionName' | 'position' | 'isActive'
    >
  ) => {
    history.push(`players/${player.id}`);
  };

  // onDeletePlayerClick = (player) => {
  //     this.props.history.push(`players/${player.id}/delete`)
  // };

  const onRequestSort = (orderBy: EmployeeOrderBy, orderDir: OrderDir) => {
    setSearchProps({
      ...searchProps,
      orderBy: orderBy,
      orderDir: orderDir,
    });
  };

  const onPlayerGranted = async () => {
    history.push(`/projects/${projectId}/players`);
    await result.refetch();
  };

  const onGrantProjectPlayerClosed = () => {
    history.push(`/projects/${projectId}/players`);
  };

  /*const onAddAchievementClick = () => {
    //const { history } = this.props;
    history.push(`/achievements/give`);
  };*/

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const onSearchFieldChange = (field: string) => {
    setSearchProps({
      ...searchProps,
      searchField: field,
    });
  };

  const onPageChange = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchProps({
      ...searchProps,
      skip: page * searchProps.take,
    });
  };

  const onIsActiveChange = () => {
    setSearchProps({
      ...searchProps,
      isActive: !searchProps.isActive,
      skip: 0,
    });
  };

  const headerTitle = (
    <span style={{ display: 'flex', alignItems: 'center' }}>
      <NavLink className="navLink" to="players">
        Игроки
      </NavLink>
    </span>
  );

  return (
    <div className={className}>
      {' '}
      <Page
        routeEndsWith="/players"
        // path={path}
        noScroll
        header={<AppHeader title={headerTitle} />}
        content={
          <>
            <div>
              <div className="buttonsWrapper">
                <SearchBar
                  searchBySource={searchBySource}
                  placeholder={`Поиск игроков...`}
                  onSearchValueChange={onSearchValueChange}
                  onSearchFieldChange={onSearchFieldChange}
                  // minSelectWidth={242}
                  // classString="searchBar"
                  selectWidth={240}
                  // width={70}
                />
                <Button
                  className="addPlayerButton"
                  variant="contained"
                  color="primary"
                  disableElevation
                  type="submit"
                  onClick={() => onAddPlayerClick()}
                >
                  Добавить
                </Button>
              </div>
              <div className="row" style={{ marginBottom: 24 }}>
                <FormControlLabel
                  className="isActiveCheckbox"
                  control={
                    <Checkbox
                      checked={searchProps.isActive}
                      onChange={onIsActiveChange}
                      color="primary"
                    />
                  }
                  label="Действующий сотрудник"
                />
              </div>
            </div>
            <QueryView
              result={result}
              renderData={(data, result) => {
                const Rows = () => {
                  const players = data.project.players.items;
                  const searchString = searchValue;
                  const searchField = searchProps.searchField;
                  return players.map((row) => {
                    return (
                      <TableRow
                        //className={highlightPlayer?.id === row.id ? 'highlighted' : ''}
                        hover
                        tabIndex={-1}
                        key={row.id}
                        onClick={() => onPlayerRowClick(row)}
                        style={{ height: row.avatar ? 92 : 72 }}
                      >
                        <TableCell align="left" className="cell cellWidth">
                          <Badge
                            className={`badge ${row.isActive ? 'active' : ''}`}
                            overlap="circle"
                            badgeContent=" "
                            variant="dot"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                          >
                            <Avatar
                              className="avatar"
                              src={
                                row.avatar
                                  ? row.avatar
                                  : '/assets/images/male-default-avatar.svg'
                              }
                            />
                          </Badge>
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.name}
                            searchValue={searchString}
                            skip={searchField !== 'name'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.rootSubdivisionName}
                            searchValue={searchString}
                            skip={searchField !== 'rootSubdivisionName'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.position}
                            searchValue={searchString}
                            skip={searchField !== 'position'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={String(row.id)}
                            searchValue={searchString}
                            skip={searchField !== 'playerId'}
                          />
                        </TableCell>
                      </TableRow>
                    );
                  });
                };

                return (
                  <div className="relative">
                    <SharedTable<EmployeeOrderBy>
                      onRequestSort={onRequestSort}
                      rows={Rows()}
                      headCells={headCells}
                    />
                    <Paginator
                      count={data?.project.players.total}
                      perPage={searchProps.take}
                      page={Math.ceil(searchProps.skip / searchProps.take)}
                      onChangePage={onPageChange}
                    />
                    {result.loading && <Loader blurred />}
                  </div>
                );
              }}
            />
          </>
        }
      />
      <Switch>
        <Route
          path={`${path}/:playerId(\\d+)`}
          render={() => (
            <div className="pageContainer">
              <PlayerPage />
            </div>
          )}
        />
        <Route
          path={`${path}/grant`}
          exact
          render={() => (
            <div className="pageContainer">
              <GrantProjectPlayersPage
                projectId={projectId}
                onGranted={onPlayerGranted}
                onClosed={onGrantProjectPlayerClosed}
              />
            </div>
          )}
        />
        <Route exact render={() => <Redirect to={`${url}`} />} />
      </Switch>
    </div>
  );
};

const PlayersPage = styled(PlayersPage_)`
  width: 100%;
  height: 100%;
  > div > .header .navLink {
    color: ${colors.slateBlue};
    font-size: 28px;
  }
  > div > .scrollbar-container {
    .addPlayerButton {
      height: 36px;
      width: 208px;
      padding: 0 24px 0 16px;
      border-radius: 18px;
      background-color: transparent;
      border: 2px solid #2b3757;
      :hover {
        background-color: rgb(64, 76, 107) !important;
      }
    }
    .buttonsWrapper {
      min-height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    > .pageContainer {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 9;
    }
    .isActiveCheckbox {
      padding-left: 20px;
      margin: 10px 0 0;
      & .MuiIconButton-root {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      & > .MuiCheckbox-root {
        padding: 0;
      }
      & svg {
        font-size: 16px;
      }
      & > .MuiTypography-root {
        font-size: 12px;
        font-weight: 600;
        line-height: 2px;
        color: ${colors.slateBlue};
      }
    }
    .row {
      display: flex;
      align-items: center;
    }
    .relative {
      position: relative;
    }
    .avatar {
      width: 60px;
      height: 60px;
    }
    .badge {
      & > .MuiBadge-badge {
        width: 12px;
        height: 12px;
        border: solid 2px ${colors.card};
        background-color: ${colors.lightGreyBlue};
        border-radius: 50%;
      }
      &.active {
        & > .MuiBadge-badge {
          background-color: ${colors.green};
        }
      }
    }
    .cellWidth {
      &:first-child {
        width: 15%;
      }
      &:nth-child(2) {
        width: 27%;
      }
      &:nth-child(3) {
        width: 17%;
      }
      &:nth-child(4) {
        width: 27%;
      }
      &:nth-child(5) {
        width: 20%;
      }
    }
  }
`;

export default PlayersPage;
