import TextField from '@material-ui/core/TextField';
import { useField } from 'formik';
import React, { FC } from 'react';
import { InputProps } from './types';

export const Input: FC<InputProps> = ({ name, label, ...rest }) => {
  const [field, { error, touched }] = useField<string>(name);
  const shouldMarkError = !!(error && touched);

  return (
    <TextField
      error={shouldMarkError}
      helperText={shouldMarkError ? error : ``}
      fullWidth
      label={label}
      variant="outlined"
      {...field}
      {...rest}
    />
  );
};
