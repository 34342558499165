import { Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import { ExportToCsv } from 'export-to-csv';
import plural from 'plural-ru';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';
import { ActionType } from '../../../../../../_constants';
import { EmployeeOrderBy, OrderDir } from '../../../../../../_graphql/schema';
import { getComparator, stableSort } from '../../../../../../_utils';
import Paginator from '../../../../../_shared/Paginator';
import SearchBar from '../../../../../_shared/SearchBar';
import { defaultParamsType } from '../../PlayersPage/GrantProjectPlayersPage';
import GrantEmployeesTable from './GrantEmployeesTable';

const searchBySource = [
  {
    label: 'ФИО сотрудника',
    value: 'name',
  },
  {
    label: 'Подразделение',
    value: 'rootSubdivisionName',
  },
  {
    label: 'Должность',
    value: 'position',
  },
  {
    label: 'Табельный номер',
    value: 'id',
  },
];

interface Props {
  showSelectAllOnPageMenuItem: boolean;
  csvLoadDisabled?: boolean;
  showSelectAllMenuItem?: boolean;
  items: any[];
  total: number;
  loading: boolean;
  searchParams: defaultParamsType;
  setSearchParams: (p: defaultParamsType) => void;
  getMapEmployeeList?: (
    ids: string[]
  ) => Promise<{
    data: { valid: { isActive: boolean }[]; invalid: number[] } | null;
  }>;
  onCancelButtonClick: () => void;
  onSubmitButtonClick: (p: {
    excluded: number[];
    isAllSelect: boolean;
    selected: number[];
  }) => void;
}

const GrantEmployeesForm: FC<Props> = ({
  showSelectAllOnPageMenuItem = false,
  showSelectAllMenuItem = false,
  csvLoadDisabled,
  items,
  total,
  loading,
  searchParams,
  setSearchParams,
  getMapEmployeeList,
  onCancelButtonClick,
  onSubmitButtonClick,
}) => {
  const dispatch = useDispatch();

  const sliceItems = (localItems = []) => {
    return stableSort(
      localItems,
      getComparator(searchParams.orderDir.toLowerCase(), searchParams.orderBy)
    ).slice(searchParams.skip, searchParams.skip + searchParams.take);
  };
  const [csv, setCsv] = useState<File | null>(null);

  const [selected, setSelected] = useState<number[]>([]);
  const [excluded, setExcluded] = useState<number[]>([]);

  const [isAllSelect, setIsAllSelect] = useState(false);
  const [isHeadChecked, setIsHeadChecked] = useState<boolean>(false);
  const selectedLength = selected.length;
  const excludedLength = excluded.length;

  const [isHeadIndeterminate, setIsHeadIndeterminate] = useState<boolean>(
    false
  );

  const isRowSelected = (id: number) =>
    selected.indexOf(id) !== -1 || (isAllSelect && excluded.indexOf(id) === -1);

  const onSelectAllClick = (checked: boolean) => {
    if (csv) {
      setSelected([]);
      if (checked) {
        const items: number[] = /*localSearch() ||*/ [];
        setSelected(items.map((item): number => item));
      }
    } else {
      //console.log(checked);
      setSelected([]);
      setExcluded([]);
      setIsAllSelect(checked);
    }
  };
  const onSelectAllOnPageClick = () => {
    const localItems: never[] | undefined = /*csv ? localSearch() :*/ [];
    const itemIds: number[] | undefined = csv
      ? sliceItems(localItems)
      : items.map((i: { id: number }) => i.id);

    if (!isAllSelect) {
      if (itemIds?.every((id) => selected.includes(id as number))) {
        setSelected(selected.filter((id: number) => !itemIds?.includes(id)));
      } else {
        if (itemIds) {
          setSelected(
            selected
              .filter((id: number): boolean => !itemIds.includes(id))
              .concat(itemIds)
          );
        }
      }
    } else {
      if (itemIds?.every((id) => excluded.includes(id as number))) {
        setExcluded(excluded.filter((id: number) => !itemIds?.includes(id)));
      } else {
        if (itemIds) {
          setExcluded(
            excluded.filter((id) => !itemIds.includes(id)).concat(itemIds)
          );
        }
      }
    }
  };

  const setSelectedArray = (arr: number[], id: number) => {
    //console.log(arr);
    const idx = arr.indexOf(id);
    //console.log(idx);
    switch (true) {
      case idx === -1:
        return [...arr, id];
      case idx === 0:
        return [...arr.slice(1)];
      case idx === arr.length - 1:
        return [...arr.slice(0, -1)];
      default:
        return [...arr.slice(0, idx), ...arr.slice(idx + 1)];
    }
  };

  const onRowClick = (
    _e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    id: number
  ) => {
    if (!isAllSelect) {
      setSelected(setSelectedArray(selected, id));
    } else {
      setExcluded(setSelectedArray(excluded, id));
    }
  };

  const onRequestSort = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    property: EmployeeOrderBy
  ) => {
    setSearchParams({
      ...searchParams,
      orderDir:
        searchParams.orderBy === property &&
        searchParams.orderDir === OrderDir.Asc
          ? OrderDir.Desc
          : OrderDir.Asc,
      orderBy: property,
    });
  };

  /** Footer Кнопки Отмена и Выдать*/
  const onPageChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchParams({
      ...searchParams,
      skip: page * searchParams.take,
    });
  };
  const onSubmitButtonClickFunction = () => {
    onSubmitButtonClick({ isAllSelect, selected, excluded });
  };
  const onCancelButtonClickFunction = () => {
    onCancelButtonClick();
  };

  const [totalSelected, setTotalSelected] = useState(0);

  const onSearchValueChange = (string: string) => {
    setSelected([]);
    setExcluded([]);

    setSearchParams({
      ...searchParams,
      searchValue: string,
      skip: 0,
    });
    setIsAllSelect(false);
  };
  const onSearchFieldChange = (field: string) => {
    setExcluded([]);
    setSearchParams({
      ...searchParams,
      searchField: field,
      skip: 0,
    });
    setIsAllSelect(false);
    setSelected([]);
  };

  /** Кнопка Загрузить файл CSV */

  const exportInvalidCsv = (invalid: number[]) => {
    const options = {
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      filename: 'not-found-employees',
      showLabels: false,
      showTitle: false,
      title: 'My Awesome CSV',
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: false,
    };

    const csvExporter = new ExportToCsv(options);

    csvExporter.generateCsv(
      invalid.map((n) => {
        return { personnelNumber: n };
      })
    );
  };

  const getMapEmployeeListFunction = (ids: string[]) => {
    // setMapEmployeeList({
    //   ...mapEmployeeList,
    //   loading: true,
    //   error: null,
    // });
    //
    if (getMapEmployeeList) {
      getMapEmployeeList(ids)
        .then(({ data }) => {
          const invalid = data?.invalid;
          if (invalid?.length) {
            dispatch({
              type: ActionType.SHOW_ALERT_DIALOG,
              payload: {
                title: `${plural(
                  invalid.length,
                  'Не найден %d сотрудник',
                  'Не найдено %d сотрудника',
                  'Не найдено %d сотрудников'
                )}`,
                message: (
                  <span>
                    Они не будут добавлены.
                    <br />
                    <span
                      className="alertLink"
                      onClick={() => exportInvalidCsv(invalid)}
                    >
                      Скачать
                    </span>{' '}
                    список ненайденных сотрудников.
                  </span>
                ),
              },
            });
          }
          if (data) {
            // setMapEmployeeList({
            //   data: data,
            //   loading: false,
            //   error: null,
            // });
          }
        })
        .catch((error: { message: string }) => {
          dispatch({
            type: ActionType.ADD_TOAST,
            payload: {
              text: `Ошибка получения списка сотрудников ${error.message}`,
              className: 'error',
              delay: 5000,
            },
          });

          // setMapEmployeeList({
          //   ...mapEmployeeList,
          //   loading: false,
          //   error: null,
          // });
        });
    }
  };

  const MAX_IDS = 10000;

  const onCsvUploaded = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const csv: File | null = (files?.length && files[0]) || null;
    if (csv) {
      const reader = new FileReader();
      reader.readAsText(csv);
      reader.onload = () => {
        const ids = reader?.result
          ?.toString()
          .split(/\r\n|\n/)
          .map((row) => row.split(/\t/)[0])
          .filter((v) => v);
        //console.log(ids);
        if (ids && ids.length > MAX_IDS) {
          dispatch({
            type: ActionType.ADD_TOAST,
            payload: {
              text: `Возможно импортировать не более ${plural(
                MAX_IDS,
                '%d сотрудника',
                '%d сотрудников',
                '%d сотрудников'
              )} за раз. В файле ${plural(
                ids.length,
                '%d сотрудника',
                '%d сотрудников',
                '%d сотрудников'
              )}`,
              className: 'error',
              delay: 5000,
            },
          });

          setSelected([]);
          setExcluded([]);
          setCsv(null);
          setIsAllSelect(false);
        } else if (ids) {
          getMapEmployeeListFunction(ids);
        }
      };
      reader.onerror = () => {
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            text: `Ошибка чтения csv`,
            className: 'error',
          },
        });
      };

      setCsv(csv);
      setSelected([]);
      setExcluded([]);
      setIsAllSelect(false);
      //readCsvFile();
    }
  };

  const onCsvClearButtonClick = () => {
    setCsv(null);
    setSelected([]);
    setExcluded([]);
    setIsAllSelect(false);
  };

  /** Действующий сотрудник */

  const onIsActiveChange = () => {
    setSelected([]);
    setExcluded([]);
    setSearchParams({
      ...searchParams,
      isActive: !searchParams.isActive,
      skip: 0,
    });
    setIsAllSelect(false);
  };

  useEffect(() => {
    if (
      (selectedLength && selectedLength === total) ||
      (isAllSelect && excludedLength === 0)
    ) {
      setIsHeadChecked(true);
    } else {
      setIsHeadChecked(false);
    }
    if (
      (selectedLength && selectedLength < total) ||
      (isAllSelect && excludedLength && excludedLength < total)
    ) {
      setIsHeadIndeterminate(true);
    } else {
      setIsHeadIndeterminate(false);
    }
    setTotalSelected(isAllSelect ? total - excludedLength : selectedLength);
  }, [setTotalSelected, isAllSelect, selectedLength, excludedLength, total]);

  return (
    <>
      <SearchGroupComp>
        <div className="row">
          <SearchBar
            placeholder={
              csv ? 'Поиск сотрудников по файлу...' : 'Поиск сотрудников...'
            }
            className={['searchBar', csvLoadDisabled ? ' no-margin' : ''].join(
              ' '
            )}
            searchBySource={searchBySource}
            onSearchValueChange={onSearchValueChange}
            onSearchFieldChange={onSearchFieldChange}
          />
          {csvLoadDisabled ? null : csv ? (
            <div className="selectedCsv">
              <Typography>{csv?.name}</Typography>
              <IconButton
                aria-label="delete"
                onClick={onCsvClearButtonClick}
                size="medium"
              >
                <ClearIcon color="inherit" fontSize="inherit" />
              </IconButton>
            </div>
          ) : (
            <Button
              className="uploadButton"
              variant="outlined"
              color="primary"
              disableElevation
              component="label"
            >
              Загрузить файл
              <input
                accept=".csv"
                type="file"
                style={{ display: 'none' }}
                onChange={onCsvUploaded}
              />
            </Button>
          )}
        </div>
        <div className="row">
          <FormControlLabel
            className="isActiveCheckbox"
            control={
              <Checkbox
                checked={searchParams.isActive}
                onChange={onIsActiveChange}
                color="primary"
              />
            }
            label="Действующий сотрудник"
          />
        </div>
      </SearchGroupComp>
      <SelectedEmployees>
        <div>
          {totalSelected} из{' '}
          {plural(total, '%d сотрудника', '%d сотрудников', '%d сотрудников')}
        </div>
      </SelectedEmployees>
      <GrantEmployeesTable
        order={searchParams.orderDir.toLowerCase()}
        orderBy={searchParams.orderBy}
        searchValue={searchParams.searchValue}
        searchField={searchParams.searchField}
        loading={loading}
        isHeadChecked={isHeadChecked}
        isHeadIndeterminate={isHeadIndeterminate}
        selected={selected}
        total={total}
        rows={items}
        isSelected={isRowSelected}
        onSelectAllClick={onSelectAllClick}
        onSelectAllOnPageClick={onSelectAllOnPageClick}
        onRowClick={onRowClick}
        onRequestSort={onRequestSort}
        showSelectAllMenuItem={showSelectAllMenuItem}
        showSelectAllOnPageMenuItem={showSelectAllOnPageMenuItem}
      />
      <Footer>
        <Paginator
          count={total}
          perPage={searchParams.take}
          page={Math.ceil(searchParams.skip / searchParams.take)}
          onChangePage={onPageChange}
        />
        <div className="actions">
          <Button
            className="cancelButton"
            variant="text"
            color="primary"
            disableElevation
            type="button"
            onClick={onCancelButtonClickFunction}
          >
            Отмена
          </Button>
          <Button
            className="saveButton"
            variant={totalSelected ? 'contained' : 'text'}
            color="primary"
            disableElevation
            type="submit"
            disabled={totalSelected === 0}
            onClick={onSubmitButtonClickFunction}
          >
            Выдать{' '}
            {totalSelected > 0 &&
              plural(
                totalSelected,
                '%d сотруднику',
                '%d сотрудникам',
                '%d сотрудникам'
              )}
          </Button>
        </div>
      </Footer>
    </>
  );
};
const SearchGroupComp = styled.div`
  display: flex;
  flex-direction: column;
  .row {
    display: flex;
    align-items: center;
  }
  .searchBar {
    &.no-margin {
      margin-right: 0;
    }
  }
  .uploadButton {
    margin-left: auto;
    width: 208px;
  }
  .selectedCsv {
    margin-left: auto;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width: 208px;
    font-size: 14px;
    font-weight: 600;
    line-height: 1.71;
    color: ${({ theme }) => theme.colors.white};
    & > button {
      color: ${({ theme }) => theme.colors.slateBlue};
    }
  }
  .isActiveCheckbox {
    padding-left: 20px;
    margin: 10px 0 0;
    & .MuiIconButton-root {
      width: 20px;
      height: 20px;
      margin-right: 4px;
    }
    & > .MuiCheckbox-root {
      padding: 0;
    }
    & svg {
      font-size: 16px;
    }
    & > .MuiTypography-root {
      font-size: 12px;
      font-weight: 600;
      line-height: 2;
      color: ${({ theme }) => theme.colors.slateBlue};
    }
  }
`;
const SelectedEmployees = styled.div`
  display: flex;
  margin-top: 12px;
  margin-bottom: 12px;
  & > div {
    display: flex;
    margin-left: auto;
    font-size: 16px;
    font-weight: 600;
    line-height: 1.5;
    color: ${({ theme }) => theme.colors.slateBlue};
  }
`;
const Footer = styled.div`
  margin-top: auto;
  height: 64px;
  display: flex;
  align-items: center;
  > .actions {
    display: flex;
    margin-left: auto;
    .saveButton {
      height: 36px;
      min-width: 208px;
      border-radius: 18px;
    }
    .cancelButton {
      height: 36px;
      width: 160px;
      border-radius: 18px;
    }
  }
`;

export default GrantEmployeesForm;
