import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useParams } from 'react-router-dom';
import {
  Checkbox,
  Input,
  InputReadonly,
  SelectSubdivision,
} from '../../../../../_shared/fields';
import FormTemplate, { FormDecor } from '../../../../../_shared/FormTemplate';
import QueryView from '../../../../../_shared/QueryView';
import { useGetProjectAvailableSubdivisionsQuery } from './queries';
import { CategoryDeleteType, Field, Fields } from './types';

interface Props {
  onDelete?: CategoryDeleteType;
}

const Form: FC<Props> = ({ onDelete }) => {
  const { projectId } = useParams<{
    projectId: string;
  }>();
  const formikContext = useFormikContext<Fields>();

  const projectAvailableSubdivisionsQueryResult = useGetProjectAvailableSubdivisionsQuery(
    {
      variables: {
        id: +projectId,
        name: '',
        skip: 0,
        take: 50,
      },
    }
  );

  return (
    <QueryView
      result={projectAvailableSubdivisionsQueryResult}
      renderData={(data) => (
        <FormTemplate
          fieldsRender={[
            {
              title: `Основная информация`,
              render: () => (
                <>
                  <Input name={Field.name} label="Название" />
                  {formikContext.values.id ? (
                    <InputReadonly name={Field.id} label="ID" />
                  ) : null}
                </>
              ),
            },
            {
              title: `Подразделения`,
              render: () => (
                <SelectSubdivision
                  name={Field.subdivisions}
                  optionList={data.project.availableSubdivisions.items}
                />
              ),
            },
            FormDecor.divider,
            {
              title: `Доступ`,
              render: () => (
                <Checkbox name={Field.isActive} label="Категория активна" />
              ),
            },
            {
              show: !!onDelete,
              render: () => (
                <button
                  className="button-reset deleteButton"
                  type="button"
                  onClick={onDelete}
                >
                  Удалить категорию
                </button>
              ),
            },
          ]}
        />
      )}
    />
  );
};

export default Form;
