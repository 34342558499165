import { TextField } from '@material-ui/core';
import MenuItem from '@material-ui/core/MenuItem';
import { useField } from 'formik';
import React, { FC } from 'react';
import styled from 'styled-components/macro';
import { SelectProps } from './types';

export const Select: FC<SelectProps> = ({ name, label, optionList, type }) => {
  const [field, { error, touched }] = useField<string>(name);
  const shouldMarkError = !!(error && touched);

  return (
    <TextField
      error={shouldMarkError}
      helperText={shouldMarkError ? error : ``}
      label={label}
      select={true}
      variant="outlined"
      fullWidth
      {...field}
    >
      {optionList.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {type === `color` && option.value ? (
            <ColorWrapper backgroundColor={option.value}>
              {option.name}
            </ColorWrapper>
          ) : (
            option.name
          )}
        </MenuItem>
      ))}
    </TextField>
  );
};

const ColorWrapper = styled.div<{ backgroundColor: string }>`
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  justify-content: start;
  gap: 8px;

  &::before {
    width: 16px;
    height: 16px;

    background-color: ${(props) => props.backgroundColor};
    border-radius: 4px;

    content: '';
  }
`;
