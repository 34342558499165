import { Formik } from 'formik';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActionType } from '../../../../_constants';
import { StyledFC } from '../../../_shared/types';
import AppHeader from '../MainPage/_shared/AppHeader';
import Page from '../_shared/Page';
import Form from './_shared/Form';
import {
  CreateProjectMutationVariables,
  useCreateProjectMutation,
} from './_shared/queries';
import { Field, Fields, ProjectCancel } from './_shared/types';
import validation from './_shared/validation';

const adaptForCreate = ({
  description,
  imageBase64,
  isActive,
  name,
  subdivisions,
}: Fields): CreateProjectMutationVariables => ({
  description,
  imageBase64,
  isActive,
  name,
  subdivisionIds: subdivisions.map(({ id }) => id),
});

const getEmptyInitialValues = (): Fields => ({
  [Field.id]: 0,
  [Field.description]: ``,
  [Field.imageBase64]: ``,
  [Field.imageSrc]: ``,
  [Field.isActive]: true,
  [Field.name]: ``,
  [Field.subdivisions]: [],
});

const New_: StyledFC = ({ className }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [postProjectMutation] = useCreateProjectMutation();

  const handleCancel: ProjectCancel = () => {
    history.push(`/`);
  };

  const handleSubmit = async (formData: Fields) => {
    const { data, errors } = await postProjectMutation({
      variables: adaptForCreate(formData),
    });

    if (data) {
      history.push(`${data.createProject.id}/settings`);
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'success',
          delay: 5000,
          text: `Проект «${data.createProject.name}» успешно создан`,
        },
      });
    } else if (errors) {
      dispatch({
        type: ActionType.ADD_TOAST,
        payload: {
          className: 'error',
          delay: 5000,
          text: `Ошибки при создании проекта ${errors.map(
            ({ message }) => message
          )}`,
        },
      });
    }
  };

  return (
    <Page
      className={className}
      noScroll
      header={<AppHeader title="Новый проект" />}
      content={
        <Formik
          initialValues={getEmptyInitialValues()}
          validationSchema={validation}
          onReset={handleCancel}
          onSubmit={handleSubmit}
        >
          <Form />
        </Formik>
      }
    />
  );
};

const New = styled(New_)`
  display: flex;
  background-color: ${({ theme }) => theme.colors.darkBackground};
  .content {
    flex-grow: 1;
  }
`;

export default New;
