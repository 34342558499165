import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React, { FC } from 'react';

const ManagersIcon: FC<SvgIconProps> = (props) => (
  <SvgIcon {...props}>
    <g transform="translate(2 1)">
      <circle cx="5.219" cy="5.365" r="1.313" />
      <path d="M5.208 7.958c-1.29.008-2.392-.931-2.589-2.207-.196-1.276.572-2.504 1.805-2.885 1.233-.381 2.56.199 3.118 1.363l1.364-.687c-.02-.038-.036-.076-.052-.115l.438-.604c.181-.255.15-.604-.073-.823l-.646-.656c-.124-.118-.287-.185-.458-.188-.136 0-.267.044-.375.125l-.605.427c-.231-.126-.476-.227-.729-.302L6.281.688c-.05-.312-.32-.541-.635-.542h-.917c-.316 0-.584.23-.635.541l-.125.74c-.244.078-.481.175-.709.292l-.593-.427c-.109-.081-.24-.125-.375-.125-.174 0-.34.072-.459.198l-.645.645c-.228.217-.26.57-.073.823l.437.615c-.121.225-.219.463-.292.708l-.718.125C.23 4.332 0 4.601 0 4.917v.916c.001.316.23.585.542.636l.74.125c.075.244.172.481.29.708l-.426.594c-.183.258-.152.61.073.833l.646.646c.22.22.566.25.823.073l.614-.438c.22.115.45.21.688.282l.125.729c.05.311.32.54.635.541h.917c.315 0 .584-.23.635-.541l.125-.73c.207-.067.41-.15.604-.25l-.677-1.364c-.354.184-.747.28-1.146.281z" />
    </g>
    <path d="M23 20.7c0-4.4-3.6-8-8-8s-8 3.6-8 8c0 .6.4 1 1 1h14c.6 0 1-.5 1-1zm-13.9-1c.5-2.8 2.9-5 5.9-5 3 0 5.4 2.2 5.9 5H9.1zm5.9-5c2.8 0 5-2.2 5-5s-2.2-5-5-5-5 2.2-5 5 2.2 5 5 5zm0-2c-1.7 0-3-1.3-3-3s1.3-3 3-3 3 1.3 3 3-1.3 3-3 3z" />
  </SvgIcon>
);
export default ManagersIcon;
