import Avatar from '@material-ui/core/Avatar';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDebounce } from '@react-hook/debounce';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import styled from 'styled-components/macro';
import { ActionType, colors } from '../../../../../_constants';
import { EmployeeOrderBy, OrderDir } from '../../../../../_graphql/schema';
import HighlightedText from '../../../../_shared/HighlightedText';
import Paginator from '../../../../_shared/Paginator';
import QueryView from '../../../../_shared/QueryView';
import SearchBar from '../../../../_shared/SearchBar';
import SharedTable from '../../../../_shared/SharedTable';
import { HeadCell, StyledFC } from '../../../../_shared/types';
import Loader from '../../_shared/Loader';
import Page from '../../_shared/Page';
import AppHeader from '../_shared/AppHeader';
import GrantProjectManagersPage from './GrantProjectManagersPage';
import { useGetManagersQuery, useRemoveManagerMutation } from './queries';

const ManagersPage_: StyledFC = ({ className }) => {
  const match = useRouteMatch<{ projectId: string }>();
  const projectId = parseInt(match.params.projectId);
  const dispatch = useDispatch();
  const history = useHistory();

  const [searchValue, setSearchValue] = useDebounce('', 500);
  const [searchProps, setSearchProps] = useState({
    skip: 0,
    take: 10,
    searchField: 'name',
    orderBy: EmployeeOrderBy.Name,
    orderDir: OrderDir.Asc,
    isActive: true,
  });

  const result = useGetManagersQuery({
    variables: {
      id: projectId,
      ...searchProps,
      ...(searchValue ? { [searchProps.searchField]: searchValue } : {}),
    },
  });

  const [highlightManager] = useState({ id: null });

  const [removeManagerMutation] = useRemoveManagerMutation();
  const projectRemoveManager = (employeeId: number) => {
    removeManagerMutation({
      variables: {
        project: projectId,
        employee: employeeId,
      },
    })
      .then(({ data }) => {
        if (data?.removeManager) {
          dispatch({
            type: ActionType.ADD_TOAST,
            payload: {
              text: `Администратор успешно удален`,
              className: 'success',
              delay: 5000,
            },
          });
        } else {
          dispatch({
            type: ActionType.ADD_TOAST,
            payload: {
              text: `TODO: неудача ${data}`,
              className: 'error',
              delay: 5000,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: ActionType.ADD_TOAST,
          payload: {
            text: `Ошибка Удаления администратора ${error.message}`,
            className: 'error',
            delay: 5000,
          },
        });
      });
  };

  // onEditManagerClick = (manager) => {
  //     this.props.history.push(`managers/${manager.id}`)
  // };

  const onAddManagerClick = () => {
    history.push(`managers/grant`);
  };

  /*  const onManagerRowClick = (manager: never) => {
    console.log('onManagerRowClick', manager);
  };*/

  const onDeleteManagerClick = (manager: number) => {
    dispatch({
      type: ActionType.SHOW_CONFIRM_DIALOG,
      payload: {
        title: 'Удалить из администраторов проекта?',
        message: 'Это приведет к потере прав администратора',
        applyText: 'Удалить',
        cancelText: 'Отмена',
        onApply: () => projectRemoveManager(manager),
      },
    });
  };

  // onManagerCreated = (manager) => {
  //     this.getProjectManagerList({highlightManager: manager})
  // };
  // onManagerChanged = (manager) => {
  //     this.getProjectManagerList({highlightManager: manager})
  // };
  // onManagerDeleted = (manager) => {
  //     this.getProjectManagerList()
  // };

  const onManagerGranted = () => {
    history.push(`/projects/${projectId}/managers`);
  };

  const onGrantProjectManagerClosed = () => {
    history.push(`/projects/${projectId}/managers`);
  };

  const searchBySource = [
    {
      label: 'ФИО сотрудника',
      value: 'name',
    },
    {
      label: 'Подразделение',
      value: 'rootSubdivisionName',
    },
    {
      label: 'Должность',
      value: 'position',
    },
    {
      label: 'Табельный номер',
      value: 'id',
    },
  ];

  const onRequestSort = (orderBy: EmployeeOrderBy, orderDir: OrderDir) => {
    setSearchProps({
      ...searchProps,
      orderBy: orderBy,
      orderDir: orderDir,
    });
  };

  const onSearchValueChange = (value: string) => {
    setSearchValue(value);
  };

  const onSearchFieldChange = (field: string) => {
    setSearchProps({
      ...searchProps,
      searchField: field,
    });
  };

  const onPageChange = (
    _e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    page: number
  ) => {
    setSearchProps({
      ...searchProps,
      skip: page * searchProps.take,
    });
  };

  const onIsActiveChange = () => {
    setSearchProps({
      ...searchProps,
      isActive: !searchProps.isActive,
      skip: 0,
    });
  };

  const headCells: HeadCell<EmployeeOrderBy>[] = [
    {
      id: 'image',
      numeric: false,
      disablePadding: false,
      label: '',
      sortable: false,
    },
    {
      id: 'name',
      numeric: true,
      disablePadding: false,
      label: 'Имя',
      orderBy: EmployeeOrderBy.Name,
    },
    {
      id: 'rootSubdivisionName',
      numeric: true,
      disablePadding: false,
      label: 'Подразделение',
      orderBy: EmployeeOrderBy.RootSubdivisionName,
    },
    {
      id: 'position',
      numeric: true,
      disablePadding: false,
      label: 'Должность',
      orderBy: EmployeeOrderBy.Position,
    },
    {
      id: 'id',
      numeric: true,
      disablePadding: false,
      label: 'Табельный номер',
      orderBy: EmployeeOrderBy.Id,
    },
    {
      id: 'button',
      numeric: true,
      disablePadding: false,
      label: '',
      sortable: false,
    },
  ];

  return (
    <div className={className}>
      <Page
        header={<AppHeader title={`Администраторы`} />}
        noScroll
        content={
          <>
            <div className="buttonsWrapper">
              <SearchBar
                searchBySource={searchBySource}
                placeholder={`Поиск администраторов...`}
                onSearchValueChange={onSearchValueChange}
                onSearchFieldChange={onSearchFieldChange}
                minSelectWidth={242}
                selectWidth={240}
                width={70}
              />
              <Button
                className="addManagerButton"
                variant="contained"
                color="primary"
                disableElevation
                type="submit"
                style={{
                  backgroundColor: 'transparent',
                  border: '2px solid #2b3757',
                }}
                onClick={() => onAddManagerClick()}
              >
                Добавить
              </Button>
            </div>
            <div className="row" style={{ marginBottom: 24 }}>
              <FormControlLabel
                className="isActiveCheckbox"
                control={
                  <Checkbox
                    checked={searchProps.isActive}
                    onChange={onIsActiveChange}
                    color="primary"
                  />
                }
                label="Действующий сотрудник"
              />
            </div>
            <QueryView
              result={result}
              renderData={(data, result) => {
                const Rows = (): JSX.Element[] | undefined => {
                  const managers = data.project.managers.items;
                  const searchString = searchValue;
                  const searchField = searchProps.searchField;

                  return managers?.map((row) => {
                    return (
                      <TableRow
                        className={
                          highlightManager?.id === row.id ? 'highlighted' : ''
                        }
                        hover
                        tabIndex={-1}
                        key={row.id}
                        //onClick={() => onManagerRowClick(row)}
                        style={{
                          height: row.avatar ? 92 : 72,
                          cursor: 'default',
                        }}
                      >
                        <TableCell align="left" className="cell cellWidth">
                          <Badge
                            className={`badge ${
                              searchProps.isActive ? 'active' : ''
                            }`}
                            overlap="circle"
                            badgeContent=" "
                            variant="dot"
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'right',
                            }}
                          >
                            <Avatar
                              className="avatar"
                              src={
                                row.avatar
                                  ? process.env.REACT_APP_BASE_PATH + row.avatar
                                  : '/assets/images/male-default-avatar.svg'
                              }
                            />
                          </Badge>
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.name}
                            searchValue={searchString}
                            skip={searchField !== 'name'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.rootSubdivisionName}
                            searchValue={searchString}
                            skip={searchField !== 'rootSubdivisionName'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.position}
                            searchValue={searchString}
                            skip={searchField !== 'position'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <HighlightedText
                            text={row.id.toString()}
                            searchValue={searchString}
                            skip={searchField !== 'id'}
                          />
                        </TableCell>
                        <TableCell className="cell cellWidth">
                          <IconButton
                            className="action-button"
                            onClick={(e) => {
                              e.stopPropagation();
                              onDeleteManagerClick(row.id);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  });
                };

                return (
                  <div className="relative">
                    <SharedTable<EmployeeOrderBy>
                      onRequestSort={onRequestSort}
                      rows={Rows()}
                      headCells={headCells}
                    />

                    <Paginator
                      count={data.project.managers.total}
                      perPage={searchProps.take}
                      page={Math.ceil(searchProps.skip / searchProps.take)}
                      onChangePage={onPageChange}
                    />

                    {result.loading && <Loader blurred />}
                  </div>
                );
              }}
            />
          </>
        }
      />
      <Switch>
        <Route
          path={`${match.path}/:managerId(\\d+)`}
          render={() => (
            <div className="pageContainer">
              ManagerPage
              {/*<ManagerPage onManagerChanged={this.onManagerChanged}*/}
              {/*              onManagerDeleted={this.onManagerDeleted}/>*/}
            </div>
          )}
        />
        <Route
          path={`${match.path}/grant`}
          exact
          render={() => (
            <div className="pageContainer">
              <GrantProjectManagersPage
                projectId={projectId}
                onGranted={onManagerGranted}
                onClosed={onGrantProjectManagerClosed}
              />
            </div>
          )}
        />
        <Route exact render={() => <Redirect to={`${match.url}`} />} />
      </Switch>
    </div>
  );
};

const ManagersPage = styled(ManagersPage_)`
  width: 100%;
  height: 100%;
  .scrollbar-container {
    .addManagerButton {
      height: 36px;
      width: 208px;
      padding: 0 24px 0 16px;
      border-radius: 18px;
      background-color: transparent;
      border: 2px solid #2b3757;
      :hover {
        background-color: rgb(64, 76, 107) !important;
      }
    }
    .buttonsWrapper {
      min-height: 56px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
    }
    .isActiveCheckbox {
      margin: 10px 0 0 0;
      padding-left: 20px;
      & > .MuiCheckbox-root {
        padding: 0;
      }
      & svg {
        font-size: 16px;
      }
      & .MuiIconButton-root {
        width: 20px;
        height: 20px;
        margin-right: 4px;
      }
      & > .MuiTypography-root {
        font-size: 12px;
        font-weight: 600;
        line-height: 2;
        color: ${colors.slateBlue};
      }
    }
    .row {
      display: flex;
      align-items: center;
    }
    .relative {
      position: relative;
    }
    .avatar {
      width: 60px;
      height: 60px;
    }
    .badge {
      & > .MuiBadge-badge {
        width: 12px;
        height: 12px;
        border: solid 2px ${colors.card};
        background-color: ${colors.lightGreyBlue};
        border-radius: 50%;
      }
      &.active {
        & > .MuiBadge-badge {
          background-color: #19c4ac;
        }
      }
    }
    .cellWidth {
      &:first-child {
        width: 9% !important;
      }
      &:nth-child(2) {
        width: 29% !important;
      }
      &:nth-child(3) {
        width: 13% !important;
      }
      &:nth-child(4) {
        width: 29% !important;
      }
      &:nth-child(5) {
        width: 15% !important;
      }
      &:nth-child(6) {
        width: 10% !important;
      }
    }
  }
  > .pageContainer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
  }
`;

export default ManagersPage;
