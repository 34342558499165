import MaterialCheckbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { useField } from 'formik';
import React, { FC } from 'react';
import { InputCheckboxProps } from './types';

export const Checkbox: FC<InputCheckboxProps> = ({ name, label, ...rest }) => {
  const [{ value, onChange }] = useField<boolean>(name);

  return (
    <FormControlLabel
      className="checkbox"
      label={label}
      control={
        <MaterialCheckbox
          color="primary"
          name={name}
          checked={value}
          onChange={onChange}
        />
      }
      {...rest}
    />
  );
};
