import { CardContent } from '@material-ui/core';
import Card from '@material-ui/core/Card';
import Checkbox from '@material-ui/core/Checkbox';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import React, { FC } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import styled from 'styled-components/macro';
import { AchievementOrderBy } from '../../../../../../_graphql/schema';
import CheckboxDropdownMenu from '../../../../../_shared/CheckboxDropdownMenu';
import HighlightedText from '../../../../../_shared/HighlightedText';
import { HeadCell, StyledFC } from '../../../../../_shared/types';
import DropdownIcon from '../../../../../icons/DropdownIcon';
import Loader from '../../../_shared/Loader';

const headCells: HeadCell<AchievementOrderBy>[] = [
  {
    id: 'image',
    numeric: false,
    sortable: false,
    align: 'left',
    disablePadding: false,
    label: '',
  },
  {
    id: 'name',
    numeric: false,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'Название',
    orderBy: AchievementOrderBy.Name,
  },
  {
    id: 'weight',
    numeric: false,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'Вес',
    orderBy: AchievementOrderBy.Weight,
  },
  {
    id: 'category',
    numeric: false,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'Категория',
    orderBy: AchievementOrderBy.Category,
  },
  {
    id: 'id',
    numeric: true,
    sortable: true,
    align: 'left',
    disablePadding: false,
    label: 'ID',
    orderBy: AchievementOrderBy.Id,
  },
];

const GrantPlayerAchievementsTableHead_: StyledFC<{
  order: string;
  orderBy: string;
  isChecked: boolean;
  isIndeterminate: boolean;
  showSelectAllMenuItem: boolean;
  showSelectAllOnPageMenuItem: boolean;
  onSelectAllClick: (ckecked: boolean) => void;
  onSelectAllOnPageClick: () => void;
  onRequestSort: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    property: AchievementOrderBy
  ) => void;
  className?: string;
}> = ({
  order,
  orderBy,
  isChecked,
  isIndeterminate,
  showSelectAllMenuItem,
  showSelectAllOnPageMenuItem,
  onSelectAllClick,
  onSelectAllOnPageClick,
  onRequestSort,
  className,
}) => {
  const createSortHandler = (property: AchievementOrderBy) => (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow className={className}>
        <TableCell className="cell" padding="checkbox">
          <CheckboxDropdownMenu
            onSelectAllClick={onSelectAllClick}
            onSelectAllOnPageClick={onSelectAllOnPageClick}
            indeterminate={isIndeterminate}
            checked={isChecked}
            showSelectAllMenuItem={showSelectAllMenuItem}
            showSelectAllOnPageMenuItem={showSelectAllOnPageMenuItem}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            className="cell"
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={
              orderBy === headCell.id
                ? order === 'desc'
                  ? 'desc'
                  : 'asc'
                : undefined
            }
          >
            <TableSortLabel
              disabled={!headCell.sortable}
              active={orderBy === headCell.id}
              direction={
                orderBy === headCell.id
                  ? order === 'desc'
                    ? 'desc'
                    : 'asc'
                  : undefined
              }
              onClick={createSortHandler(headCell.id as AchievementOrderBy)}
              IconComponent={DropdownIcon as React.FC}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className="visuallyHidden" />
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};

const GrantPlayerAchievementsTableHead = styled(
  GrantPlayerAchievementsTableHead_
)`
  > .cell {
    font-size: 12px;
    font-weight: 600;
    line-height: 2;
    color: ${({ theme }) => theme.colors.slateBlue};
    padding-top: 1px;
    padding-bottom: 13px;
    border: none;
    &:first-child {
      padding-left: 16px;
      &:after {
        left: 16px;
      }
    }
    &:last-child {
      padding-right: 16px;
      &:after {
        right: 16px;
      }
    }
    &:after {
      content: ' ';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      height: 1px;
      background-color: ${({ theme }) => theme.colors.hint};
    }
  }
  .checkbox {
    padding: 0;
  }
`;

const Row_: FC<{
  row: any;
  selected: number[];
  index: number;
  searchValue: string;
  searchField: string;
  className?: string;
  handleClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowId: number
  ) => void;
  isSelected: (id: number) => boolean;
}> = ({
  row,
  index,
  searchValue,
  searchField,
  selected,
  handleClick = () => console.log('handleClick'),
  isSelected = (value) => selected.indexOf(value) !== -1,
  className,
}) => {
  const isItemSelected = isSelected(row.id);
  const labelId = `grant-player-achievements-table-checkbox-${index}`;

  return (
    <TableRow
      className={className}
      hover
      onClick={(event) => handleClick(event, row.id)}
      role="checkbox"
      aria-checked={isItemSelected}
      tabIndex={-1}
      selected={isItemSelected}
    >
      <TableCell padding="checkbox" className="cell">
        <Checkbox
          className="checkbox"
          checked={isItemSelected}
          inputProps={{ 'aria-labelledby': labelId }}
        />
      </TableCell>
      <TableCell className="cell" component="th" id={labelId} scope="row">
        <img
          className="image"
          alt="logo"
          src={row.image ? process.env.REACT_APP_BASE_PATH + row.image : null}
          style={{ backgroundColor: row.color.value }}
        />
      </TableCell>
      <TableCell className="cell">
        <HighlightedText
          text={row.name}
          skip={searchField !== 'name'}
          searchValue={searchValue}
        />
      </TableCell>
      <TableCell className="cell">
        <HighlightedText
          text={row.weight.name}
          skip={searchField !== 'weight'}
          searchValue={searchValue}
        />
      </TableCell>
      <TableCell className="cell">
        <HighlightedText
          text={row.category.name}
          skip={searchField !== 'category'}
          searchValue={searchValue}
        />
      </TableCell>
      <TableCell className="cell">
        <HighlightedText
          text={row.id}
          skip={searchField !== 'id'}
          searchValue={searchValue}
        />
      </TableCell>
    </TableRow>
  );
};

const Row = styled(Row_)`
  &.Mui-selected {
    background-color: inherit;
  }
  .cell {
    padding: 12px;
    border: none;
    font-size: 14px;
    cursor: pointer;
    color: ${({ theme }) => theme.colors.white};
    &:first-child {
      padding-left: 16px;
      border-radius: 10px 0 0 10px;
    }
    &:last-child {
      padding-right: 16px;
      border-radius: 0 10px 10px 0;
    }
  }
  .checkbox {
    padding: 0;
  }
  .image {
    display: block;
    width: 48px;
    height: 48px;
    border-radius: 6px;
    object-fit: contain;
  }
`;

const GrantPlayerAchievementsTable_: StyledFC<{
  order: string;
  orderBy: string;
  selected: number[];
  total?: number;
  searchValue: string;
  searchField: string;
  loading: boolean;
  isHeadChecked: boolean;
  isHeadIndeterminate: boolean;
  rows: any[] | undefined;
  showSelectAllMenuItem: boolean;
  showSelectAllOnPageMenuItem: boolean;
  isSelected: (id: number) => boolean;
  onSelectAllClick: (checked: boolean) => void;
  onSelectAllOnPageClick: () => void;
  onRowClick: (
    event: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    rowId: number
  ) => void;
  onRequestSort: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    property: AchievementOrderBy
  ) => void;
}> = ({
  order,
  orderBy,
  selected,
  searchValue,
  searchField,
  loading = false,
  isHeadChecked = false,
  isHeadIndeterminate = false,
  rows = [],
  showSelectAllMenuItem,
  showSelectAllOnPageMenuItem,
  isSelected = (value) => selected.indexOf(value) !== -1,
  onSelectAllClick = (event) => console.log('onSelectAllClick', event),
  onSelectAllOnPageClick = () => console.log('onSelectAllOnPageClick'),
  onRowClick = (event, id) => console.log('onRowClick', event, id),
  onRequestSort = (event, property) =>
    console.log('handleRequestSort', event, property),
  className,
}) => {
  return (
    <div className={className}>
      <Card elevation={0} className="card">
        <CardContent className="cardContent">
          <TableContainer className="tableContainer">
            <PerfectScrollbar>
              <Table stickyHeader className="table">
                <GrantPlayerAchievementsTableHead
                  isChecked={isHeadChecked}
                  isIndeterminate={isHeadIndeterminate}
                  showSelectAllOnPageMenuItem={showSelectAllOnPageMenuItem}
                  showSelectAllMenuItem={showSelectAllMenuItem}
                  order={order.toString().toLowerCase()}
                  orderBy={orderBy}
                  onSelectAllClick={onSelectAllClick}
                  onSelectAllOnPageClick={onSelectAllOnPageClick}
                  onRequestSort={onRequestSort}
                />
                <TableBody>
                  {rows.map((row, idx) => (
                    <Row
                      isSelected={isSelected}
                      key={row.id}
                      row={row}
                      index={idx}
                      selected={selected}
                      handleClick={onRowClick}
                      searchField={searchField}
                      searchValue={searchValue}
                    />
                  ))}
                </TableBody>
              </Table>
            </PerfectScrollbar>
          </TableContainer>
          {loading && <Loader />}
        </CardContent>
      </Card>
    </div>
  );
};

const GrantPlayerAchievementsTable = styled(GrantPlayerAchievementsTable_)`
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
  > .card {
    overflow: hidden;
    height: 100%;
    .cardContent {
      padding: 32px 48px 24px !important;
      overflow: hidden;
      height: 100%;
    }
    .tableContainer {
      height: 100%;
    }
    .table {
      min-width: 750px;
    }
  }
`;

export default GrantPlayerAchievementsTable;
