import React, { FC, useEffect, useState } from 'react';
import { Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import Edit from './Edit';
import Grant from './Grant';
import Main from './Main';
import New from './New';
import {
  AchievementCancel,
  AchievementChange,
  AchievementCreate,
  AchievementDelete,
  AchievementEditClick,
  AchievementGrant,
  AchievementGrantClick,
  AchievementNewClick,
} from './_shared/types';

const AchievementsPage: FC = () => {
  const { path, url } = useRouteMatch();
  const history = useHistory();

  const [highlightedId, setHighlightedId] = useState<number | undefined>();
  useEffect(() => {
    const timeout = setTimeout(() => {
      setHighlightedId(undefined);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [highlightedId]);

  const handleCancel: AchievementCancel = () => {
    history.push(url);
  };
  const handleChange: AchievementChange = (id) => {
    history.push(url);
    setHighlightedId(id);
  };
  const handleCreate: AchievementCreate = (id) => {
    history.push(url);
    setHighlightedId(id);
  };
  const handleDelete: AchievementDelete = () => {
    history.push(url);
  };
  const handleGrant: AchievementGrant = (id) => {
    history.push(url);
    setHighlightedId(id);
  };

  const handleNewClick: AchievementNewClick = () => {
    history.push(`${url}/new`);
  };
  const handleEditClick: AchievementEditClick = (id) => {
    history.push(`${url}/${id}`);
  };
  const handleRowClick: AchievementGrantClick = (id) => {
    history.push(`${url}/${id}/grant`);
  };

  return (
    <Switch>
      <Route
        path={`${path}/:achievementId(\\d+)/grant`}
        render={() => (
          <PageContainer>
            <Grant onGrant={handleGrant} onCancel={handleCancel} />
          </PageContainer>
        )}
      />
      <Route
        path={`${path}/:achievementId(\\d+)`}
        render={() => (
          <PageContainer>
            <Edit
              onCancel={handleCancel}
              onChange={handleChange}
              onDelete={handleDelete}
            />
          </PageContainer>
        )}
      />
      <Route
        path={`${path}/new`}
        exact
        render={() => (
          <PageContainer>
            <New onCancel={handleCancel} onCreate={handleCreate} />
          </PageContainer>
        )}
      />
      <Route
        path={path}
        exact
        render={() => (
          <Main
            highlightedId={highlightedId}
            onEditClick={handleEditClick}
            onNewClick={handleNewClick}
            onRowClick={handleRowClick}
          />
        )}
      />
    </Switch>
  );
};

const PageContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
`;

export default AchievementsPage;
