import { applyMiddleware, createStore } from 'redux';
import { createLogger } from 'redux-logger';
import thunk from 'redux-thunk';
import localStorageMiddleware from './_middleware/local-storage';
import refreshTokenMiddleware from './_middleware/refresh-token';
import reducer from './_reducers';

const loggerMiddleware = createLogger();
const store = createStore(
  reducer,
  applyMiddleware(
    thunk,
    refreshTokenMiddleware,
    localStorageMiddleware,
    loggerMiddleware
  )
);
export default store;
